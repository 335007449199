import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { Alert, Col } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  RSelect,
  RadioButton,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  DueOptions,
  FrequencyOptions,
  setDueInAndFrequence,
} from "./RolesData";
import { RadioOptionTypes } from "../../../utils/Utils";

const RoleTimeRange = (props) => {
  const [data, setData] = useState([]);
  const [errorVal, setError] = useState();
  const history = useHistory();

  useEffect(() => {
    setData(
      props.state?.data?.courses.map((item) => ({
        ...item,
        isMandatory: item.isMandatory ?? false,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function updateDueIn(index, item, value) {
    let list = [...data];
    item.dueIn = value;
    list[index] = item;
    setData(list);
  }

  function updateType(index, item, value) {
    let list = [...data];
    item.isMandatory = value === 0;
    list[index] = item;
    setData(list);
  }

  function updateFrequency(index, item, value) {
    let list = [...data];
    item.frequency = value;
    list[index] = item;
    setData(list);
  }

  function onSaveClick() {
    if (
      data.some(
        (item) => item.dueIn === undefined || item.frequency === undefined
      )
    ) {
      setError("Please select due in and frequency for all caurses.");
    } else {
      setError();
      let courses = data.map((item) => {
        return {
          id: item.id,
          dueIn: item.dueIn.value,
          frequency: item.frequency.value,
          isMandatory: item.isMandatory,
        };
      });
      let params = {
        roleId: props.state?.data?.roleId,
        course: courses,
      };
      setDueInAndFrequence(params, (data, error) => {
        if (error === null) {
          history.push(`${process.env.PUBLIC_URL}/roles-list`);
        } else {
          setError(error.message);
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Head title="roletime-range" />
      <Content>
        <BlockHead size="sm mt-n5">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                <div className="p-2">
                  <h6 className="title">
                    Set the due time range and renewal frequency for selected
                    courses
                  </h6>
                </div>
              </BlockTitle>
              <BlockTitle tag="h3" page>
                <div className="p-2">
                  <h6 className="title">Role: {props.state?.data?.name}</h6>
                </div>
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
          <div className="p-2">
            <Alert color="primary">
              <strong>Please note: </strong>
              Due dates will only be updated for training that was not
              previously assigned to a staff member.
              <br />
              If your staff members have an existing due date for a training
              listed here, that due date will still apply.
            </Alert>
          </div>
        </BlockHead>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {errorVal}{" "}
            </Alert>
          </div>
        )}
        <Block>
          <DataTable className={"frequency-table"}>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">
                    <strong>Training</strong>
                  </span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">
                    <strong>Type</strong>
                  </span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">
                    <strong>Due in</strong>
                  </span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">
                    <strong>Frequency</strong>
                  </span>
                </DataTableRow>
              </DataTableHead>
              {data.length > 0
                ? data.map((item, index) => {
                    return (
                      <DataTableItem key={index}>
                        <DataTableRow>
                          <div className="user-info">
                            <span className="tb-lead">{item.name} </span>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="w-min-100px">
                            <RadioButton
                              name={index}
                              optionType={RadioOptionTypes.role}
                              onChange={(selectedOption) => {
                                updateType(index, item, selectedOption);
                              }}
                              activeIndex={item.isMandatory === true ? 0 : 1}
                            />
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="w-min-100px">
                            <RSelect
                              options={DueOptions}
                              value={item.dueIn}
                              onChange={(e) => updateDueIn(index, item, e)}
                            />
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="w-min-100px">
                            <RSelect
                              options={FrequencyOptions}
                              value={item.frequency}
                              onChange={(e) => updateFrequency(index, item, e)}
                            />
                          </div>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
          </DataTable>
          <Col md="12">
            <div className="mt-3 text-left">
              <Button
                className="btn btn-lighter me-2"
                onClick={() => {
                  props.prev();
                }}
              >
                Back
              </Button>
              <Button
                className="btn btn-primary custom-solid-btn-sm"
                onClick={() => onSaveClick()}
              >
                Save
              </Button>
            </div>
          </Col>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default RoleTimeRange;
