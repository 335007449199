import React from "react";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";

function Privacy() {
  return (
    <React.Fragment>
      <Head title="Terms &amp; Conditions" />
      <Content>
        <div className="privacy-page">
          <div class="container">
            {/* <h1 className="fs-30">
              <center>Privacy Notice</center>
            </h1> */}

            <h2>Privacy Notice</h2>
            <p>
              At Saunders Training Academy we take data privacy very seriously
              and we are committed to protecting and respecting the rights of
              all individuals. We are dedicated to ensuring the confidentiality
              and privacy of information entrusted to us and aspire to be
              transparent when we collect and use personal data.
            </p>
            <p>
              This policy relates to Saunders Training Academy, and is intended
              to tell you how we collect and use your<b>Personal Information</b>{" "}
              to give you the information you need at a glance, with the option
              of following a link if you want to find out more.
            </p>
            <p>This privacy notice covers the following:</p>
            <h1 className="TableofContents">Table of Contents</h1>
            <ul>
              <li>
                <a href="#">Our Contact Details</a>
              </li>
              <li>
                <a href="#">Who are we</a>
              </li>
              <li>
                <a href="#">
                  What is personal information, and which personal information
                  do we collect about you
                </a>
              </li>
              <li>
                <a href="#">
                  How Saunders Training Academy collects personal information
                </a>
              </li>
              <li>
                <a href="#">How do we use your information</a>
              </li>
              <li>
                <a href="#">Sharing Information</a>
              </li>
              <li>
                <a href="#">
                  What do we do to keep your Personal Information secure
                </a>
              </li>
              <li>
                <a href="#">International transfer of data</a>
              </li>
              <li>
                <a href="#">How we store your information</a>
              </li>
              <li>
                <a href="#">Your Rights</a>
              </li>
              <li>
                <a href="#">How to complain</a>
              </li>
              <li>
                <a href="#">Third-party links</a>
              </li>
              <li>
                <a href="#">What are cookies</a>
              </li>
              <li>
                <a href="Your Choices (e.g. marketing related emails or otherwise)">
                  Your Choices (e.g. marketing related emails or otherwise)
                </a>
              </li>
              <li>
                <a href="Changes to this Privacy Notice and your duty to inform us of changes">
                  Changes to this Privacy Notice and your duty to inform us of
                  changes
                </a>
              </li>
            </ul>
            <li class="l">Our Contact Details</li>
            <p>
              Saunders Training Academy’s Headquarters is located at Ann’s, 23
              Annaghmore Road, Portadown, BT62 1NA
            </p>
            <p>
              If you have questions or comments about this Privacy Notice or how
              we handle personal data, please direct your correspondence either
              to the above postal or to
              <a href="info@saunderstrainingacademy.co.uk">
                info@saunderstrainingacademy.co.uk
              </a>
            </p>

            <li class="l">Who are we</li>
            <p>
              We are <b> SAUNDERS TRAINING ACADEMY</b>, trading as Saunders
              Training Academy (<b> "Saunders Training Academy", "we", "us"</b>)
              and we act as a processor for the purposes of all applicable UK
              legislation including (i) the General Data Protection Regulation,
              Regulation (EU) 2016/679 as it forms part of domestic law in the
              United Kingdom by virtue of section 3 of the European Union
              (Withdrawal) Act 2018; and (ii) the UK Data Protection Act 2018
              (the <b> "Data Protection Law"</b>). We operate the Saunders
              Training Academy platform and website at
              <a href="https://saunderstrainingacademy.co.uk/">
                https://saunderstrainingacademy.co.uk/
              </a>{" "}
              (the
              <b>"Platform"</b>).
            </p>
            <p>
              Our Platform connects Clients with our Workers through{" "}
              <a href="https://saunderstrainingacademy.co.uk/">
                https://saunderstrainingacademy.co.uk/
              </a>{" "}
              and any other website or application through which we may make the
              Platform and services available from time to time (our
              <b> "services"</b>). This policy applies to you if you are a
              Member, Client, Worker, supplier, referee, visitor to our Platform
              or applicant for a job with us. For the purposes of this policy:
            </p>
            <ul>
              <li>
                <b>Members</b>– a user of the Platform who completes a
                registration as a Member.
              </li>
              <li>
                <b>Clients</b>– a Member of the Platform who is a provider of
                healthcare services and operator of healthcare facilities that
                is regulated by the Care Quality Commission, such as but not
                limited to, care homes, hospitals, prisons.
              </li>
              <li>
                <b>Workers</b>– a Member of the Platform who is a Worker of
                Saunders Training Academy and a provider of healthcare services,
                such as but not limited to, qualified nurses, doctors or care
                workers.
              </li>
              <li>
                <b>Referee</b>– a person who provides a personal or work
                reference for a Worker or an Applicant.
              </li>
              <li>
                <b>Suppliers</b>– business contacts in our supplier
                organisations.
              </li>
              <li>
                <b>Platform Visitors</b>– anyone who visits our Platform.
              </li>
              <li>
                <b>Applicants</b>– an individual who is applying for a job with
                us.
              </li>
              <li>
                <b>Vacancy</b>– a specific ad hoc rota vacancy within the
                healthcare sector commonly on a shift-by-shift basis and
                "Vacancies" shall be construed accordingly.
              </li>
              <li>
                <b>Assignments</b>– an assignment entered into between Saunders
                Training Academy and the Client for the provision of services by
                a Worker to the Client corresponding to a Vacancy.
              </li>
            </ul>
            <li class="l">
              What is Personal Information, and which Personal Information do we
              collect about you
            </li>
            <p>
              For the purposes of this Privacy Notice, "Personal Information"
              consists of any information that relates to you and/or information
              from which you can be identified, directly or indirectly as
              defined under Data Protection Law. For example, information which
              identifies you may consist of your name, address, telephone
              number, photographs, location data, an online identifier (e.g.
              cookies identifiers and your IP address) or to one or more factors
              specific to your physical, economic, cultural or social identity.
              When we combine other information (i.e. information that does not,
              on its own, identify you) with Personal Information, we treat the
              combined information as Personal Information.
            </p>
            <p>
              {" "}
              Given the nature of our website, we do not expect to collect the
              personal data of anyone under 18 years old. If you are aware that
              any personal data of anyone under 18 years old has been shared
              with our website please let us know so that we can delete that
              data. This privacy policy is primarily written for adults.
            </p>

            <p>
              We may collect use, store and transfer different kinds of Personal
              Information about you which we have grouped together as follows:
            </p>
            <ul>
              <li>
                {" "}
                <b>Identity Data </b> includes first name, maiden name (if
                applicable), last name, community username, title, date of birth
                and gender, job title, a copy of a photographic identity
                document (such as a passport or ID card), a photograph for
                incorporation into your profile on the Platform, a screenshot
                from any applicable video-call between a Member (or their
                representative) and Saunders Training Academy, and, in respect
                of an Worker, the applicable NMC pin/reference/registration
                number.
              </li>
              <li>
                {" "}
                <b>Contact Data </b> includes address, former addresses (where
                applicable), email address and telephone number(s).
              </li>
              <li>
                {" "}
                <b>Career History and Education Data </b> includes professional
                skills and experience, employment history, academic and
                professional qualifications, certificates, training,
                competencies (clinical and medication), reference information
              </li>
              <li>
                {" "}
                <b>Employment Status Data </b>includes status/right to work and
                work permit information.
              </li>
              <li>
                {" "}
                <b>Worker Profile Data</b> includes job role, details of
                professional indemnity insurance cover, your ratings submitted
                via our in-built ratings system, feedback relating to you,
                preferences.
              </li>
              <li>
                {" "}
                <b>Financial Data</b>includes bank account details, direct debit
                mandates
              </li>
              <li>
                {" "}
                <b>Technical Data </b>includes internet protocol (IP) addresses,
                location data, username and password, usage session dates and
                duration, page views, time zone setting and location, browser
                plug-in types and versions, operating system and platform, the
                type of browser used while visiting our Platform, how you use
                our Platform and the numbers of users who visit our website.
              </li>
              <li>
                {" "}
                <b>Services Data </b>includes information about how you use our
                services, details of which services you have received from us,
                choices you have made on the Platform (including, for example,
                settings and favorite workers), information as to Vacancies
                posted and applied for, Assignments, cancellations, ratings,
                reviews, responses to surveys and requests for feedback,
                information about your use of our services, correspondence and
                communications with you and information about any complaints or
                enquiries you make to us.
              </li>
              <li>
                {" "}
                <b>Community Data i</b>ncludes any information you send to other
                Members via the Platform's community function.
              </li>
              <li>
                {" "}
                <b>Marketing and Communications Data </b>includes your
                preferences in receiving marketing from us and your
                communication preferences.
              </li>
              <li>
                {" "}
                <b>Special Categories of Data</b>: we may collect the following
                special categories of Personal
              </li>
              <p>Information about you:</p>
              <ul class="b">
                <li>
                  occupational health information including
                  vaccination/immunisation status and history ("
                  <b>Occupational Health Data</b>");
                </li>
                <li>
                  the identity of your professional indemnity insurer which may
                  indicate trade union membership ("<b> Trade Union Data </b>");
                  and
                </li>
                <li>
                  where a photographic ID is provided (for example by way of a
                  copy of your passport, driving licence or other photographic
                  ID document) an inference may be made as to your race,
                  ethnicity and/or religious beliefs (" <b>ID Data </b>").
                </li>
              </ul>
              <li>
                <b> Criminal Offences Data</b>: we will process information
                about a Workers criminal convictions including enquiries made of
                the Disclosure and Barring Service ("DBS") in respect of
                criminal convictions as part of a Workers membership application
                process, creation of a profile and use of the Platform.
              </li>
            </ul>
            <p>
              You must provide this personal data to use our website and deal
              with our business. Our Clients require us to carry out a criminal
              records check in order to satisfy themselves that there is nothing
              in a Worker’s criminal convictions history which makes a Worker
              unsuitable for becoming a Member of the Platform, using the
              Platform and/or applying for and carrying out an Assignment.
              Therefore, we may collect a copy of your DBS Certificate, your DBS
              Certificate number and DBS Update Service Number. We may also ask
              you to provide a statement about the information contained in your
              DBS Certificate.
            </p>
            <p>
              We have in place an appropriate policy document and safeguards
              which we are required by law to maintain when processing such
              data.
            </p>
            <p>
              We also collect and use aggregated, anonymous or pseudonymised
              data, such as statistical or demographic data. If we combine any
              of this data with your Personal Information so that it can
              directly or indirectly identify you, we treat the combined data as
              personal data which will be used in accordance with this Privacy
              Notice.
            </p>
            <p>
              Sometimes you can choose if you want to give us your personal data
              and let us use it. Where that is the case we will tell you and
              give you the choice before you give the personal data to us. We
              will also tell you whether declining to share that personal data
              will have any effect on our ability to provide services to you.
            </p>
            <p>
              <b> 3. a) If you fail to provide personal information</b>
            </p>
            <p>
              Where we need to collect Personal Information by law, or under the
              terms of a contract we have with you, and you fail to provide that
              Personal Information when requested, we may not be able to perform
              the contract we have, or which we are attempting to enter into,
              with you (for example, to provide you with services and support).
              In such circumstances, we may be required to cease providing
              services and support to you and will notify you accordingly.
            </p>
            <li className="fs-5">
              <b>
                {" "}
                How Saunders Training Academy collects personal information
              </b>
            </li>
            <p>
              At Saunders Training Academy we may obtain personal data directly
              from individuals in a number of ways and Saunders Training Academy
              may also obtain personal information indirectly (such as your
              browsing activity while on our website) from a variety of sources
              including:
            </p>
            <li>
              <b>Members</b>
            </li>
            <ul>
              <li>
                <b>
                  {" "}
                  <i> Personal Information that you provide directly to us </i>
                </b>
                . You may give us your Identity Data, Contact Data, Marketing
                and Communications Data, by filling in our Platform registration
                form, creating an account and profile or by corresponding with
                us by email, phone or otherwise. We collect Services Data when
                we correspond with you about our services and when you use the
                Platform.
              </li>
              <li>
                <b>
                  <i> Automated technologies or interactions</i>
                </b>
                . When you interact with our Platform, we may automatically
                collect Technical Data about your equipment, browsing actions
                and patterns. We collect this Personal Information by using
                cookies and other similar technologies, and our analytics
                providers. Please see section 10 below.
              </li>
            </ul>
            <li>
              <b>Clients</b>
            </li>
            <ul>
              <li>
                <b>
                  <i> Personal Information that you provide directly to us</i>
                </b>
                . You may give us your Identity Data, Contact Data, Financial
                Data, Marketing and Communications Data, by filling in our
                Platform registration form, creating an account and profile,
                updating and adding to your profile, using the Platform or by
                corresponding with us by email, phone or otherwise. We collect
                Services Data when we correspond with you about our services and
                when you use the Platform.
              </li>
              <li>
                <b>
                  <i> Automated technologies or interactions</i>
                </b>
                .When you interact with our Platform, we may automatically
                collect Technical Data about your equipment, browsing actions
                and patterns. We collect this Personal Information by using
                cookies and other similar technologies, and our analytics
                providers. Please see section 10 below.
              </li>
              <li>
                <b>
                  <i> Third parties or publicly available sources</i>
                </b>
                .We may collect your Identity Data and Contact Data from
                publicly available sources such as Companies House and the CQC.
              </li>
            </ul>
            <li>
              <b>Workers</b>
            </li>
            <ul>
              <li>
                <b>
                  <i> Personal Information that you provide directly to us.</i>
                </b>
                You may give us your Identity Data, Contact Data, Career History
                and Education Data, Employment Status Data, Worker Profile Data,
                Financial Data, Special Categories of Data, Criminal Offences
                Data, Marketing and Communications Data, by filling in our
                Platform registration form, creating an account and profile,
                updating and adding to your profile, using the Platform or by
                corresponding with us by email, phone or otherwise. We collect
                Services Data when we correspond with you about our services and
                when you use the Platform.
              </li>
              <li>
                <b>
                  <i> Automated technologies or interactions</i>
                </b>
                .When you interact with our Platform, we may automatically
                collect Technical Data about your equipment, browsing actions
                and patterns. We collect this Personal Information by using
                cookies and other similar technologies, and our analytics
                providers. Please see section 10 below.
              </li>
              <li>
                <b>
                  <i> Third parties or publicly available sources</i>
                </b>
                .We may receive Identity Data, Career History and Education Data
                from third parties such as your Referee and from publicly
                available sources such as LinkedIn, the NMC. We may also receive
                Criminal Offences Data from a third party DBS check provider if
                you have chosen to use our recommended provider to obtain a DBS
                Certificate and from the DBS Update Service provided by the
                government if you have chosen to give us access to that service.
              </li>
            </ul>
            <li>
              <b>Suppliers</b>
            </li>
            <ul>
              <li>
                We collect your Identity Data, Contact Data, Financial Data and
                Services Data when we correspond with you about our services,
                and from publicly available sources such as Companies House.
              </li>
            </ul>
            <li>
              <b>Referee</b>
            </li>
            <ul>
              <li>
                We may obtain your Identity Data and Contact Data as well as
                information regarding your credentials as a referee, details of
                your relationship and your opinions of a Worker or
              </li>
              <p>
                Applicant, either directly from you or from an Worker or
                Applicant.
              </p>
            </ul>
            <li>
              <b>Platform Visitors</b>
            </li>
            <ul>
              <li>
                When you interact with our Platform, we may automatically
                collect Technical Data about your equipment, browsing actions
                and patterns. We collect this Personal Information by using
                cookies and other similar technologies, and our analytics
                providers. Please see section 10 below.
              </li>
            </ul>
            <li>
              <b>Applicants</b>
            </li>
            <ul>
              <li>
                <b>
                  <i>Personal Information that you provide directly to us.</i>
                </b>
                You may give us your Identity Data, Contact Data, Career History
                and Education Data, Employment Status Data and Financial Data by
                applying for a job with us.
              </li>
              <li>
                <b>
                  <i>Third parties or publicly available sources.</i>
                </b>
                We may receive Identity Data, Contact Data and Career History
                and Education Data from third parties such as your employer,
                Referee, your recruiter and publicly available sources such as
                LinkedIn.
              </li>
            </ul>
            <p>
              We will always ensure you know we are processing your personal
              information except where it is disproportionately difficult to do
              so.
            </p>
            <li className="fs-20px">How do we use your information</li>
            <p>
              We will only use your Personal Information when Data Protection
              Law allows us to do so.
            </p>
            <p>
              You can see a full list of the types of data we process, the
              purpose for which we process it and the lawful basis on which it
              is processed in the table below.
            </p>
            <p>
              Where we refer to a legitimate interest herein, we mean that we
              have a business or commercial reason to use your personal data, so
              long as this is not overridden by your own rights and interests.
              We will carry out an assessment when relying on legitimate
              interests, to balance our interests against your own. You can
              obtain details of this assessment by contacting us using the
              contact details provided herein.
            </p>
            <p>
              <b>
                5. a) Saunders Training Academy’s purposes and lawful bases of
                personal data processing
              </b>
            </p>
            <p>
              We may use the Personal Information that we collect for the
              following purposes. For each purpose, we describe the legal bases
              we rely on to justify such use of your Personal Information:
            </p>
          </div>
          <div class="d">
            <table className="w-100  p-2 overflow-x-auto">
              <tr>
                <th className="w15">Category</th>
                <th>Purpose/Activity</th>
                <th className="w20">Type of data</th>
                <th>Legal basis</th>
              </tr>
              <tr>
                <td>Clients</td>
                <td>
                  <b>To register you or your employer as a Client</b>
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                  </ul>
                </td>
                <td>Necessary for performance of a contract with you</td>
              </tr>
              <tr>
                <td> Clients</td>
                <td>
                  <b>To provide you with our services including</b>.
                  <ul class="c">
                    <li>operating the Platform;</li>
                    <li>allowing you to communicate with other Members;</li>
                    <li>
                      allowing you to post Vacancies and find WORKERs to fill
                      those Vacancies;
                    </li>
                    <li>
                      managing payments, fees and charges including via Payment
                      Processor(s); and
                    </li>
                    <li>collecting and seeking to recover money owed to us.</li>
                  </ul>
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Financial Data</li>
                    <li>Community Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for:
                  <ul class="c">
                    <li>performance of a contract with you;</li>
                    <li>
                      our legitimate interests to operate the Platform and those
                      of other Members; and
                    </li>
                    <li>
                      necessary for our legitimate interests (to recover debts
                      due to us).
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Workers</td>
                <td>
                  <b>To register you as a Worker</b>including making enquiries
                  of third parties, for example, via a Verification Services
                  Provider (see section 5 below).
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Career History and Education Data</li>
                    <li>Employment Status Data</li>
                    <li>WORKER Profile Data</li>
                  </ul>
                </td>
                <td>Necessary for performance of a contract with you</td>
              </tr>
              <tr>
                <td>Workers</td>
                <td>
                  <b>To provide you with our services including</b>.
                  <ul class="c">
                    <li>operating the Platform;</li>
                    <li>allowing you to amend your profile;</li>
                    <li>
                      allowing you to communicate and interact with other
                      Members;
                    </li>
                    <li>
                      allowing you to apply for Vacancies, accept invitations
                      from Clients and enter into Assignments with Clients;
                    </li>
                    <li>
                      managing payments, fees and charges including via the
                      Payment Processor; and
                    </li>
                    <li>collecting and seeking to recover money owed to us.</li>
                  </ul>
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Financial Data</li>
                    <li>Services Data</li>
                    <li>Career History and Education Data</li>
                    <li>Employment Status Data</li>
                    <li>WORKER Profile Data</li>
                    <li>Community Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for:
                  <ul class="c">
                    <li>performance of a contract with you;</li>
                    <li>
                      our legitimate interests to operate the Platform and those
                      of other Members; and
                    </li>
                    <li>
                      our legitimate interests (to recover debts due to us).
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Workers</td>
                <td>
                  <b> For compliance</b> purposes – Clients (as healthcare
                  institutions) will need to access the personal data of an
                  WORKER via the Platform in order
                  <ul class="c">
                    <li>
                      to assess the WORKER against the compliance standards set
                      by the Client organisation ahead of arrangement of an
                      Assignment; and
                    </li>
                    <li>
                      to maintain records of WORKERs with whom the Client has
                      entered into Assignments
                    </li>
                  </ul>
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Profile Data</li>
                    <li>Referee Data</li>
                  </ul>
                </td>
                <td>
                  {" "}
                  Necessary for:
                  <ol class="c" type="i">
                    <li>performance of a contract with you;</li>
                    <li>
                      our legitimate interests to operate the Platform and those
                      of other Members
                    </li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>Clients and Workers</td>
                <td>
                  <b>To communicate with relevant regulatory bodies</b>{" "}
                  including the Nursing and Midwifery Council and/or the Care
                  Quality Commission
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Worker Profile Data</li>
                    <li>Services Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for:
                  <ul class="c">
                    <li>performance of a contract with you;</li>
                    <li>to comply with a legal obligation;</li>
                    <li>
                      for our legitimate interests and those of any applicable
                      regulators.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Members, Clients and Workers</td>
                <td>
                  <b>To manage our relationship with you which will include:</b>
                  <ul class="c">
                    <li>
                      notifying you about changes to our Terms and Conditions or
                      Privacy Notice; and
                    </li>
                    <li>asking you to leave a review or provide feedback.</li>
                  </ul>
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Marketing and Communications Data</li>
                    <li>Services Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for:
                  <ul class="c">
                    <li>performance of a contract with you;</li>
                    <li>to comply with a legal obligation;</li>
                    <li>
                      our legitimate interests in keeping our records updated
                      and studying how Members use our Platform and services
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Applicants</td>
                <td>
                  <b> To consider you for a role</b>, if you are applying for a
                  job with us
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Career History and Education Data</li>
                    <li>Employment Status Data</li>
                    <li>Financial Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for:
                  <ul class="c">
                    <li>
                      To take steps in order to enter a contract with you;
                    </li>
                    <li>for our legitimate interests in finding employees;</li>
                    <li>
                      to comply with our legal obligations, such as to make
                      reasonable adjustments.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Referees</td>
                <td>
                  <b> To perform our services</b> to Workers and Clients and to
                  enable us to obtain your opinions on an Applicant.
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for our legitimate interest in providing our
                  services to Clients and WORKERs, and obtaining information
                  about Applicants
                </td>
              </tr>
              <tr>
                <td>Suppliers</td>
                <td>
                  <b> To carry out our contractual obligations</b> to you, if
                  you are our supplier or subcontractor, including to manage our
                  payments to you.
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Financial Data</li>
                    <li>Services Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for our legitimate interests in receiving services
                  from our suppliers to ensure our business is run efficiently.
                </td>
              </tr>
              <tr>
                <td>Members and Platform Visitors</td>
                <td>
                  <b> For security purposes and to administer our Platform</b> –
                  to maintain and enhance the Platform, to ensure that content
                  from it is presented in the most effective manner for you and
                  your computer, and to enhance the user experience (including
                  troubleshooting, data analysis, testing, system maintenance,
                  support, reporting and hosting of data)
                </td>
                <td>Technical Data</td>
                <td>
                  Necessary:
                  <ul class="c">
                    <li>
                      for our legitimate interests in running our business, to
                      ensure the security of our systems, to assist us in the
                      provision of administration and IT services, network
                      security, to prevent fraud and in the context of a
                      business reorganisation or company restructuring exercise;
                    </li>
                    <li>for performance of a contract with you;</li>
                    <li>to comply with a legal obligation.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Members, Clients and Workers</td>
                <td>
                  <b>To provide you with marketing information</b> relating to
                  the services and activities which you request from us or which
                  we feel may be of interest to you, and relevant Platform
                  content, and to measure or understand the effectiveness of the
                  marketing we serve to you.
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Services Data</li>
                    <li>Marketing and Communications Data</li>
                    <li>Technical Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for our legitimate interests to develop our
                  services, grow our business and inform our marketing strategy.
                </td>
              </tr>
              <tr>
                <td>All categories</td>
                <td>
                  <b> Business and analysis purposes</b> - for business
                  monitoring, assessment and analysis of our Clients, Workers
                  and Members, to develop ourbusiness strategy, record keeping
                  including maintaining our accounts, complying with good
                  practice and for other administrative, operational and
                  security reasons, and to seek your thoughts and opinions on
                  the services we provide.
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Services Data</li>
                    <li>Marketing and Communications Data</li>
                    <li>Technical Data</li>
                  </ul>
                </td>
                <td>
                  Necessary:
                  <ol class="c" type="i">
                    <li>
                      for our legitimate interests in running our
                      businessefficiently, successfully and in order to keep our
                      records updated;
                    </li>
                    <li>to comply with a legal obligation.</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>Members and Platform Visitors</td>
                <td>
                  <b> To improve the Platform and the services,</b> services,
                  customer relationships and experiences.
                </td>
                <td>
                  <ul class="c">
                    <li>Technical Data</li>
                    <li>Services Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for our legitimate interests in understanding how
                  Members use our services, keeping the Platform updated, and
                  developing our business and to inform our marketing strategy.
                </td>
              </tr>
              <tr>
                <td>Members, Workers, Clients and Referees</td>
                <td>
                  <b>
                    {" "}
                    As required in special circumstances such as a police or
                    other legal investigation
                  </b>{" "}
                  or serious complaint requiring a Client, a Worker and/or
                  Saunders Training Academy to release personal data
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Worker Profile Data</li>
                    <li>Employment Status Data</li>
                    <li>Career History and Education Data</li>
                    <li>Services Data</li>
                    <li>Marketing and Communications Data</li>
                    <li>Technical Data</li>
                    <li>Special Categories of Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for:
                  <ul class="c">
                    <li>performance of a contract with you;</li>
                    <li>compliance with a legal obligation;</li>
                    <li>performance of a task in the public interest;.</li>
                    <li>
                      the establishment, exercise or defence of legal claims or
                      whenever courts are acting in tier judicial capacity;
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Workers</td>
                <td>
                  <b>
                    {" "}
                    As required in limited circumstances, when a Client must use
                    personal data to help it discharge its functions relating to
                    providing care to patients
                  </b>{" "}
                  and relatives and looking after their welfare.
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Worker Profile Data</li>
                    <li>Services Data</li>
                    <li>Technical Data</li>
                    <li>Marketing and Communications Data</li>
                  </ul>
                </td>
                <td>
                  Necessary for:
                  <ul class="c">
                    <li>Performance of a contract with you;</li>
                    <li>Compliance with a legal obligation;</li>
                    <li>Public interest.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>All categories</td>
                <td>
                  <b> To prevent and detect crime, fraud or corruption</b> and
                  to meet our legal, regulatory and ethical responsibilities
                </td>
                <td>
                  <ul class="c">
                    <li>Identity Data</li>
                    <li>Contact Data</li>
                    <li>Technical Data</li>
                    <li>Services Data</li>
                  </ul>
                </td>
                <td>Necessary to comply with our legal obligations</td>
              </tr>
            </table>
          </div>
          <div class="container">
            <p>
              Please be aware that we are not responsible for the data
              processing activities of others, such as Clients.
            </p>
            <p>
              <b>5. b) Marketing communications</b>
            </p>
            <p>
              Where permitted in our legitimate interest or with your prior
              consent where required by Data Protection Law, we will use your
              Personal Information for marketing analysis and to provide you
              with promotional update communications by email, telephone or post
              about our products and services. For further information on this,
              see the 'Your Choices' section of this Privacy Notice.
            </p>
            <p>
              <b>5. c) Combining personal information</b>
            </p>
            <p>
              We may combine the Personal Information that we collect from you
              to the extent permitted by applicable law. For example, we may
              combine various different databases that contain your Personal
              Information to carry out internal analysis of our Member base and
              how the services are used, and to provide better services and more
              personalised content (such as marketing).
            </p>
            <p>
              <b>5. d) Change of purpose</b>
            </p>
            <p>
              Where we need to use your Personal Information for another reason
              other than for the purpose for which we collected it, we will only
              use your Personal Information where that reason is compatible with
              the original purpose. If we need to use your Personal Information
              for an unrelated purpose, we will notify you and we will explain
              the legal basis which allows us to do so. Please note that we may
              process your Personal Information without your knowledge or
              consent, in compliance with the above rules, where this is
              required or permitted by law.
            </p>
            <li class="l">Sharing information</li>
            <p>
              We will only use your Personal Information for our internal
              business purposes, some of which are mentioned above. We may
              disclose your information to the following entities:
            </p>
            <p>
              <b>Clients</b>
            </p>
            <ul>
              <li>
                We may need to disclose Worker's Personal Information to Clients
                as part of the services and use of the Platform. For example, we
                will share Workers' Identity Data, Contact Data, Worker Profile
                Data, Employment Status Data and Career History and Education
                Data with Clients when you apply for a Vacancy (as defined in
                our Terms and Conditions). We will also share limited Identity
                Data with Clients to enable them to invite Workers to apply for
                Vacancies.
              </li>
            </ul>
            <p>
              <b>Service Providers</b>
            </p>
            <ul>
              <li>
                We use third party service providers to help us to administer
                certain activities and services on our behalf, such as IT,
                hosting and cloud services, payment processing services,
                verification services, marketing services, customer support
                services and DBS check services. We may share Personal
                Information about you with such third party service providers
                solely for the purpose of enabling them to perform services on
                our behalf and they will operate only in accordance with our
                instructions. Here are examples of third-party service providers
                we use:
              </li>
              <li>
                <i> IT Service Provider and Administration Services</i> – we use
                salesforce.com, inc. and Amazon Web Services, Inc. to provide us
                with IT, hosting and cloud services.
              </li>
              <li>
                Marketing and Analytics Services – we use Segment.io, Inc. and
                Facebook to provide us with marketing and analytics services
                including Facebook Pixel and Custom Audiences.
              </li>
              <ul class="b">
                <li>
                  Facebook Pixel is a cookie (explained below at section 10)
                  which we use to gather information about use of our Platform.
                  Facebook's cookies policy can be found <a href="here">here</a>
                  .
                </li>
                <li>
                  We use Custom Audiences to tailor our advertising to people
                  who are likely to be interested in us. The data processing
                  terms which apply to Facebook when we use Custom Audiences are
                  found
                  <a href="here">here</a>.
                </li>
              </ul>
              <li>
                <i> Customer Support Services</i> – we use Accrels to provide
                out of hours contact and customer support services, a company
                located in India with services performed in Ahmedabad.
              </li>
            </ul>
            <p>
              <b>Anonymous statistics</b>
            </p>
            <ul>
              <li>
                We prepare and develop anonymous, aggregate or generic data and
                statistics for various reasons (such as aggregate usage
                statistics including "page views" on the Platform analysing how
                users use our content and for marketing). As this data is
                anonymous (i.e. you cannot be identified from it) we do not
                consider this information to be Personal Information. As such,
                we may share it with any third party.
              </li>
            </ul>
            <p>
              <b>Third parties when required by law</b>
            </p>
            <ul>
              <li>
                We will disclose your Personal Information to comply with
                applicable law or respond to valid legal process, including from
                our regulators, law enforcement or other government agencies (in
                which case such agencies or regulators will be acting as
                processors as well); to protect the users of the Platform (e.g.
                to prevent spam or attempts to defraud them); to operate and
                maintain the security of the Platform (e.g. to prevent or stop
                an attack on our systems or networks); or to protect our rights
                or property.
              </li>
            </ul>
            <p>
              <b>Other Parties in Connection with Corporate Transactions</b>
            </p>
            <ul>
              <li>
                event that (a) we buy any business or assets, in which case we
                will disclose your Personal Information to the prospective buyer
                of such business or assets subject to the terms of this Privacy
                Notice; (b)
                <p>
                  all or substantially all of our business or assets are or are
                  intended to be sold or otherwise assigned to another entity.
                </p>
              </li>
            </ul>
            <p>
              <b>Other Parties at Your Direction</b>
            </p>
            <ul>
              <li>
                We may share Personal Information about you with third parties
                when you request such sharing, such as to prospective employers,
                or to your legal or other professional advisers.
              </li>
            </ul>
            <li class="l">What do we do to keep your information secure</li>
            <p>
              We have put in place appropriate physical and technical measures
              to safeguard your Personal Information. In addition, we limit
              access to your Personal Information to those employees, agents,
              contractors and other third parties who have a business need to
              know. They will only process your Personal Information on our
              instructions and they are subject to a duty of confidentiality.
              When we use service providers to assist us in processing your
              Personal Information, we have written contracts in place with such
              service providers which means that they cannot do anything with
              your Personal Information unless we have instructed them to do it.
            </p>
            <p>
              However, please note that although we take appropriate steps to
              protect your Personal Information, no website or transmission of
              data, computer system or wireless connection is completely secure
              and therefore we cannot guarantee the security of your Personal
              Information. We have established and implemented procedures to
              deal with any suspected Personal Information breach and will
              notify you and any applicable regulator of a breach where we are
              legally required to do so.
            </p>
            <li class="l">International Transfer of Data</li>
            <p>
              The Personal Information that we collect from you may be stored
              and processed in your region, or transferred to, stored at or
              otherwise processed outside the UK or the European Economic Area
              ("<b>UK/EEA</b>
              "), some of which may provide lower levels of protection of
              privacy
            </p>
            <p>
              By using the Platform and/or providing us with your Personal
              Information, you acknowledge that we will collect, transfer, store
              and process your information outside the UK/EEA. We will take all
              steps reasonably necessary to ensure that your Personal
              Information is kept secure and treated in accordance with this
              Privacy Notice.
            </p>
            <p>
              When we transfer your Personal Information outside the UK/EEA to
              third parties, we will ensure that appropriate transfer agreements
              and mechanisms, such as the relevant Standard Contractual Clauses,
              are in place to help ensure that our third party service providers
              provide an adequate level of protection to your Personal
              Information. We will only transfer your Personal Information
              outside the UK/EEA in accordance with applicable laws or where you
              have given us your consent to do so, where required by Data
              Protection Law.
            </p>
            <p>We may transfer your personal information outside the UK/EEA:</p>
            <ul>
              <li>In order to store it.</li>
              <li>
                In order to enable us to provide goods or services to you and
                fulfil our contract with you. This includes processing of
                payment details or the provision of support services.
              </li>
              <li>Where we are legally required to do so.</li>
            </ul>
            <p>
              Under Data Protection Law, we can only transfer your personal data
              to a country outside the UK where: (i) in the case of transfers
              subject to the UK GDPR, the UK government has decided the
              particular country ensures an adequate level of protection of
              personal data (known as an ‘adequacy regulation’) further to
              Article 45 of the UK GDPR; (ii) there are appropriate safeguards
              in place, together with enforceable rights and effective legal
              remedies for you; or (iii) a specific exception applies under
              relevant data protection law.
            </p>
            <p>
              Where we transfer your personal data outside the UK we do so on
              the basis of an adequacy regulation or (where this is not
              available) legally-approved standard data protection clauses
              recognised or issued further to Article 46(2) of the UK GDPR. In
              the event we cannot or choose not to continue to rely on either of
              those mechanisms at any time we will not transfer your personal
              data outside the UK unless we can do so on the basis of an
              alternative mechanism or exception provided by Data Protection Law
              and reflected in an update to this policy.
            </p>
            <li class="l">How we store your information</li>
            <p>
              We will only retain your personal data for as long as reasonably
              necessary to fulfil the purposes we collected it for, including
              for the purposes of satisfying any legal, regulatory, tax,
              accounting or reporting requirements. We may retain your personal
              data for a longer period in the event of a complaint or if we
              reasonably believe there is a prospect of litigation in respect to
              our relationship with you.
            </p>
            <p>
              To determine the appropriate retention period for personal data,
              we consider the amount, nature and sensitivity of the personal
              data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we
              process your personal data and whether we can achieve those
              purposes through other means, and the applicable legal,
              regulatory, tax, accounting or other requirements
            </p>
            <p>
              We retain Workers' Personal Information for seven years after
              deletion of their account. We retain Clients' Personal Information
              for seven years following the last contact with such Client.
            </p>
            <p>
              When your Personal Information is no longer required for the
              purpose it was collected or as required by applicable law, it will
              be deleted and/or returned to you in accordance with applicable
              law.
            </p>
            <p>
              In some circumstances you can ask us to delete your Personal
              Information (see section 8 below).
            </p>
            <li class="l">Your Rights</li>
            <p>
              We will collect, store and process your Personal Information in
              accordance with your rights under any applicable Data Protection
              Laws. Under certain circumstances, you have the following rights
              which you can exercise at any time in relation to your Personal
              Information:
            </p>
            <ul>
              <li>
                Subject Access - you have the right to request details of the
                Personal Information which we hold about you and copies of such
                Personal Information.
              </li>
              <li>
                Right to Withdraw Consent – where you have consented to our
                processing of your Personal Information, you have the right to
                withdraw such consent at any time. In the event you wish to
                withdraw your consent to processing, please contact us using the
                details provided in section 1.
              </li>
              <li>
                Data Portability – you may, in certain circumstances, request us
                to port (i.e. transmit) your Personal Information directly to
                another organisation or to you.
              </li>
              <li>
                Rectification – we want to ensure that the Personal Information
                about you that we hold is accurate and up to date. If you think
                that any information we have about you is incorrect or
                incomplete, please let us know. To the extent required by
                applicable laws, we will rectify or update any incorrect or
                inaccurate Personal Information about you.
              </li>
              <li>
                Erasure ('right to be forgotten') - you have the right to have
                your Personal Information 'erased' in certain specified
                situations.
              </li>
              <li>
                Restriction of processing – you have the right in certain
                specified situations to require us to stop processing your
                Personal Information.
              </li>
              <li>
                Object to processing – You have the right to object to specific
                types of processing of your Personal Information, such as, where
                we are processing your Personal Information for the purposes of
                direct marketing.
              </li>
              <li>
                Prevent automated decision-taking – in certain circumstances,
                you have the right not to be subject to decisions being taken
                solely on the basis of automated processing.
              </li>
            </ul>
            <p>
              For further information on each of those rights, including the
              circumstances in which they do and do not apply, please contact us
              using the details set forth herein. You may also find it helpful
              to refer to the guidance from the UK’s Information Commissioner on
              your rights under the UK GDPR.
            </p>
            <p>
              <b>10. a) No fee typically required</b>
            </p>
            <p>
              You will not have to pay a fee to access your Personal Information
              (or to exercise any of the other rights). However, we may charge a
              reasonable fee if your request is clearly unfounded, repetitive,
              or excessive. Alternatively, we could refuse to comply with your
              request in such circumstances.
            </p>
            <p>
              <b>10. b) What we may need from you</b>{" "}
            </p>
            <p>
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access your
              Personal Information (or to exercise any of your other rights).
              This is a security measure to ensure that Personal Information is
              not disclosed to any person who has no right to receive it. We may
              also contact you to ask you for further information in relation to
              your request to expedite our response.
            </p>
            <p>
              <b>10. c) Rights related request</b>
            </p>
            <p>
              If you wish to enforce any of your rights under applicable Data
              Protection Laws, then please see section 1 above. We will respond
              to your request without undue delay and by no later than one month
              from receipt of any such request, unless a longer period is
              permitted by applicable data protection laws, and we may charge a
              reasonable fee for dealing with your request which we will notify
              you. Please note that we will only charge a fee where we are
              permitted to do so by applicable Data Protection Laws.
            </p>
            <li class="l">How to complain</li>
            <p>
              If you disagree with how we are processing your data, please
              contact our DPO at
              <a href="info@saunderstrainingacademy.co.uk">
                info@saunderstrainingacademy.co.uk
              </a>{" "}
              or address your letter to the DPO at the Saunders Training Academy
              Headquarters address listed in the ‘Contact Details' section.
            </p>
            <p>
              You can also complain to the ICO if you are unhappy with how we
              have used your data. If you are concerned that we have not
              complied with your legal rights under applicable data protection
              laws, you
            </p>
            <p>
              may contact the Information Commissioner's Office (
              <a href="www.ico.org.uk">www.ico.org.uk</a> ) which is the data
              protection regulator in the UK which is where we are located.
            </p>
            <p>
              The ICO’s address is: Information Commissioner’s Office Wycliffe
              House Water Lane Wilmslow Cheshire SK9 5AF <br />
              Helpline number: 0303 123 1113
            </p>
            <p>
              Alternatively, if you are based outside the UK, you may contact
              your local data protection supervisory authority.
            </p>
            <li class="l">Third-party links</li>
            <p>
              Our Platform may contain links to other third party websites that
              are not operated by us. These linked sites and applications are
              not under our control and as such, we are not responsible for the
              privacy practices or the content of any linked websites and online
              applications. If you choose to use any third party websites, any
              Personal Information collected by the third party’s website will
              be controlled by the Privacy Notice of that third party. We
              strongly recommend that you take the time to review the privacy
              policies of any third parties to which you provide Personal
              Information.
            </p>
            <li class="l">What are cookies</li>
            <p>
              We may use cookies on our website. Cookies are small text files
              that can be read by a web server in the domain that puts the
              cookie on your hard drive. Cookies are assigned to and stored in a
              user's internet browser on a temporary (for the duration of the
              online session only) or persistent basis (cookie stays on the
              computer after the internet browser or device has been closed).
              Cookies collect and store information about a user’s preferences,
              product usage and content viewed which allows for us to provide
              users with an enhanced and customized experience when engaging
              with the website.
            </p>
            <li class="l">
              Your Choices (e.g. marketing-related emails or otherwise)
            </li>
            <p>
              When you request information on or from the website, or otherwise
              communicate with us, we may use your Personal Information (such as
              your contact details (e.g. name, address, email address, telephone
              number)) to send you marketing-related correspondence by email
              related to our products or services. When we process your Personal
              Information for marketing purposes, we do so on the basis that it
              is in our legitimate interests to use your Personal Information
              for these purposes or with your consent, where required by Data
              Protection Law.
            </p>
            <p>
              We may also use your Personal Information to personalise and to
              target more effectively our marketing communications to ensure, to
              the extent possible, that any marketing-related correspondence is
              relevant to you.
            </p>
            <p>
              We may ask you to confirm or update your marketing preferences if
              you ask us to provide further services in the future, or if there
              are changes in the law, regulation, or the structure of our
              business.
            </p>
            <li class="l">
              Changes to this Privacy Notice and your duty to inform us of
              changes
            </li>
            <p>
              It is important that you check back often for updates to the
              Privacy Notice, as we may change this Privacy Notice from time to
              time. The “Date last updated” legend at the bottom of this page
              states when the notice was last updated, and any changes will
              become effective upon our posting of the revised Privacy Notice.
            </p>
            <p>
              We will provide notice to you if these changes are material and,
              where required by applicable law, we will seek your consent. We
              will provide this notice by email or by posting notice of the
              changes on our website
            </p>
            <p>
              It is also important that the Personal Information we hold about
              you is accurate and current. Please keep us informed if your
              Personal Information changes during your relationship with us.
            </p>
          </div>

          <div
            class="image"
            className="w60 d-flex justify-content-center gap-x-2 mx-auto align-items-center"
          >
            {/* <img src="/tra.png" alt="image" className="w-100" /> */}
            <img className="mx-2" src={LogoDark} alt="logo-dark" />
            <h3 className="text-center">Cookie Policy</h3>
          </div>
          <br />
          <div class="container">
            <p>(Updated 1 January 2024)</p>
            <p>
              Our website uses cookies to distinguish you from other users of
              our website. This helps us to provide you with a good experience
              when you browse our website and also allows us to improve our
              site.
            </p>
            <p>
              A cookie is a small file of letters and numbers that we store on
              your browser or the hard drive of your computer if you agree.
              Cookies contain information that is transferred to your computer's
              hard drive.
            </p>
            <p>We use the following cookies</p>
            <p>
              <b>Strictly necessary cookies.</b> These are cookies that are
              required for the operation of our website. They include, for
              example, cookies that enable you to log into secure areas of our
              website, or cookies that remember information you have previously
              entered to complete a form as you proceed through a journey on our
              website.
            </p>
            <p>
              <b>Analytical/performance cookies.</b> These cookies allow us to
              recognise and count the number of visitors and to see how visitors
              move around our website when they are using it. This helps us to
              improve our website and enhance users' experience.
            </p>
            <p>
              <b>Functionality cookies.</b> These are used to recognise you when
              you return to our website. This enables us to personalise our
              content for you and remember your preferences (for example, your
              choice of language or region).
            </p>
            <p>
              <b>Targeting cookies.</b> These cookies record your visit to our
              website, the pages you visit and the links you follow. We use this
              information to make our website and any advertising we may choose
              to display on it more relevant to your interests. We may also
              share this information with third parties for this purpose.
            </p>
            <p>
              Please note that third parties (including, for example Google
              Analytics, Sumo, ShareThis, Intercom, Drip, Median, Facebook,
              Cloudflare, Twitter, Segment advertising networks and providers of
              external services like web traffic analysis services) may also use
              cookies, over which we have no control. These cookies are likely
              to be analytical/performance cookies or targeting cookies.
            </p>
            <p>
              You can block cookies by activating the setting on your browser
              that allows you to refuse the setting of all or some cookies.
              However, if you use your browser settings to block all cookies
              (including essential cookies) you may not be able to access all or
              parts of our site and your user experience may be impacted.
            </p>
            <p>
              We also use certain other tracking technologies in addition to
              cookies, such as:
            </p>
            <ul>
              <li>
                Facebook Pixel, Twitter Pixel Helper, Google Analytics, Google
                Ads Display, LinkedIn Insight, Drip Analytics, Intercom,
                Attribution App, GoSquared, Median
              </li>
            </ul>
          </div>
        </div>
      </Content>
    </React.Fragment>
  );
}

export default Privacy;
