import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Alert, Form } from "reactstrap";
import { Block, Button, Col, Icon } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { addUserRole, editUserRole, getCourseList } from "./RolesData";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";

const AddRole = (props) => {
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
  });

  const [errorVal, setError] = useState();
  const [courseList, setCourseList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [roleId, setRoleId] = useState();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    loadCourses();
  }, []);

  useEffect(() => {
    setSelectAll(courseList.every((item) => item.checked));
  }, [courseList]);

  const showDetails = (state) => {
    setRoleId(state.roleId);
    setFormData((prevData) => ({ ...prevData, title: state.name }));
    setCourseList((prevData) =>
      prevData.map((item) => ({
        ...item,
        checked: state.courses.some((e) => e.id === item.id),
      }))
    );
  };

  const toggleSelectAll = (e) => {
    const newState = e.currentTarget.checked;
    setSelectAll(newState);
    setCourseList((prevData) =>
      prevData.map((item) => ({ ...item, checked: newState }))
    );
  };

  const onSelectChange = (e, id) => {
    const newState = e.currentTarget.checked;
    setCourseList((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, checked: newState } : item
      )
    );
    setSelectAll(
      courseList
        .filter((item) => item.id !== id)
        .every((item) => item.checked) && newState
    );
  };

  const onInputChange = (e) => {
    setFormData((prevData) => ({ ...prevData, title: e.target.value }));
  };

  const onFormSubmit = (e) => {
    // check if the title input field is not empty
    const isTitleValid = formData.title.trim();
    setIsTitleValid(isTitleValid);

    // check if all checkboxes are selected
    const isAllChecked = courseList.some((item) => item.checked === true);

    // if the form is valid, submit it
    if (isTitleValid && isAllChecked) {
      let name = formData.title.trim();
      let ids = courseList
        .filter((item) => item.checked === true)
        .map((item) => item.id);
      if (roleId) {
        updateRole(roleId, name, ids);
      } else {
        createRole(name, ids);
      }
    }
  };

  function moveToStep2(data) {
    props.setState("data", {
      roleId: data.id,
      name: data.name,
      courses: data.courses,
    });
    props.next();
  }

  //******************API************************/
  function loadCourses() {
    getCourseList(true, (data, error) => {
      if (error === null) {
        setCourseList(data);
        if (props.state.data && props.state.data?.roleId) {
          showDetails(props.state.data);
        } else if (location.state && location.state.roleId) {
          showDetails(location.state);
        }
      }
      setIsInitScreen(false);
    });
  }

  function createRole(name, ids) {
    let params = {
      name: name,
      courseIds: ids,
    };
    addUserRole(params, (data, error) => {
      if (error === null) {
        moveToStep2(data);
      } else {
        setError(error.message);
      }
    });
  }

  function updateRole(id, name, ids) {
    let params = {
      id: id,
      name: name,
      courseIds: ids,
    };

    editUserRole(params, (data, error) => {
      if (error === null) {
        moveToStep2(data);
      } else {
        setError(error.message);
      }
    });
  }
  //*********************************************/

  return (
    <React.Fragment>
      <Head title="Role" />
      {!isInitScreen && (
        <Content>
          {errorVal && (
            <div className="mb-5 mt-n4">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
          <Block>
            <Form className="row gy-3 mt-n5">
              <Col md="4" lg="3" sm="12">
                <div className="form-group">
                  <label className="form-label">Role title</label>
                  <input
                    className={`form-control ${
                      isTitleValid ? "" : "is-invalid"
                    }`}
                    value={formData.title}
                    onChange={onInputChange}
                    type="text"
                    name="name"
                    defaultValue={formData.name}
                  />
                  {!isTitleValid && (
                    <p className="invalid">Please enter a valid title</p>
                  )}
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="form-label">
                    Select the required courses for this Role
                  </label>
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="pid-all"
                      checked={selectAll}
                      onChange={toggleSelectAll}
                    />
                    <label className="custom-control-label" htmlFor="pid-all">
                      Select all
                    </label>
                  </div>
                </div>
              </Col>
              {courseList.map((item, index) => (
                <React.Fragment key={index}>
                  <Col lg="4" xxl="3" md="6" sm="12" key={item.courses}>
                    <div className="form-group">
                      <span className="nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`pid-${item.id}`}
                            checked={item?.checked ?? false}
                            onChange={(e) => onSelectChange(e, item.id)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`pid-${item.id}`}
                          >
                            {item.title}
                          </label>
                        </div>
                      </span>
                    </div>
                  </Col>
                </React.Fragment>
              ))}
              <Col md="12">
                <h6>
                  Set the due date and renewal frequency for each selected
                  course on the next page
                </h6>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <Button
                    className="btn btn-primary custom-solid-btn-sm"
                    type="button"
                    onClick={() => {
                      onFormSubmit();
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </Col>
            </Form>
          </Block>
        </Content>
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default AddRole;
