import React from "react";

const ResultedQuestion = ({ question, isanswer, answer, key }) => {
  return (
    <div>
      <div className="mb-4">
        <p className="fs-6 fw-500 text-dark mb-3">{question}</p>
        <ResultAnswer isCorrect={isanswer} answers={answer} />
      </div>
    </div>
  );
};

export default ResultedQuestion;

export const ResultAnswer = ({ isCorrect, answers }) => {
  return (
    <div
      className={`${
        isCorrect ? "bg-success-dim" : "bg-danger-dim"
      } bg-white rounded-3 d-flex align-items-center p-2`}
    >
      <span className="me-2">
        {isCorrect ? (
          <em className="ni ni-check text-success fs-5"></em>
        ) : (
          <em className="ni ni-cross-sm text-danger fs-5"></em>
        )}
      </span>

      {answers.length > 0 && <p className="fw-500 m-0">{answers.join(", ")}</p>}
    </div>
  );
};
