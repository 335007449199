import React from "react";
import {
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from "../../components/Component";
import { useHistory } from "react-router";
import { Spinner } from "reactstrap";
import { UserAccountType, showErrorToast } from "../../utils";
import { Storage } from "../../services/storage/storage";

const Matrix = ({
  isLoading,
  hList,
  vList,
  matchItem,
  showStatus,
  onEventClick,
}) => {
  const history = useHistory();

  const showStaffClick = (item) => {
    let currentUser = Storage.getLoggedInUser();
    if (
      currentUser &&
      ((currentUser.id !== item.userid && item.accountType !== UserAccountType.admin) ||
        currentUser.accountType === UserAccountType.admin)
    ) {
      history.push({
        pathname: `${process.env.PUBLIC_URL}/staff-training`,
        state: { id: item.userid },
      });
    } else {
      showErrorToast("You can not able to view and modify your own/admin profile");
    }
  };

  return (
    <>
      <div className="matrix-table w-100 mx-auto border">
        <DataTable
          id="style-3"
          className="card-stretch overflow-auto min-h-calc_100vh_-_220 border border-light-gray w-100 mx-auto border"
        >
          <DataTableBody>
            <DataTableHead>
              {hList?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <DataTableRow className=" border-light-gray-right text-center px-2">
                      <span className="sub-text text-black fw-semibold">
                        {item?.title}
                      </span>
                    </DataTableRow>
                  </React.Fragment>
                );
              })}
            </DataTableHead>
            {vList?.length > 0 &&
              vList?.map((vItem, vIndex) => {
                return (
                  <DataTableItem key={vIndex} className="t-body">
                    {hList?.map((hItem, index) => {
                      let userVData = matchItem(hItem, vItem) ?? null;
                      let data = showStatus(userVData);
                      return (
                        <React.Fragment key={index}>
                          <DataTableRow className=" border-light-gray-right text-center px-2">
                            {hItem.id === -1 ? (
                              <>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    showStaffClick(vItem);
                                  }}
                                >
                                  <span className="sub-text d-block text-black text-decoration-underline fw-semibold">
                                    {`${vItem.firstName}
                                    ${vItem.lastName}`}
                                  </span>
                                  <span className="sub-text d-block text-black text-decoration-underline fw-semibold ">
                                    {vItem.email ? vItem.email : null}
                                  </span>
                                </span>
                              </>
                            ) : hItem.id === 0 ? (
                              <span className="sub-text ">
                                {`${
                                  vItem?.compliancePercentage === undefined
                                    ? 0
                                    : vItem?.compliancePercentage
                                }%`}
                              </span>
                            ) : (
                              <>
                                {data.text && (
                                  <span
                                    className={`badge cursor-pointer bg-${data?.theme}-dim
                                    text-black border-0 py-1 px-2 fs-0_775rem`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (data?.sweetAlert === true) {
                                        onEventClick(hItem, vItem, vIndex);
                                      }
                                    }}
                                  >
                                    {data?.text
                                      ? data?.text?.date
                                        ? data?.text?.date
                                        : data?.text
                                      : ""}
                                    <br />
                                    {data?.text ? data?.text?.time : ""}
                                  </span>
                                )}
                              </>
                            )}
                          </DataTableRow>
                        </React.Fragment>
                      );
                    })}
                  </DataTableItem>
                );
              })}
          </DataTableBody>
          <span className="d-flex justify-content-center">
          {isLoading && (
              <DataTableItem>
                <DataTableRow className={"d-flex justify-content-center"}>
                  <td colSpan={hList?.length}>
                    <div className="text-center  my-5" >
                      <Spinner size={"md"} color="primary" />
                    </div>
                  </td>
                </DataTableRow>
              </DataTableItem>
            )}
            </span>
          {vList?.length === 0 && isLoading === false && (
            <p className="p-5 text-center">DATA NOT FOUND</p>
          )}
        </DataTable>
      </div>
    </>
  );
};

export default Matrix;
