const myTimeout = setTimeout(myStopFunction, 100);

function myStopFunction() {
  const para = document.createElement("div");
  para.classList.add("table-div");
  //   para.innerHTML = "This is a paragraph.";
  const table = document.querySelector("#course-chapter-details table");
  const sElement = document
    .getElementById("course-chapter-details")
    .appendChild(para)
    .appendChild(table);
}
