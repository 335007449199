import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Matrix from "../matrix/Matrix";
import Swal from "sweetalert2";
import { fetchPolicyList, getPolicyMatrix, policyUnread } from "./PolicyData";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Component";
import moment from "moment/moment";

const Policies = () => {
  const [policyData, setPolicyData] = useState();
  const [userdata, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [matrixPageInfo, setMatrixPageInfo] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    loadPolicyList();
    setIsLoading(false);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    const scrollableDiv = document.getElementById("style-3");
    scrollableDiv.addEventListener("scroll", handleScroll, true);
    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll, true);
    };
  }, [currentPage, isLoading]);

  useEffect(() => {
    loadPolicyMatrix();
  }, [currentPage]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } =
      document.getElementById("style-3");
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
      if (currentPage !== matrixPageInfo.totalPages) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  function loadPolicyList() {
    const pageInfo = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: 50,
      },
    };
    fetchPolicyList(pageInfo, (data, error) => {
      if (error === null) {
        setPolicyData(data);
      }
    });
  }
  function loadPolicyMatrix() {
    const pageInfo = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: 15,
      },
    };
    setIsLoading(true);
    getPolicyMatrix(pageInfo, (data, error) => {
      if (error === null) {
        if (currentPage === 1) {
          setUserData(data.userdata);
          setMatrixPageInfo(data.pageinfo);
        } else {
          let list = [...userdata];
          list.push(...data.userdata);
          setUserData(list);
        }
      }
      setIsLoading(false);
    });
  }
  const callSweetAlert = (policy, user, userIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to reset policy read date ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, reset it!",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          policyId: policy.id,
          userId: user.userid,
        };
        policyUnread(params, (data, error) => {
          if (error === null) {
            let updatedPolicy = policy;
            updatedPolicy.readAt = null;

            var updatedUser = user;
            let policies = [...updatedUser?.policies];

            let policyIndex = policies?.findIndex((e) => e.id === policy.id);

            policies[policyIndex] = updatedPolicy;
            updatedUser.policies = policies;

            let list = [...userdata];
            list[userIndex] = updatedUser;
            setUserData(list);
          }
        });
      }
    });
  };
  return (
    <>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className={"mx-auto"}>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Policy Matrix
              </BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Matrix
          isLoading={isLoading}
          hList={policyData}
          vList={userdata}
          onEventClick={(policy, user, userIndex) =>
            callSweetAlert(policy, user, userIndex)
          }
          matchItem={(hItem, vItem) => {
            let items = vItem.policies.filter((item) => item.id === hItem.id);
            if (items.length === 1) {
              return items[0];
            }
            return null;
          }}
          showStatus={(item) => {
            if (item === null || item === undefined) {
              return "";
            } else {
              return item.readAt
                ? {
                    text: {
                      date: moment(item.readAt).format("DD-MM-YYYY"),
                      time: moment(item.readAt).format("hh:mm A"),
                    },
                    theme: "success",
                    sweetAlert: true,
                  }
                : {
                    text: "Pending",
                    theme: "warning",
                  };
            }
          }}
        />
      </Content>
    </>
  );
};

export default Policies;
