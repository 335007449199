import React from "react";
import Button from "./Button";
import { Spinner } from "reactstrap";
import { Icon } from "../Component";

const ActionButton = ({
  className,
  type,
  title,
  size,
  loading,
  disabled,
  color,
  ...props
}) => {
  return (
    <Button
      size={size}
      className={`btn-block ${
        disabled
          ? "btn-light"
          : size
          ? `custom-solid-btn-${size}`
          : "custom-solid-btn-sm"
      } ${className}`}
      color={disabled && "light"}
      disabled={loading || disabled}
      onClick={props.onClick}
      type={type ?? "button"}
    >
      {loading ? <Spinner size="sm" color="light" /> : title}
    </Button>
  );
};
export default ActionButton;
