import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CardTitle } from "reactstrap";
import { Button } from "../../components/Component";
import { BarChart } from "./BarChart";
import { saleRevenue } from "./DashboardData";
import moment from "moment/moment";

export const Duetraining = ({ data, chartData, onDidPressed }) => {
  return (
    <>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">Overdue training</h6>
        </CardTitle>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="cursor"
        href={undefined}
        onClick={(ev) => {
          ev.preventDefault();
          onDidPressed();
        }}
      >
        <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
          <div className="nk-sale-data">
            <span className="amount">{data?.overdue?.overdue ?? 0} staff</span>
            <span className="sub-title">
              Total staff {data?.staff?.active ?? 0}
            </span>
          </div>
          {/* <div className="nk-sales-ck sales-revenue"> */}
          <div className="nk-sales-ck">
            <BarChart data={chartData} />
          </div>
        </div>
      </a>
    </>
  );
};
export const Certificates = ({ data, chartData, onPressed }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={undefined}
      className=""
      onClick={(ev) => {
        ev.preventDefault();
        onPressed();
      }}
    >
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">New certificates</h6>
        </CardTitle>
      </div>
      <div className="d-flex align-md-end flex-column flex-md-row flex-sm-wrap g-4 flex-md-nowrap">
        <div className="nk-sale-data">
          <span className="amount">
            {data?.totalCertificateLastWeek ?? 0} certificates
          </span>
          <span className="sub-title">
            Last week (
            {moment()
              .subtract(1, "weeks")
              .startOf("isoWeek")
              .format("DD-MM-YYYY")}{" "}
            to{" "}
            {moment()
              .subtract(1, "weeks")
              .endOf("isoWeek")
              .format("DD-MM-YYYY")}
            )
          </span>
        </div>
        <div className="nk-sales-ck">
          <BarChart data={chartData} />
        </div>
      </div>
    </a>
  );
};
export const FaceToFaceMeetings = ({ data, chartData }) => {
  return (
    <div className="ftf">
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">Upcoming FTF</h6>
        </CardTitle>
      </div>
      <Link to={`${process.env.PUBLIC_URL}/manage-face-to-face`}>
        <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
          <div className="nk-sale-data">
            <span className="amount">{data?.totalFacetofaceCount ?? 0}</span>
            <span className="sub-title">Meetings</span>
          </div>
        </div>
        {/* <div className="nk-sales-ck">
          <BarChart data={chartData} />
        </div> */}
      </Link>
    </div>
  );
};
export const PolicyLists = ({ data, onPressed, onMatrix }) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href={undefined}
        className="cursor"
        onClick={(ev) => {
          ev.preventDefault();
          onPressed();
        }}
      >
        <div className="card-title-group align-start mb-2">
          <CardTitle>
            <h6 className="title">Policies</h6>
          </CardTitle>
        </div>
        <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
          <div className="nk-sale-data">
            <span className="amount">{data?.totalPolicy ?? 0}</span>
            <span className="sub-title">
              <h6 className="text-muted">Total policies</h6>
              {""}&nbsp;{" "}
            </span>
          </div>
        </div>
      </a>
      <div className="mt-1">
        <Button
          color="primary"
          className="btn btn-info me-n1"
          onClick={(ev) => {
            ev.preventDefault();
            onMatrix();
          }}
        >
          Matrix
        </Button>
      </div>
    </>
  );
};
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const formattedDate = `${yesterday.getDate().toString().padStart(2, "0")}-${(
  yesterday.getMonth() + 1
)
  .toString()
  .padStart(2, "0")}-${yesterday.getFullYear()}`;

export const Training = ({ data, chartData, onTraining }) => {
  return (
    <>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">Mandatory Training</h6>
        </CardTitle>
        {/* <div className="mt-1">
        <a
          href={`${process.env.PUBLIC_URL}/matrix`}
          className="btn btn-dim btn-primary mt-n2 me-n1"
        >
          Matrix
        </a>
        <a
          href={`${process.env.PUBLIC_URL}/location`}
          className="btn btn-dim btn-primary mt-n2 me-n1 ms-2"
        >
          Locations
        </a>
        <a
          href={undefined}
          className="btn btn-dim btn-primary mt-n2 me-n1 ms-2"
          onClick={(ev) => {
            ev.preventDefault();
            onTraining();
          }}
        >
          Training
        </a>
        
      </div> */}
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-start flex-sm-wrap g-4 flex-md-nowrap">
          <div className="nk-sale-data">
            <span className="amount">
              {data?.mandatoryTraining?.average ?? 0}%
            </span>
            <span className="sub-title">
              <h6 className="text-muted mb-0">Total Compliance</h6>
              <span className="d-block d-sm-inline me-1">
                {data?.mandatoryTraining?.fully ?? 0} Fully compliant out of{" "}
                {data?.staff?.active ?? 0}
              </span>
            </span>
          </div>
        </div>

        <div className="d-flex flex-column align-items-start flex-sm-wrap g-4 flex-md-nowrap">
          <div className="nk-sale-data">
            <span className="amount">
              {data?.mandatoryTraining?.averageComplianceInPercentage ?? 0}%
            </span>
            <span className="sub-title">
              <h6 className="text-muted mb-0">Avg Compliance</h6>
              <span className="d-block d-sm-inline">As on {formattedDate}</span>
            </span>
          </div>
        </div>
      </div>
      {/* <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
        <div className="nk-sale-data">
          <span className="amount">
            {data?.mandatoryTraining?.average ?? 0}%
          </span>
          <span className="sub-title">
            <h6 className="text-muted">Average Compliance</h6>
            {data?.mandatoryTraining?.fully ?? 0} Fully compliant out of{" "}
            {data?.staff?.active ?? 0}
          </span>
        </div>
      </div> */}
      <div className="nk-sales-ck sales-revenue-lg">
        <BarChart data={chartData} />
      </div>
      <div className="mt-3">
        <a
          href={`${process.env.PUBLIC_URL}/matrix`}
          className="btn btn-info me-n1"
        >
          Matrix
        </a>
        <a
          href={`${process.env.PUBLIC_URL}/location`}
          className="btn btn-info me-n1 ms-2"
        >
          Locations
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href={undefined}
          className="btn btn-info me-n1 ms-2"
          onClick={(ev) => {
            ev.preventDefault();
            onTraining();
          }}
        >
          Training
        </a>
      </div>
    </>
  );
};

export const ActiveStaff = ({ data, onManageStaff }) => {
  return (
    <>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">Active Staff</h6>
        </CardTitle>
      </div>
      <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
        <div className="nk-sale-data">
          <span className="amount">{data?.staff?.active ?? 0}</span>
          <span className="sub-title">
            <h6 className="text-muted">Staff Members</h6>
            {data?.staff?.paused ?? 0} Paused out of {data?.staff?.total ?? 0}
          </span>
        </div>
      </div>
      <div className="mt-1">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href={undefined}
          className="btn btn-info me-n1"
          onClick={(ev) => {
            ev.preventDefault();
            onManageStaff();
          }}
        >
          Manage staff
        </a>
        <a
          href={`${process.env.PUBLIC_URL}/roles-list`}
          className="btn btn-info me-n1 ms-2"
        >
          Manage roles
        </a>
      </div>
    </>
  );
};
