
import React from "react";
import { Progress } from "reactstrap";

const ProgressColor = ({value }) => {
    return (
        <div className="project-progress w-max-150px">
            <Progress
                className="progress-pill progress-md bg-light"
                color={
                    value === 100
                        ? "success"
                        : value >= 60
                            ? "warning"
                            : value > 0
                                ? "danger"
                                : "secondary"
                }
                value={value}
            ></Progress>
            <div className="project-progress-percent">
                {(value)}%
            </div>
        </div>
    );
};

export default ProgressColor;


