import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Button,
  DataTable,
  DataTableBody,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import AddOutcome from "./AddOutcome";
import { CourseWizardContext } from "./CourseWizard";

const CourseOutcome = (props) => {
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(false);
  const { data, updateData } = React.useContext(CourseWizardContext);

  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });

  const updateOutcome = (list) => {
    updateData("outcome", list);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setModal({ edit: true });
  };

  const handleRemove = (value, index) => {
    let list = (data?.outcome ?? []).filter((item) => item !== value);
    updateOutcome(list);
  };

  const onNextClick = () => {
    // if ((data?.outcome ?? []).length === 0) {
    //   setError("Please add at least one outcome");
    // } else {
    //   setError(null);
    // }
    props.next();
  };

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="align-items-end">
            <BlockHeadContent className=" align-items-center ms-auto">
              <Button
                color="primary"
                className="btn-text custom-solid-btn-sm"
                onClick={() => setModal({ add: true })}
              >
                Add outcome
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          {(data?.outcome ?? []).length > 0 ? (
            <DataTable className="card-stretch">
              <DataTableBody>
                {(data?.outcome ?? []).length > 0
                  ? (data?.outcome ?? []).map((item, index) => {
                      return (
                        <DataTableItem key={index}>
                          <DataTableRow>
                            <div className="project-info">
                              <h6 className="title">{item}</h6>
                            </div>
                          </DataTableRow>
                          <DataTableRow className="nk-tb-col-tools text-end">
                            <ul className="nk-tb-actions gx-1">
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={() =>
                                            handleEdit(item, index)
                                          }
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#delete"
                                          onClick={() =>
                                            handleRemove(item, index)
                                          }
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Remove</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
            </DataTable>
          ) : (
            <div className="text-center">
              <span className="text-silent">
                You have not added any outcome yet.
              </span>
              {error && (
                <div className="text-center">
                  <span className="text-danger">{error}</span>
                </div>
              )}
            </div>
          )}

          <div className="actions clearfix">
            <ul>
              <li className=" align-items-center ms-auto">
                <Button
                  color="primary"
                  type="submit"
                  className="custom-solid-btn-sm"
                  onClick={() => onNextClick()}
                >
                  Next
                </Button>
              </li>
              <li>
                <Button color="light" type="button" onClick={props.prev}>
                  Back
                </Button>
              </li>
            </ul>
          </div>
        </Block>
        <AddOutcome
          visible={modal.edit || modal.add}
          item={selectedItem}
          onClosed={() => {
            setModal({ add: false });
            setSelectedItem();
          }}
          onSaved={(item) => {
            let list = [...(data?.outcome ?? [])];
            if (selectedItem) {
              let index = list.findIndex((e) => e === selectedItem);
              if (index >= 0) {
                list[index] = item;
              }
            } else {
              list.push(item);
            }
            updateOutcome(list);
            setModal({ add: false });
            setSelectedItem();
          }}
        />
      </Content>
    </React.Fragment>
  );
};

export default CourseOutcome;
