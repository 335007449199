import moment from "moment";
import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-service-impl";
import { removeTimeFromServerDateString } from "../../utils";

export const fetchDashboard = (locationId, callback) => {
  apiService
    .postCall(APIServicePath.dashboard, { locationId: locationId })
    .then((result) => {
      callback(result.data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const fetchChatData = (locationId, start, end, callback) => {
  apiService
    .postCall(APIServicePath.dashboardGraph, {
      locationId: locationId,
      startDate: start,
      endDate: end,
    })
    .then((result) => {
      let data = {};
      
      if (result.certGraphData) {
        data.certificates = prepareCertificatesChat(
          result.certGraphData
        );
      }

      if (result.complianceGraphData) {
        data.compliance = prepareComplianceChat(start, end, result.complianceGraphData);
      }

      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

function dateToShow(date, format) {
  let dueDate = moment(removeTimeFromServerDateString(date), "YYYY-MM-DD");
  return dueDate.format(format ? format : "DD-MM-YYYY");
}

function prepareCertificatesChat(list) {
  var labels = [];
  var backgroundColor = [];
  var data = [];

  for (let index = list?.length - 1; index >= 0; index--) {
    const element = list[index];
    data.push(element.total);
    backgroundColor.push(
      "rgba(0,110,255,1)"
      );
    let date = dateToShow(element.date, "DD/MM");

    labels.push(date);
  }


  return {
    labels: labels,
    dataUnit: " Certificates",
    stacked: false,
    datasets: [
      {
        label: "Certificates",
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        backgroundColor: backgroundColor,
        data: data,
      },
    ],
  };
}

function prepareComplianceChat(start, end, list) {
  var labels = [];
  var backgroundColor = [];
  var data = [];

  let startDate = moment(start, "YYYY-MM-DD");
  let endDate = moment(end, "YYYY-MM-DD");

  while (startDate.toDate() <= endDate.toDate()) {

    let filterList = list.filter(
      // eslint-disable-next-line no-loop-func
      (item) => dateToShow(item.date) === startDate.format("DD-MM-YYYY")
    );

    if (filterList.length > 0) {
      const element = filterList[0];
      data.push(element.percentage);
      backgroundColor.push(
        "rgba(0,110,255,1)"
      );
    } else {
      data.push(0);
      backgroundColor.push("rgba(0,110,255, 0.2)");
    }
    labels.push(startDate.format("DD/MM"));
    startDate = startDate.add(1, "days");
  }

  return {
    labels: labels,
    dataUnit: "%",
    stacked: false,
    datasets: [
      {
        label: "Compliance",
        barPercentage: 0.6,
        categoryPercentage: 0.6,
        backgroundColor: backgroundColor,
        data: data,
      },
    ],
  };
}
