import React, { useEffect } from "react";
import Content from "../../layout/content/Content";

import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Component";
import { useState } from "react";
import { Card, Spinner } from "reactstrap";
import { useHistory, useLocation } from "react-router";
import { readPolicy } from "./PolicyData";
import { Storage } from "../../services/storage/storage";

const PolicyView = () => {
  const history = useHistory();
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);
  const [userInfo, setUserInfo] = useState(Storage.getLoggedInUser());
  
  const submitReadPolicy = () => {
    const params = {
      policyId: location?.state?.data?.id,
    };
    setIsLoader(true);
    readPolicy(params, (data, error) => {
      if (error === null) {
        setIsLoader(false);
        history.push(`${process.env.PUBLIC_URL}/learner-policies`);
      }
    });
  };
  return (
    <>
      <Content>
        <Block className="wide-md mx-auto">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {location?.state?.data?.name}
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Card className="card-bordered h-calc_100vh_-_316">
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              height="100%"
              width="100%"
              src={`https://docs.google.com/gview?url=${location?.state?.data?.url}&embedded=true`}
            ></iframe>
          </Card>
          {userInfo.accountType === "STAFF" ? (
            location?.state?.data?.policyRead?.completedAt ? null : (
              <div className="mt-2 text-end">
                <button
                  className="btn btn-primary btn-text custom-solid-btn-sm"
                  onClick={submitReadPolicy}
                  disabled={isLoader}
                >
                  {isLoader ? <Spinner size="sm" color="light" /> : "Accept"}
                </button>
              </div>
            )
          ) : null}
        </Block>
      </Content>
    </>
  );
};

export default PolicyView;
