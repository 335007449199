export const menu = [
  {
    text: "Dashboard",
    link: "/",
  },
  {
    text: "Staff",
    link: "/staff",
  },
  {
    text: "Face to face",
    link: "/manage-face-to-face",
  },
  // {
  //   text: "Policies",
  //   link: "/policy-list",
  // },
  {
    text: "Resources",
    link: "/resource-folders",
  },
  {
    text: "Support",
    link: "/support",
  },
];

export const learnerMenu = [
  {
    text: "Trainings",
    link: "/",
  },
  {
    text: "Face to face",
    link: "/learner-face-to-face",
  },
  // {
  //   text: "Policies",
  //   link: "/learner-policies",
  // },
  {
    text: "Resources",
    link: "/resource-folders",
  },
  {
    text: "CPD Trainings",
    link: "/cpd-list",
  },
];
