import React from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../../components/Component";
import { TrainingCard } from "./TrainingCard";
import { Card } from "reactstrap";
import { useLocation } from "react-router";

const ViewAllCourse = (props) => {
  const location = useLocation();
  return (
    <React.Fragment>
      <Head title="all-course" />
      <Content>
        <div className="wide-lg mx-auto">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {location.state?.title ?? ""}
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <div className="d-flex flex-wrap ">
            {location.state?.data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-12 col-sm-12 col-md-6 col-lg-4"
                >
                  <Card className=" me-3 mb-4 ">
                    <TrainingCard item={item} isMandatory={location.state?.isMandatory ?? false} />
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default ViewAllCourse;
