import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import {
  Alert,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockContent,
  BlockHead,
  Button,
  DataTable,
  DataTableBody,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import { saveCourseDetail } from "../TrainingData";
import AddTest from "./AddTest";
import { CourseWizardContext } from "./CourseWizard";

const CourseTest = (props) => {
  const history = useHistory();

  const [selectedQuestion, setSelectedQuestion] = useState(false);
  const [error, setError] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState();
  const [loading, setLoading] = useState(false);
  const { data, updateData } = React.useContext(CourseWizardContext);

  const handleEdit = (item, index) => {
    setSelectedQuestion(item);
    setSelectedQuestionIndex(index);
    setModal(true);
  };

  const updateQuestions = (list) => {
    updateData("questions", list);
  };

  const handleRemove = (value) => {
    let list = (data?.questions ?? []).filter((item) => item !== value);
    updateQuestions(list);
  };

  const onSubmitClick = () => {
    // if ((data?.questions ?? []).length === 0) {
    //   setError("Please add at least one test");
    // } else {
    //   setError(null);
    //   saveCourse();
    //   // history.push(`${process.env.PUBLIC_URL}/training-list`);
    // }
    saveCourse();
  };

  function questionList(questions) {
    let list = [];
    for (let index = 0; index < questions?.length ?? 0; index++) {
      const item = questions[index];
      let question = {
        id: (item.id ?? 0) > 0 ? item.id : undefined,
        description: item.question,
        type: item.questionType,
        options: item.options,
        correctAnswer: item.answerIndexes,
      };
      list.push(question);
    }
    return list;
  }

  //*******************API's************************/
  const saveCourse = () => {
    let intro = (data?.chapters?.length ?? 0) > 0 ? data?.chapters[0].html : "";
    let chapters = [];
    for (let index = 1; index < data?.chapters?.length ?? 0; index++) {
      const item = data?.chapters[index];
      let chapter = {
        id: item.id > 0 ? item.id : undefined,
        title: item.title,
        desc: item.html,
        questions: questionList(item?.questions),
      };
      chapters.push(chapter);
    }

    let questions = questionList(data?.questions);
    let params = {
      id: data?.id,
      courseName: data?.courseName,
      aboutTraining: data?.about,
      coverFileId: data?.cover?.fileId,
      outcome: data?.outcome,
      intro: intro,
      chapters: chapters,
      questions: questions,
      frequency: data?.frequency?.value,
      dueIn: data?.dueIn?.value,
      duration: data?.duration?.value,
      isEvaluationRequired: data?.evalutionForm?.value === "Yes" ? true : false,
      isCpd: data?.isCpd?.value === 'Yes' ? true : false,
      isManualCertificate: data?.isManualCertificate?.value === 'Yes' ? true : false,

    };
    setLoading(true);
    saveCourseDetail(params, (data, error) => {
      setLoading(false);
      if (error === null) {
        history.push(`${process.env.PUBLIC_URL}/training-list`);
      } else {
        setError(error.message);
      }
    });
  };
  //*******************End API's************************/
  const [modal, setModal] = useState(false);
  return (
    <React.Fragment>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="align-items-end">
            <BlockContent className=" align-items-center ms-auto">
              <Button
                color="primary"
                className="btn-text custom-solid-btn-sm"
                onClick={() => setModal(true)}
              >
                Add test
              </Button>
            </BlockContent>
          </BlockBetween>
        </BlockHead>
        {error && (data?.questions ?? []).length > 0 && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {error}{" "}
            </Alert>
          </div>
        )}
        <Block>
          {(data?.questions ?? []).length > 0 ? (
            <QuestionListView
              questions={data?.questions}
              onEdit={handleEdit}
              onRemove={handleRemove}
            />
          ) : (
            <div className="text-center">
              <span className="text-silent">
                You have not added any test yet.
              </span>
              {error && (
                <div className="text-center">
                  <span className="text-danger">{error}</span>
                </div>
              )}
            </div>
          )}

          <div className="actions clearfix">
            <ul>
              <li className=" align-items-center ms-auto">
                <Button
                  color="primary"
                  type="button"
                  className="custom-solid-btn-sm"
                  disabled={loading}
                  onClick={() => onSubmitClick()}
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Submit"}
                </Button>
              </li>
              <li>
                <Button color="light" type="button" onClick={props.prev}>
                  Back
                </Button>
              </li>
            </ul>
          </div>
        </Block>
        <AddTest
          visible={modal}
          item={selectedQuestion}
          onClosed={() => {
            setModal(false);
            setSelectedQuestionIndex();
            setSelectedQuestion();
          }}
          onSaved={(item) => {
            let list = [...(data?.questions ?? [])];
            if (selectedQuestion && (selectedQuestionIndex ?? 0) >= 0) {
              list[selectedQuestionIndex] = item;
            } else {
              list.push(item);
            }
            updateQuestions(list);
            setSelectedQuestion();
            setSelectedQuestionIndex();
          }}
        />
      </Content>
    </React.Fragment>
  );
};

export default CourseTest;

export const QuestionListView = ({ questions, onEdit, onRemove }) => {
  return (
    <DataTable className="card-stretch">
      <DataTableBody>
        {(questions ?? []).map((item, index) => {
          return (
            <DataTableItem className="question-type-table" key={index}>
              <DataTableRow size="1">
                <span key={index}>
                  #{index + 1} {item.questionType}
                </span>
              </DataTableRow>
              <DataTableRow size="6">
                <div className="project-info">
                  <ul>
                    <li key={index}></li>
                    <h6 className="mb-2">{item.question}</h6>
                    {item.options.map((option, optionIndex) => (
                      <strong key={optionIndex}>
                        <li className="my-1 text-dark">
                          {" "}
                          {option}
                          {item.answerIndexes.some((e) => e === optionIndex) ? (
                            <Icon className="ms-2" name="check" />
                          ) : null}
                        </li>
                      </strong>
                    ))}
                  </ul>
                </div>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools text-end">
                <ul className="nk-tb-actions gx-1">
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                      >
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#edit"
                              onClick={() => onEdit(item, index)}
                            >
                              <Icon name="edit"></Icon>
                              <span>Edit</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#delete"
                              onClick={() => onRemove(item, index)}
                            >
                              <Icon name="trash"></Icon>
                              <span>Remove</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </DataTableRow>
            </DataTableItem>
          );
        })}
      </DataTableBody>
    </DataTable>
  );
};
