import { Icon } from "../../../components/Component";

export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <div
    {...props}
    className={`slick-arrow-prev slick-arrow ${
      currentSlide === 0 ? "slick-disabled" : ""
    }`}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <a
      href="#prev"
      className={`slick-prev ${currentSlide === 0 ? "disabled" : "enabled"}`}
      onClick={(ev) => ev.preventDefault()}
      style={{ backgroundColor: currentSlide === 0 ? "white" : "#006eff" }}
    >
      <Icon
        name="chevron-left"
        style={{ color: currentSlide === 0 ? "gray" : "white" }}
      ></Icon>
    </a>
  </div>
);

export const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <div
    {...props}
    className={`slick-arrow-next slick-arrow ${
      currentSlide === slideCount - 3 ? "slick-disabled" : ""
    }`}
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 3 ? "slick-disabled" : ""}
    type="button"
  >
    <a
      href="#next"
      className={`slick-next ${
        currentSlide === slideCount - 3 ? "disabled" : "enabled"
      }`}
      onClick={(ev) => ev.preventDefault()}
      style={{
        backgroundColor: currentSlide === slideCount - 3 ? "white" : "#006eff",
      }}
    >
      <Icon
        name="chevron-right"
        style={{ color: currentSlide === slideCount - 3 ? "gray" : "white" }}
      ></Icon>
    </a>
  </div>
);
