import { Storage } from "../storage/storage";
import { APIDataConverter } from "./api-data-converter";
import { APIError, APIErrorCode, APIServiceBase } from "./api-service-base";

export const APIServicePath = {
  login: "auth/sign-in",
  forgotPassword: "auth/reset-password",
  verifyResetPasswordToken: "auth/verify-reset-password-token",
  resendEmail: "auth/resend-email-link",
  generateSession: "auth/login-with-token",
  recoverPassword: "auth/recover-password",
  logout: "user/sign-out",
  addLocation: "location/create",
  editLocation: "location/update",
  deleteLocation: "location/remove",
  checkPassword: "user/check-password",
  changePassword: "user/change-password",
  locationList: "location/list",
  locationStats: "location/statistics-list",
  roleList: "role/list",
  updateRole: "role/update",
  createRole: "role/create",
  removeRole: "role/remove",
  roleFrequency: "role/set-course-frequency",
  courseList: "course/list",
  courseDetail: "course/detail",
  courseStats: "course/statistics-list",
  saveCourse: "course/save",
  courseAssignedList: "course/my-assigned-list",
  courseOtherList: "course/my-not-assigned-list",
  assignedCourseList: "course/course-assigned-list",
  ftfTrainingList: "face-to-face/list-for-staff",
  staffList: "staff/list",
  addStaff: "staff/add-staff",
  editStaff: "staff/edit",
  removeStaff: "staff/remove",
  detailStaff: "staff/detail",
  fileUpload: "common/file-upload",
  dashboard: "user/dashboard",
  faceToFaceSave: "face-to-face/save",
  faceToFaceList: "face-to-face/list",
  faceToFaceMyList: "face-to-face/my-list",
  faceToFaceBooking: "face-to-face/booking",
  faceToFaceCancel: "face-to-face/cancel",
  faceToFaceBookingDetails: "face-to-face/ftf-booking-details",
  faceToFaceModrateBooking: "face-to-face/modrate-booking",
  faceToFaceBookingCancel: "face-to-face/booking-cancel",
  faceToFaceBookingByManager: "face-to-face/booking-by-manager",
  PolicyList: "policy/list",
  policyAdd: "policy/create",
  policyMatrix: "staff/policy-matrix",
  PolicyAssignedList: "policy/my-assigned-list",
  policyRead: "policy/read",
  policyUnread: "policy/unread",
  resourceList: "resource/list",
  policyStatisticsList: "policy/statistics-list",
  resourceCreate: "resource/create",
  resourceRemove: "resource/remove",
  resourceEdit: "resource/edit",
  resourceSave: "resource/save",
  courseMatrix: "staff/course-matrix",
  chapterMarkAsRead: "course/chapter-mark-as-read",
  chapterMarkAsUnread: "course/chapter-mark-as-unread",
  assessmentResultSave: "assessment/result-save",
  courseMarkCompleteByAdmin: "course/mark-as-complete-staff-data",
  courseMarkNotMandatoryByAdmin: "course/staff-course-not-mandatory",
  getMyCertificate: "staff/get-my-certificates",
  staffGetCertificate: "staff/certificates-list",
  staffResendInvite: "staff/resent-invitation",
  staffCourseUpdate: "course/staff-course-update",
  assignCourseToStaff: "staff/assign-course-to-staff",
  downloadAllCertificate: "staff/download-all-certificate",
  courseMatrixEmail: "staff/send-course-matrix-to-email",
  AssesmentResultDetail: "assessment/result-detail",
  sendCourseMatrixToEmail: "staff/send-course-matrix-to-email",
  dashboardGraph: "user/dashboard-graph",
  resetTraining: "course/reset",
  sendUnlockCourseRequest: "course/unlock-request-to-admin",
  evalutionQuestionList: "course/evaluation-question-list",
  evalutionFormAnswer: "course/evaluation-answer",
  evaluationFormDetail: "course/evaluation-detail",
  cpdFormSave: "face-to-face/save-cpd",
  cpdList: "face-to-face/list-cpd",
  removeCPD: "face-to-face/cpd-cert-delete",
  archiveCertificate: "course/staff-archived-certificates",

  // Support_ticket
  managerList: "support-ticket/manager-list",
  ticketSave: "support-ticket/save",
  ticketList: "support-ticket/list",
  ticketModerateStatus: "support-ticket/moderate-status",
  notesave: "support-ticket/note-save",
  noteList: "support-ticket/note-list",
};

export const APIServiceMethod = {
  post: "POST",
  get: "GET",
  delete: "DELETE",
  put: "PUT",
};

export class APIServiceImpl extends APIServiceBase {
  constructor() {
    super();
    this.dataConverter = new APIDataConverter();
  }

  async handleAPIResopnse(response) {
    const json = await response.json();
    if ((response.status === 200 || response.status === 201) && json) {
      return json;
    } else {
      let error = new APIError(
        json?.errorCode ?? APIErrorCode.UnkonwnError,
        json?.message ?? null,
        json?.title ?? null,
        json?.isForceLogout ?? false
      );
      if (json?.isForceLogout === true) {
        Storage.clearAll();
        window.location.reload();
      }
      throw error;
    }
  }

  setUserToken(accessToken) {
    this.accessToken = accessToken;
  }

  handleAPIException(exception) {
    if (exception instanceof APIError) {
      return exception;
    } else {
      if (exception.code === 20 || exception.message === "Aborted") {
        // Abort Api call
        return new APIError(APIErrorCode.Aborted);
      } else {
        return new APIError(APIErrorCode.UnkonwnError);
      }
    }
  }

  handleAuthResponse(authResponse) {
    if (!authResponse.accessToken) {
      throw new APIError(APIErrorCode.UnexpectedAuthResponse);
    }
    this.accessToken = authResponse.accessToken;
  }

  // ************************Auth  API's*******************************//

  async signIn(req) {
    const authResponse = await this.publicAPICall(
      APIServicePath.login,
      APIServiceMethod.post,
      req
    );
    this.handleAuthResponse(authResponse);
    return this.dataConverter.toAuthorization(authResponse);
  }

  async verifyResetPasswordToken(req) {
    return await this.publicAPICall(
      APIServicePath.verifyResetPasswordToken,
      APIServiceMethod.post,
      req
    );
  }
  async resendVerifyEmail(req) {
    return await this.publicAPICall(
      APIServicePath.resendEmail,
      APIServiceMethod.post,
      req
    );
  }

  async generateSession(req) {
    const authResponse = await this.publicAPICall(
      APIServicePath.generateSession,
      APIServiceMethod.post,
      req
    );
    this.handleAuthResponse(authResponse);
    return this.dataConverter.toAuthorization(authResponse);
  }

  async resetPassword(req) {
    return this.publicAPICall(
      APIServicePath.forgotPassword,
      APIServiceMethod.post,
      req
    );
  }

  async recoverPassword(req) {
    return this.publicAPICall(
      APIServicePath.recoverPassword,
      APIServiceMethod.post,
      req
    );
  }

  async logout() {
    return await this.sessionAPICall(
      APIServicePath.logout,
      APIServiceMethod.post,
      {}
    );
  }

  // ************************Common API's*******************************//
  async postCall(path, req, timeout) {
    return await this.sessionAPICall(
      path,
      APIServiceMethod.post,
      req,
      null,
      timeout
    );
  }

  async postCallWithCancelSignal(path, req, token) {
    const controller = new window.AbortController();
    const signal = controller.signal;
    token.cancel = () => {
      controller.abort();
    };
    return await this.sessionAPICall(path, APIServiceMethod.post, req, signal);
  }

  async getCall(path, req) {
    return await this.sessionAPICall(path, APIServiceMethod.get, req);
  }

  async fileUploader(file, type, path) {
    if (file === undefined || file === null) {
      return null;
    }
    let body = new FormData();
    body.append("file", file);
    body.append("type", type);
    body.append("name", file.name);
    body.append("fileUrl", file.fileUrl);

    const result = await this.uploadImageApiCall(
      path,
      APIServiceMethod.post,
      body,
      120000
    );
    return result.data;
  }
}
