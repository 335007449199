import React, { useState, useEffect } from "react";

const EvalutionFormRadioOption = ({
  criteriaList,
  formData,
  handleInputChange,
  disabled,
}) => {
  const [selectedValues, setSelectedValues] = useState(formData);

  // Update selectedValues state when formData changes
  useEffect(() => {
    setSelectedValues(formData);
  }, [formData]);

  const handleRadioChange = (criteriaId, value) => {
    const updatedFormData = { ...selectedValues, [criteriaId]: value };
    setSelectedValues(updatedFormData);
    handleInputChange({ target: { name: criteriaId, value } }); // Call handleInputChange from parent
  };

  return (
    <>
      {criteriaList?.length > 0 && (
        <table className="border-table">
          <thead>
            <tr>
              <th>Criteria</th>
              <th>Strongly agree (4)</th>
              <th>Agree (3)</th>
              <th>Disagree (2)</th>
              <th>Strongly Disagree (1)</th>
            </tr>
          </thead>

          <tbody>
            {criteriaList.map(
              (criteria) =>
                criteria.quesType === "CRITERIA" && (
                  <tr key={criteria.id}>
                    <td>{criteria.quesDesc}</td>
                    {[4, 3, 2, 1].map((value) => (
                      <td key={value}>
                        <input
                          className="cursor-pointer"
                          type="radio"
                          name={criteria.id}
                          value={value}
                          checked={selectedValues[criteria.id] === value}
                          onChange={() => handleRadioChange(criteria.id, value)}
                          disabled={disabled}
                        />
                      </td>
                    ))}
                  </tr>
                )
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default EvalutionFormRadioOption;
