export const StorageKey = {
  authInfo: "com.saunders.auth.info",
  accessToken: "com.saunders.auth.access.token",
  lastSignedInInTime: "com.saunders.last.signed.in.time",
  isStaySignedIn: "com.saunders.last.logged.in.time",
};

const BaseStorage = {
  getItem: (key) => {
    let result = localStorage.getItem(key);
    return JSON.parse(result);
  },
  setItem: (key, value) => {
    const item = JSON.stringify(value);
    return localStorage.setItem(key, item);
  },
  removeItem: (key) => {
    return localStorage.removeItem(key);
  },
  removeAll: () => {
    localStorage.clear();
  },
};

export const Storage = {
  setUserToken: (value) => {
    BaseStorage.setItem(StorageKey.accessToken, value);
  },
  getUserToken: () => {
    let value = BaseStorage.getItem(StorageKey.accessToken);
    return value;
  },
  setLoggedInUser: (value) => {
    BaseStorage.setItem(StorageKey.authInfo, value);
  },
  getLoggedInUser: () => {
    let value = BaseStorage.getItem(StorageKey.authInfo);
    return value;
  },
  setLastSignedInTime: (value) => {
    BaseStorage.setItem(StorageKey.lastSignedInInTime, value);
  },
  getLastSignedInTime: () => {
    return BaseStorage.getItem(StorageKey.lastSignedInInTime);
  },
  setIsStaySignedIn: (value) => {
    BaseStorage.setItem(StorageKey.isStaySignedIn, value);
  },
  getIsStaySignedIn: () => {
    return BaseStorage.getItem(StorageKey.isStaySignedIn);
  },
  clearAll() {
    BaseStorage.removeAll();
  },
};
