import React from "react";
import "../../assets/LoadingComponent.css";
import { Spinner } from "reactstrap";

export const LoadingComponent = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner">
            <Spinner className="spinner-border-md" color="primary" />
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingComponent;
