import React, { useEffect, useState, useRef } from "react";
import SimpleBar from "simplebar-react";
import classNames from "classnames";
import {
  Button,
  Icon,
  PaginationComponent,
  UserAvatar,
} from "../../components/Component";
import {
  UserAccountType,
  bytesToMegaBytes,
  findUpper,
  showErrorToast,
} from "../../utils/Utils";
import {
  noteListApi,
  noteSaveApi,
  ticketListApi,
  ticketModerateStatusApi,
  uploadTicketNoteFile,
} from "./MessageData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ActionButton from "../../components/button/ActionButton";
import moment from "moment";
import LoadingComponent from "../../layout/spinner/LoadingSpinner";
import { iconsType } from "../Resources/ResourceData";
import { Spinner } from "reactstrap";
import ReplyItem from "./ReplyItem";
import { AppText } from "../../utils";
import { Storage } from "../../services/storage/storage";

let token = {};
const MessageItem = ({ managerId, onEdit }) => {
  const [selectedTicket, setSelectedTicket] = useState();
  const [textInput, setTextInput] = useState("");
  const [data, setData] = useState([]);
  const [count, setCount] = useState();
  const [conversationData, setConversationData] = useState([]);
  const [reverItems, setReverItems] = useState([]);
  const [filterTab, setFilterTab] = useState("1");
  const [search, setOnSearch] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [removeFileIds, setRemoveFileIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [ticketListTotalItem, setTicketListTotalItem] = useState(0); // Add state for total number of items
  const [ticketListCurrentPage, setTicketListCurrentPage] = useState(1);
  const [pastedImages, setPastedImages] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const history = useHistory();
  const messagesEndRef = useRef(null);
  let user = Storage.getLoggedInUser();

  useEffect(() => {
    setSelectedTicket();
    loadTicketList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTab, filterText, managerId]);

  useEffect(() => {
    if (selectedTicket?.id) {
      loadConversationList();
    }
  }, [selectedTicket?.id, currentPage]);

  useEffect(() => {
    loadTicketList();
  }, [ticketListCurrentPage]);

  useEffect(() => {
    setReverItems([...conversationData].reverse());
  }, [conversationData]);

  useEffect(() => {
    currentPage === 1 &&
      setTimeout(() => {
        scrollToBottom();
      }, [500]);
    currentPage > 1 && scrollToBottomOnLoadMore();
  }, [conversationData, currentPage]);

  const scrollToBottom = () => {
    const scrollHeight = messagesEndRef.current?.scrollHeight;
    const height = messagesEndRef.current?.clientHeight;
    if (scrollHeight && height) {
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const scrollToBottomOnLoadMore = () => {
    messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  };

  const onInputChange = (e) => {
    setFilterText(e.target.value);
  };
  let status =
    filterTab === "1"
      ? "OPEN"
      : filterTab === "2"
      ? "CLOSED"
      : filterTab === "3"
      ? ""
      : null;

  const loadMoreItems = () => {
    setCurrentPage((prevPage) => prevPage + 1); // Increment the current page
  };

  // *******************************API***********************
  function loadTicketList() {
    let params = {
      pageInfo: {
        pageIndex: ticketListCurrentPage,
        itemsPerPage: 10,
      },
      managerId: managerId ? managerId : undefined,
      search: filterText,
      status: status,
    };
    token.cancel && token.cancel();
    ticketListApi(params, token, (list, count, pageInfo, error) => {
      if (error === null) {
        setData(list);
        setCount(count);
        setTicketListTotalItem(pageInfo?.totalItems);
        setConversationData([]);
        setCurrentPage(1);
        if (list.length > 0) {
          // Set the selectedTicket to the first item in the list
          setSelectedTicket(list[0]);
        } else {
          // If the list is empty, set selectedTicket to 0
          setSelectedTicket(0);
        }
      } else {
        showErrorToast(error.message);
      }
      setIsInitScreen(false);
    });
  }

  function moderateStatus(status) {
    let params = {
      ticketId: selectedTicket?.id,
      status: status,
    };
    ticketModerateStatusApi(params, (data, error) => {
      if (error === null) {
        loadTicketList();
      } else {
        showErrorToast(error.message);
      }
    });
  }

  function noteReply() {
    let fileIds = uploadedFiles?.map((file) => {
      return file?.id;
    });
    let params = {
      ticketId: selectedTicket?.id,
      desc: textInput,
      fileIds: fileIds,
    };
    noteSaveApi(params, (data, error) => {
      if (error === null) {
        setTextInput("");
        setUploadedFiles([]);
        // let newReply = {
        //   id: data?.data?.id,
        //   eventType: data?.data?.eventType,
        //   desc: data?.data?.desc,
        //   createdAt: data?.data?.createdAt,
        //   files: data?.data?.files ?? [],
        //   userName:
        //     data?.data?.userData?.firstName +
        //     " " +
        //     data?.data?.userData?.lastName,
        // };
        // setConversationData([newReply, ...conversationData]);
        loadConversationList();
        if (selectedTicket.status === "CLOSED") {
          loadTicketList();
        }
      } else {
        showErrorToast(error.message);
      }
    });
  }

  function loadConversationList() {
    let params = {
      // pageInfo: {
      //   pageIndex: currentPage,
      //   itemsPerPage: 10,
      // },
      ticketId: selectedTicket?.id,
    };
    noteListApi(params, (list, pageInfo, error) => {
      if (error === null) {
        if (currentPage === 1) {
          // If loading first page, set the conversation data directly
          setConversationData(list);
          let index = data.findIndex((obj) => obj.id === selectedTicket?.id);
          if (index !== -1) {
            // Check if index is not -1 (indicating the item was found)
            let list = [...data];
            let updateItem = { ...list[index] }; // Create a copy of the selected ticket
            updateItem.isUnread = 0;
            list[index] = updateItem;
            setData(list);
          }
        } else {
          // If loading subsequent pages, append the new items to existing data
          setConversationData((prevData) => [...prevData, ...list]);
        }
        // Update total items count
        setTotalPages(pageInfo?.totalPages);
      } else {
        showErrorToast(error.message);
      }
    });
  }

  const uploadFile = (file, index) => {
    uploadTicketNoteFile(file, (data, error) => {
      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      if (error === null) {
        file.id = data.id;
        file.isLoading = false;
        file.fileUrl = data.fileUrl;
        updatedfiles[index] = file;
        setUploadedFiles(updatedfiles);
      } else {
        updatedfiles.splice(index, 1);
        setUploadedFiles(updatedfiles);
        showErrorToast(
          error.message.length > 0 ? error.message : AppText.connectError
        );
      }
    });
  };

  const handleFileSelect = (file, rootIndex) => {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      const fileDetails = file;
      fileDetails.isLoading = true;
      fileDetails.isError = false;
      fileDetails.id = null;
      fileDetails.isOldFile = false;

      const updatedfiles = [...uploadedFiles]; // Create a copy of the files array
      updatedfiles.push(fileDetails);
      setUploadedFiles(updatedfiles);

      // for API
      uploadFile(fileDetails, updatedfiles.length - 1);
    }
  };

  const handleFileRemove = (ind) => {
    const updatedfiles = [...uploadedFiles]; // Create a copy of formData

    removeFileIds.push(updatedfiles[ind].id);

    updatedfiles.splice(ind, 1); // Update the files array within resourceFile

    setUploadedFiles(updatedfiles);
  };

  const onSearchBack = () => {
    setOnSearch(false);
    setFilterText("");
  };

  const onMessageClick = (id) => {
    if (selectedTicket?.id === id) {
      return; // Exit function if already selected
    }
    const selectedItem = data?.find((item) => item?.id === id);
    if (selectedItem) {
      setConversationData([]);
      setCurrentPage(1);
      setSelectedTicket(selectedItem);
    }
    if (window.innerWidth <= 990) {
      setMobileView(true);
    }
  };

  const onTextChange = (e) => {
    e.preventDefault();
    setTextInput(e.target.value);
  };

  const chatBodyClass = classNames({
    "nk-msg-body": true,
    "bg-white": true,
    "show-message": mobileView,
  });

  const openFileInNewTab = (file) => {
    if (file) {
      window.open(file?.fileUrl, "_blank");
    }
  };

  const onEditClick = (e) => {
    // e.preventDefault();
    onEdit(selectedTicket);
  };

  const formatDateTime = (createdAt) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const createdAtDate = moment(createdAt).format("YYYY-MM-DD");
    let displayDateTime;
    if (today === createdAtDate) {
      displayDateTime = moment(createdAt).format("HH:mm");
    } else {
      displayDateTime = moment(createdAt).format("D MMM HH:mm");
    }
    return displayDateTime;
  };

  let ticketDateTime = formatDateTime(selectedTicket?.createdAt);

  const paginate = (pageNumber) => {
    setTicketListCurrentPage(pageNumber);
  };

  const handlePaste = (e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for (let index in items) {
      const item = items[index];
      if (item.kind === "file" && item.type.includes("image")) {
        const blob = item.getAsFile();
        const file = new File([blob], `${Date.now()}.png`, { type: blob.type });
        handleFileSelect(file, uploadedFiles.length); // Call handleFileSelect with the pasted image file
        break; // Stop after processing the first image
      }
    }
  };

  const handleClose = () => {
    setPastedImages([]);
  };

  return (
    <React.Fragment>
      <div className="nk-msg-aside hide-aside">
        <div className="nk-msg-nav">
          <ul className="nk-msg-menu">
            <li
              className={`nk-msg-menu-item ${filterTab === "1" && " active"}`}
              onClick={() => setFilterTab("1")}
            >
              <a
                href="#active"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Open ({count?.open})
              </a>
            </li>
            <li
              className={`nk-msg-menu-item ${filterTab === "2" && " active"}`}
              onClick={() => setFilterTab("2")}
            >
              <a
                href="#closed"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                Closed ({count?.closed})
              </a>
            </li>
            <li
              className={`nk-msg-menu-item ${filterTab === "3" && " active"}`}
              onClick={() => setFilterTab("3")}
            >
              <a
                href="#all"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                All ({count?.total})
              </a>
            </li>
            <li
              className="nk-msg-menu-item ms-auto"
              onClick={() => setOnSearch(true)}
            >
              <a
                href="#search"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                className="search-toggle toggle-search"
              >
                <Icon name="search"></Icon>
              </a>
            </li>
          </ul>
          <div className={`search-wrap ${search && " active"}`}>
            <div className="search-content">
              <a
                href="#search"
                className="search-back btn btn-icon toggle-search"
                onClick={(ev) => {
                  ev.preventDefault();
                  onSearchBack();
                }}
              >
                <Icon name="arrow-left"></Icon>
              </a>
              <input
                type="text"
                className="border-transparent form-focus-none form-control"
                placeholder="Search by user or message"
                onChange={(e) => onInputChange(e)}
              />
              <Button className="search-submit btn-icon">
                <Icon name="search"></Icon>
              </Button>
            </div>
          </div>
        </div>

        <SimpleBar className="nk-msg-list">
          {data?.map((item) => {
            let displayDateTime = formatDateTime(item?.lastUpdatedDate);
            return (
              <div
                className={`nk-msg-item ${
                  selectedTicket?.id === item.id ? "current" : ""
                }`}
                key={item.id}
                onClick={() => onMessageClick(item.id)}
              >
                <UserAvatar
                  className="nk-nk-msg-media"
                  theme={item.theme}
                  image={item.image}
                  text={findUpper(item?.managerName ?? "")}
                ></UserAvatar>

                <div className="nk-msg-info">
                  <div className="nk-msg-from">
                    <div className="nk-msg-sender">
                      <div className="name">{item.managerName}</div>
                      {item?.status !== "CLOSED" && item?.isUnread > 0 && (
                        <div className={`lable-tag dot bg-primary`}></div>
                      )}
                    </div>
                    <div className="nk-msg-meta">
                      <div className="attchment">
                        {item.attactchment && <Icon name="clip-h"></Icon>}
                      </div>
                      <div className="date">{displayDateTime}</div>
                    </div>
                  </div>
                  <div className="nk-msg-context">
                    <div className="nk-msg-text">
                      <h6 className="title">{item.title}</h6>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </SimpleBar>
        {data?.length > 0 && (
          <div className="my-2 d-flex justify-content-center">
            <PaginationComponent
              itemPerPage={10}
              totalItems={ticketListTotalItem}
              paginate={paginate}
              currentPage={ticketListCurrentPage}
            />
          </div>
        )}
      </div>
      {data?.length > 0 ? (
        <div className={chatBodyClass}>
          <div className="nk-msg-head d-flex justify-content-between align-center">
            <h4 className="title d-none d-lg-block m-0">
              {selectedTicket?.title}
            </h4>
            <ul className="nk-msg-actions">
              {selectedTicket?.status === "CLOSED" ? (
                <li>
                  <Button
                    outline
                    size="sm"
                    color="light"
                    className="btn-dim"
                    onClick={() => moderateStatus("OPEN")}
                  >
                    <Icon name="check"></Icon>
                    <span>Mark as Open</span>
                  </Button>
                </li>
              ) : (
                <li>
                  <Button
                    outline
                    size="sm"
                    color="light"
                    className="btn-dim"
                    onClick={() => moderateStatus("CLOSED")}
                  >
                    <Icon name="check"></Icon>
                    <span>Mark as Closed</span>
                  </Button>
                </li>
              )}
              {user.accountType === UserAccountType.manager && (
                <li>
                  <Button
                    outline
                    size="sm"
                    color="light"
                    className="btn-dim"
                    onClick={() => onEditClick()}
                  >
                    <Icon name="edit"></Icon>
                  </Button>
                </li>
              )}
              <li className="d-lg-none">
                <Button
                  outline
                  size="sm"
                  color="light"
                  className="btn-dim"
                  onClick={() => window.location.reload()}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
              </li>
            </ul>
          </div>
          {/* nk-msg-head */}
          <SimpleBar
            className="nk-msg-reply nk-reply"
            scrollableNodeProps={{ ref: messagesEndRef }}
          >
            {/* <div className="nk-msg-head py-4 d-lg-none">
              <h4 className="title">{selectedTicket?.title}</h4>
              <ul className="nk-msg-tags">
                <li>
                  <span className="label-tag">
                    <Icon name="flag-fill"></Icon>{" "}
                    <span>Technical Problem</span>
                  </span>
                </li>
              </ul>
            </div> */}
            <div className="nk-reply-item">
              <div className="nk-reply-header">
                <div className="user-card">
                  <UserAvatar
                    size="sm"
                    theme={selectedTicket?.theme}
                    // image={item.image}
                    text={findUpper(selectedTicket?.managerName ?? "")}
                  />
                  <div className="user-name">{selectedTicket?.managerName}</div>
                </div>
                <div className="date-time">{ticketDateTime}</div>
              </div>
              <div className="nk-reply-body">
                <div className="nk-reply-entry entry">
                  {selectedTicket?.desc}
                </div>
                {selectedTicket?.files && selectedTicket?.files?.length > 0 ? (
                  <div className="nk-upload-files-filelist">
                    {selectedTicket?.files.map((file, index) => (
                      <div className="nk-upload-item-filelist" key={index}>
                        <div className="nk-upload-icon">
                          {file?.mimeType ? (
                            <>
                              {iconsType[file?.mimeType]
                                ? iconsType[file?.mimeType]
                                : iconsType["others"]}
                            </>
                          ) : (
                            <>
                              {iconsType[file?.type]
                                ? iconsType[file?.type]
                                : iconsType["others"]}
                            </>
                          )}
                        </div>
                        <div className="nk-upload-info">
                          <div className="nk-upload-title">
                            <span
                              className="title"
                              style={{ cursor: "pointer" }}
                              onClick={() => openFileInNewTab(file)}
                            >
                              {file?.name
                                ? file?.name
                                : file?.fileName
                                ? file?.fileName
                                : "Ticket"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            {/* {currentPage < totalPages && (
              <div className="text-center mt-3">
                <Button color={"primary"} onClick={() => loadMoreItems()}>
                  Load More
                </Button>
              </div>
            )} */}
            {reverItems?.map((replyItem, index) => {
              let displayDateTime = formatDateTime(replyItem?.createdAt);
              let previousEventType = reverItems[index - 1]?.eventType;

              return (
                <>
                  {replyItem?.eventType === "REPLY" &&
                    previousEventType !== "STATUS_UPDATE" && (
                      <div className="reply-seprater-line"></div>
                    )}
                  {replyItem?.eventType === "REPLY" ? (
                    <>
                      <ReplyItem
                        item={replyItem}
                        key={replyItem.id}
                      ></ReplyItem>
                    </>
                  ) : replyItem?.eventType === "STATUS_UPDATE" ? (
                    <>
                      <div className="nk-reply-meta my-2">
                        <div className="nk-reply-meta-info">
                          <strong className="text-center">
                            {`Status updated from "${
                              JSON.parse(replyItem?.desc).old
                            }" to "${JSON.parse(replyItem?.desc).new}" by ${
                              replyItem?.userName
                            } at ${displayDateTime}`}
                          </strong>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </SimpleBar>
          <div className="align-bottom">
            <div className="nk-reply-form">
              <div className="">
                <div>
                  <div className="nk-reply-form-editor">
                    <div className="nk-reply-form-field">
                      <div>
                        {/* <div style={{ position: "relative" }}>
                          {pastedImages.map((imgUrl, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              <img
                                src={imgUrl}
                                alt="Pasted Image"
                                style={{
                                  maxWidth: "300px",
                                  maxHeight: "300px",
                                  margin: "5px",
                                }}
                              />
                              <Icon
                                name="cross"
                                onClick={handleClose}
                                style={{
                                  position: "absolute",
                                  right: "-10px",
                                  zIndex: "2",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          ))}
                        </div> */}
                      </div>
                      <textarea
                        className="form-control form-control-simple no-resize"
                        placeholder="Type your message here"
                        value={textInput}
                        onChange={(e) => onTextChange(e)}
                        onPaste={(e) => handlePaste(e)}
                      />
                    </div>
                    <div>
                      {uploadedFiles?.length > 0 && (
                        <div className="nk-upload-files-replyfilelist m-2">
                          {uploadedFiles?.map((file, ind) => (
                            <div
                              key={`file-${ind}`}
                              className="nk-upload-item-replyfilelist"
                            >
                              <div className="nk-upload-icon">
                                {file?.mimeType ? (
                                  <>
                                    {iconsType[file?.mimeType]
                                      ? iconsType[file?.mimeType]
                                      : iconsType["others"]}
                                  </>
                                ) : (
                                  <>
                                    {iconsType[file?.type]
                                      ? iconsType[file?.type]
                                      : iconsType["others"]}
                                  </>
                                )}
                              </div>
                              <div className="nk-upload-info">
                                <div className="nk-upload-title">
                                  <span
                                    className="title"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => openFileInNewTab(file)}
                                  >
                                    {file?.name ? file?.name : "Ticket"}
                                  </span>
                                </div>
                              </div>
                              <div className="nk-upload-action">
                                <a
                                  href="#delete"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                  className="btn btn-icon btn-trigger round-40  d-flex justify-content-center align-items-center"
                                >
                                  {file?.isLoading ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <span onClick={() => handleFileRemove(ind)}>
                                      <Icon name="trash"></Icon>
                                    </span>
                                  )}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="nk-reply-form-tools">
                      <ul className="nk-reply-form-actions g-1">
                        <li className="me-2">
                          <ActionButton
                            title={"Reply"}
                            color="primary"
                            type="submit"
                            disabled={
                              !textInput || textInput.trim().length === 0
                            }
                            onClick={() => noteReply()}
                          />
                        </li>
                        <li>
                          <div className="form-group mb-0">
                            <label htmlFor="files">
                              <Icon
                                name="clip-v"
                                className="mb-1 text-primary fs-5"
                              />
                            </label>
                            <input
                              type="file"
                              name="files"
                              accept=".pdf,.doc,.docx,.xlsx,.xls,.png,.jpg,.jpeg,.svg,.webp"
                              className="form-control"
                              id={"files"}
                              style={{ display: "none" }}
                              onChange={(e) => {
                                let temp = Array.prototype.slice.call(
                                  e.target.files
                                );
                                if (temp.length > 0) {
                                  handleFileSelect(temp[0]);
                                }
                              }}
                            />
                          </div>
                          {/* <input
                            type="file"
                            name="files"
                            accept=".pdf,.doc,.docx,.xlsx,.xls,.png,.jpg,.jpeg,.svg,.webp"
                            className="form-control"
                            id={"files"}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              let temp = Array.prototype.slice.call(
                                e.target.files
                              );
                              if (temp.length > 0) {
                                handleFileSelect(temp[0]);
                              }
                            }}
                          /> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default MessageItem;
