import React, { useEffect, useState } from "react";
import Simplebar from "simplebar-react";
import {
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
} from "../../components/Component";
import { UserAccountType } from "../../utils/Utils";
import Content from "../../layout/content/Content";
import { Storage } from "../../services/storage/storage";
import { ManagerDropDown } from "./ManagerDropDown";
import MessageItem from "./MessageItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Head from "../../layout/head/Head";

const Support = () => {
  const [selectedId, setSelectedId] = useState(1);
  const [manager, setManager] = useState();
  const history = useHistory();

  const onAddClick = () => {
    history.push(`${process.env.PUBLIC_URL}/add-ticket`);
  };

  const onEdit = (item) => {
    const itemData = {
      id: item.id,
      title: item.title,
      desc: item.plainDescription,
      files: item.files,
    };
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-ticket`,
      state: { item: itemData },
    });
  };

  let user = Storage.getLoggedInUser();

  return (
    <React.Fragment>
      <Head title="Support" />
      <Content>
        <BlockHead size="sm">
          <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
            <BlockHeadContent className="mb-2 mb-md-0">
              <BlockTitle tag="h3" page>
                Support
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              {user.accountType === UserAccountType.manager && (
                <Button
                  color="primary"
                  className="d-sm-inline-flex custom-solid-btn-sm"
                  onClick={onAddClick}
                >
                  Create Ticket
                </Button>
              )}
              {user.accountType === UserAccountType.admin && (
                <div className="w-250px">
                  <ManagerDropDown
                    value={manager}
                    className="w-250px"
                    onSelect={(e) => {
                      setManager(e);
                    }}
                  />
                </div>
              )}
            </BlockHeadContent>
          </div>
        </BlockHead>
        <div className="nk-msg border">
          <MessageItem managerId={manager?.id} onEdit={onEdit} />
        </div>
      </Content>
    </React.Fragment>
  );
};
export default Support;
