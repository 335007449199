import React from "react";
import Matrix from "./Matrix";
import { useState, useEffect } from "react";
import { fetchSendEmail, getCourseyMatrix } from "./MatrixData";
import { getCourseList } from "../dashboard/Roles/RolesData";
import moment from "moment/moment";
import Content from "../../layout/content/Content";
import {
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Component";
import { Button, Spinner } from "reactstrap";
import CourseFilter from "./CourseFilter";
import {
  AppText,
  dateToShowFormate,
  dueDateColor,
  removeTimeFromServerDateString,
  showErrorToast,
  showSuccessToast,
} from "../../utils";
import Head from "../../layout/head/Head";

var token = {};
const CourseMatrix = () => {
  const [courseData, setCourseData] = useState();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilterCourse, setSelectedFilterCourse] = useState();
  const [userdata, setUserData] = useState();
  const [matrixPageInfo, setMatrixPageInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [modal, setModal] = useState({
    edit: false,
    add: false,
    location: false,
  });
  const [coursefilterData, setCoursefilterData] = useState({
    loaction: [],
    status: [],
    course: [],
  });

  useEffect(() => {
    loadCorseMatrix();
    loadCourses();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitScreen) {
      return;
    }

    let selectedCourse = [];
    courseData?.forEach((item) => {
      coursefilterData?.course !== null &&
        coursefilterData?.course.forEach((fCourse) => {
          item.id === fCourse.id && selectedCourse.push(fCourse);
        });
    });
    let prefix = [
      {
        id: -1,
        title: "Name",
      },
      {
        id: 0,
        title: "Percentage",
      },
    ];
    setSelectedFilterCourse([...prefix, ...selectedCourse]);
    token?.cancel && token?.cancel();

    if (currentPage === 1) {
      loadCorseMatrix();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursefilterData, searchText]);

  useEffect(() => {
    if (currentPage !== 0) {
      loadCorseMatrix();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const scrollableDiv = document.getElementById("style-3");
    scrollableDiv.addEventListener("scroll", handleScroll, true);
    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isLoading]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } =
      document.getElementById("style-3");

    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
      if (currentPage !== matrixPageInfo.totalPages) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  function loadCourses() {
    getCourseList(true, (data, error) => {
      if (error === null) {
        let prefix = [
          {
            id: -1,
            title: "Name",
          },
          {
            id: 0,
            title: "Percentage",
          },
        ];
        setCourseData([...prefix, ...data]);
      }
    });
  }

  function sendCourseEmail() {
    const params = {};
    if (coursefilterData?.location?.length !== 0) {
      params.locationIds = coursefilterData?.location?.map((item) => item.id);
    }
    if (coursefilterData?.course?.length !== 0) {
      params.courseIds = coursefilterData?.course?.map((item) => item.id);
    } else {
      params.courseIds = [];
    }
    if (coursefilterData?.status?.length !== 0) {
      params.status = coursefilterData?.status?.map((item) => item.label);
    }
    if (searchText !== "") {
      params.search = searchText;
    }
    setIsSendingEmail(true);
    fetchSendEmail(params, (data, error) => {
      setIsSendingEmail(false);
      if (error === null) {
        showSuccessToast("Email has been sent successfully");
      } else {
        showErrorToast(
          error.message?.length > 0 ? error.message : AppText.connectError
        );
      }
    });
  }

  function loadCorseMatrix() {
    const params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: 50,
      },
      search: searchText,
    };
    if (coursefilterData?.location?.length !== 0) {
      params.locationIds = coursefilterData?.location?.map((item) => item.id);
    }
    if (coursefilterData?.course?.length !== 0) {
      params.courseIds = coursefilterData?.course?.map((item) => item.id);
    }
    if (coursefilterData?.status?.length !== 0) {
      params.status = coursefilterData?.status?.map((item) => item.label);
    }
    setIsLoading(true);
    getCourseyMatrix(params, token, (data, error) => {
      if (error === null) {
        setMatrixPageInfo(data.pageinfo);
        if (currentPage === 1) {
          setUserData(data.userdata);
        } else {
          let list = [...userdata];
          list.push(...data.userdata);
          setUserData(list);
        }
      }
      setIsInitScreen(false);
      setIsLoading(false);
    });
  }

  return (
    <Content>
      <Head title="Matrix" />
      <BlockHead size="sm">
        <div className="mx-auto mb-3 d-flex flex-column flex-md-row justify-content-md-between">
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Course Matrix
            </BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className="d-flex align-items-center mt-3 mt-md-0">
            <div className="form-wrap me-2">
              <input
                className="form-control "
                type="text"
                name="search"
                value={searchText}
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <button
              className="btn-text btn btn-primary custom-solid-btn-sm me-2 position-relative"
              onClick={() => setModal({ location: true })}
            >
              <span>Filter </span>
              <em className="ni ni-opt ms-1"></em>
              {(coursefilterData?.course?.length > 0 ||
                coursefilterData?.location?.length > 0 ||
                coursefilterData?.status?.length > 0) && (
                <span className="active-dot"></span>
              )}
            </button>
            <Button
              color="primary"
              className="btn-text custom-solid-btn-sm"
              onClick={sendCourseEmail}
              disabled={isSendingEmail}
            >
              {isSendingEmail ? (
                <Spinner size="sm" color="light" />
              ) : (
                "Send this matrix to my email"
              )}
            </Button>
          </BlockHeadContent>
        </div>
      </BlockHead>
      <Matrix
        isLoading={isLoading}
        hList={
          selectedFilterCourse?.length > 2 ? selectedFilterCourse : courseData
        }
        vList={userdata}
        matchItem={(hItem, vItem) => {
          let items = vItem.courses.filter((item) => item.id === hItem.id);
          if (items.length === 1) {
            return items[0];
          }
          return null;
        }}
        showStatus={(item) => {
          if (item === null || item === undefined) {
            return "";
          } else {
            let dueStartDate = item?.dueInStartAt
              ? moment(
                  removeTimeFromServerDateString(item.dueInStartAt),
                  "YYYY-MM-DD"
                ).toDate()
              : null;
            let dueDate = item?.maxDueAt
              ? moment(
                  removeTimeFromServerDateString(item.maxDueAt),
                  "YYYY-MM-DD"
                ).toDate()
              : null;
            let currentDate = moment(
              moment().format("YYYY-MM-DD"),
              "YYYY-MM-DD"
            ).toDate();
            let showdata = {
              text: dateToShowFormate(item?.maxDueAt),
              theme:
                item.completedAt === null &&
                dueStartDate &&
                dueDate &&
                dueStartDate <= currentDate &&
                currentDate <= dueDate
                  ? "secondary"
                  : dueDateColor(item?.dueInStartAt, item?.maxDueAt),
              sweetAlert: true,
            };
            return item.maxDueAt
              ? showdata
              : item.completedAt
              ? {
                  text: "One-off",
                  theme: "success",
                }
              : {};
          }
        }}
      />
      <CourseFilter
        visible={modal.location}
        selectedLocations={coursefilterData?.location}
        selectedStatus={coursefilterData?.status}
        selectedCourse={coursefilterData?.course}
        // item={editItem}
        onChanged={(filterData) => {
          setCoursefilterData({
            location: filterData?.loaction,
            status: filterData?.status,
            course: filterData?.course,
          });
        }}
        onClosed={() => {
          setModal({ location: false });
        }}
      />
    </Content>
  );
};

export default CourseMatrix;
