import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-service-impl";

export const fetchCourseList = (search, locationIds, callback) => {
  let params = {
    search: search,
    locationIds: locationIds,
  };

  apiService
    .postCall(APIServicePath.courseStats, params)
    .then((data) => {
      let list = ServerListToLocalCourseListItem(data.list);
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const fetchPolicyList = (pageInfo, callback) => {
  apiService
    .postCall(APIServicePath.PolicyList, pageInfo)
    .then((data) => {
      let prefix = [
        {
          id: -1,
          title: "Name",
        },
        {
          id: 0,
          title: "Percentage",
        },
      ];
      let policyData = [];

      data?.list.map((item, index) => {
        let data = {
          id: index + 1,
          title: item.name,
        };
        return policyData.push(data);
      });
      callback([...prefix, ...policyData], null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const getPolicyMatrix = (pageInfo, callback) => {
  apiService
    .postCall(APIServicePath.policyMatrix, pageInfo)
    .then((data) => {
      let userData = [];
      data?.list?.map((userItem, index) => {
        let user = {
          firstName: userItem.firstName,
          userid: userItem.id,
          lastName: userItem.lastName,
          policies: userItem?.policy,
        };
        return userData.push(user);
      });
      callback({ userdata: userData, pageinfo: data.pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const getPolicyAssignList = (pageInfo, callback) => {
  apiService
    .postCall(APIServicePath.PolicyAssignedList, pageInfo)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const fetchAddPolicy = (params, callback) => {
  apiService
    .postCall(APIServicePath.policyAdd, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
const ServerListToLocalCourseListItem = (data) => {
  let list = [];
  for (let index = 0; index < data?.length ?? 0; index++) {
    const element = data[index];
    list.push(ServerToLocalCourseItem(element));
  }
  return list;
};

const ServerToLocalCourseItem = (data) => {
  let item = {
    id: data.id,
    name: data.name,
    average: data.avgCompliance ?? 0,
    total: data.totalCompliance ?? 0,
    totalStaff: data.totalStaff ?? 0,
    complaintStaff: data.totalComplianceStaff ?? 0,
  };

  return item;
};

export const readPolicy = (params, callback) => {
  apiService
    .postCall(APIServicePath.policyRead, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const policyUnread = (params, callback) => {
  apiService
    .postCall(APIServicePath.policyUnread, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const uploadPolicyDoc = (file, callback) => {
  apiService
    .fileUploader(file, "POLICY", APIServicePath.fileUpload)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const PolicyListData = (pageInfo, callback) => {
  apiService
    .postCall(APIServicePath.PolicyList, pageInfo)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const fetchPolicyStatisticsList = (params, callback) => {
  apiService
    .postCall(APIServicePath.policyStatisticsList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
