import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Button, Col } from "../../components/Component";
import { faceToFaceBookingByManagerApi } from "./FaceToFaceData";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { fetchStaff } from "../Stafflist/StaffData";

const BookForStaff = ({
  visible,
  dataInfo,
  isAddTraining = false,
  onClosed,
  onSuccess,
}) => {
  const [searchString, setSearchString] = useState("");
  const { handleSubmit } = useForm();
  const [staffList, setStaffList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  var token = {};
  let locationId =
    (dataInfo?.userData?.userType === "MANAGER" &&
      dataInfo?.isGlobal === false) ||
    (dataInfo?.userData?.userType === "ADMIN" && dataInfo?.isGlobal === false)
      ? [dataInfo?.location?.id]
      : dataInfo?.userData?.userType === "MANAGER" &&
        dataInfo?.isGlobal === true
      ? dataInfo?.userData?.locations?.map((item) => item?.id)
      : [];

  useEffect(() => {
    loadStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  useEffect(() => {
    setSearchString("");
  }, [visible]);

  const removeUserRole = (item, index) => {
    setFormData(formData?.filter((data) => data.id !== item.id));
  };

  const handleOnSearch = (string, results) => {
    setSearchString(string);
  };

  const handleOnSelect = (selectedItem) => {
    setFormData((prev) => {
      if (!formData?.some((item) => item?.id === selectedItem?.id)) {
        return [...prev, selectedItem];
      } else {
        return [...prev];
      }
    });
    setStaffList("");
    setSearchString("");
  };

  const loadStaff = () => {
    token?.cancel && token?.cancel();
    fetchStaff(
      1,
      10,
      searchString,
      "ACTIVE",
      locationId,
      null,
      null,
      token,
      (data, error) => {
        if (error === null) {
          setStaffList(data?.list);
        }
      }
    );
  };

  const handleFormSubmit = (formData) => {
    callFaceToFaceBookingByManagerApi();
  };

  const callFaceToFaceBookingByManagerApi = () => {
    setIsLoading(true);
    let params = {
      ftfId: dataInfo?.id ? dataInfo?.id : null,
      staffIds: formData?.map((item) => item?.id),
    };
    faceToFaceBookingByManagerApi(params, (data, error) => {
      if (error === null) {
        setIsLoading(false);
        onClosed();
        onSuccess();
        setFormData([]);
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <Modal
      isOpen={visible}
      toggle={() => onClosed()}
      className="modal-md add-face-event-modal"
    >
      <ModalHeader className="bg-purple text-white" toggle={onClosed}>
        <p className="title">FTF Book for staff</p>
      </ModalHeader>
      <ModalBody>
        <Form className="row gy-4" onSubmit={handleSubmit(handleFormSubmit)}>
          <Col className={"h-min-150px"} size="12">
            <div className="form-group">
              <label className="form-label">Search staff</label>

              <ReactSearchAutocomplete
                showIcon={false}
                items={staffList}
                styling={{
                  height: "36px",
                  border: "1px solid #dbdfea",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "white 0px 1px 6px 0px",
                  hoverBackgroundColor: "white",

                  color: "rgb(60, 77, 98)",
                  fontSize: "13px",
                  fontFamily: "Poppins, sans-serif",
                  iconColor: "grey",
                  lineColor: "rgb(232, 234, 237)",
                  placeholderColor: "grey",
                  clearIconMargin: "3px 14px 0 0",
                  searchIconMargin: "0 0 0 16px",
                  zIndex: 100,
                }}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                inputSearchString={searchString}
                autoFocus
              />
            </div>
            <div className="d-flex align-items-center flex-wrap">
              {formData?.length > 0 &&
                formData.map((item, index) => (
                  <div key={"staff" + index}>
                    <span className="badge badge-dim rounded-pill bg-primary me-2 mb-2">
                      <span className="mr-2">{item?.name}</span>
                      <span
                        className="icon ni ni-cross-circle-fill cursor-pointer"
                        onClick={() => removeUserRole(item, index)}
                      ></span>
                    </span>
                  </div>
                ))}
            </div>
          </Col>
          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-2 gy-2">
              <li>
                <Button
                  color="primary"
                  type="submit"
                  className="custom-solid-btn-sm"
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner size="sm" color="dark" /> : "Book"}
                </Button>
              </li>
              <li>
                <Button
                  color="lighter text-gray"
                  type="buttom"
                  // disabled={isLoading}
                  onClick={() => {
                    setIsLoading(false);
                    onClosed();
                  }}
                >
                  Cancel
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default BookForStaff;
