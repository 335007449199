import React from "react";
import classNames from "classnames";

export const ErrorText = ({ className, ...props }) => {
  var classes = classNames({
    [`${className}`]: className,
  });
  classes = classes + " mt-1 text-danger fs-11px ff-italic";
  return (
    <React.Fragment>
      <p className={classes}>{props.children ?? "This field is required"}</p>
    </React.Fragment>
  );
};
