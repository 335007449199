export const AppText = {
  copyright: `© ${new Date().getFullYear()} ${
    process.env.REACT_APP_APP_NAME
  }. All rights reserved.`,
  invalidEmail: "Invalid email address",
  resetYourPassword: "Reset your password",
  newpassword: "New password",
  connectError: "Please check your network connect and try again",
  maxFileSizeError: "You can upload max 100 MB file",
  uploadFileTypeError: "File type is not correct. Please try again.",
};
