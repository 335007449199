import { RSelect } from "../../../components/Component";
import React, { useEffect, useState } from "react";
import { rolesDropDown } from "./RolesData";

export const RoleDropDown = ({ value, placeholder, isDisabled = false, onSelect }) => {
  const [list, setList] = useState();

  useEffect(() => {
    roles();
  }, []);

  const roles = () => {
    rolesDropDown((data, error) => {
      if (error === null) {
        setList(data);
      }
    });
  };

  return (
    <RSelect
      options={list ?? []}
      placeholder={placeholder}
      value={value}
      isDisabled={isDisabled}
      onChange={(e) => onSelect(e)}
    />
  );
};
