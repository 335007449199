import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404, UserAccountType } from "../utils/Utils";

import Faq from "../pages/others/Faq";
import Dashboard from "../pages/dashboard/Dashboard";
import Stafflist from "../pages/Stafflist/Stafflist";
import Facetoface from "../pages/Facetoface/Facetoface";
import Policies from "../pages/Policies/Policies";
import Resources from "../pages/Resources/Resources";
import Roleslist from "../pages/dashboard/Roles/Roleslist";
import LocationList from "../pages/dashboard/Location/Location";
import TrainingList from "../pages/dashboard/Training/TrainingList";
import CertificateList from "../pages/dashboard/Certificate/CertificateList";
import StaffTraining from "../pages/Stafflist/Stafftraining";
import RoleWizard from "../pages/dashboard/Roles/RoleWizard";
import FaceToFaceDetail from "../pages/Facetoface/DateEvent";
import CourseWizard from "../pages/dashboard/Training/WizardForms/CourseWizard";
import AddPolicy from "../pages/Policies/AddPolicy";
import TrainingWizard from "../pages/Stafflist/TrainingWizard";
import CourseMatrix from "../pages/matrix/CourseMatrix";
import { AddStaff } from "../pages/Stafflist/AddStaff";
import ClientDashboard from "../pages/dashboard/Client-Training/ClientDashboard";
import ViewAllCourse from "../pages/dashboard/Client-Training/ViewAllCourse";
import { Storage } from "../services/storage/storage";
import LearnerFaceToFace from "../pages/Facetoface/LearnerFaceToFace";
import LearnerPolicies from "../pages/Policies/LearnerPolicies";
import PolicyView from "../pages/Policies/PolicyView";
import StartCourse from "../pages/dashboard/Client-Training/StartCourse";
import VerifyPassword from "../pages/auth/CheckPassword";
import CreatePassword from "../pages/auth/CreatePassword";
import StartCourseDetails from "../pages/dashboard/Client-Training/StartCourseDetails";
import AssesmentTest from "../pages/dashboard/Client-Training/AssesmentTest";
import TrainingCertificate from "../pages/dashboard/Client-Training/TrainingCertificate";
import PolicyList from "../pages/Policies/PolicyList";
import GeneratedCertificate from "../pages/dashboard/Certificate/GeneratedCertificate";
import Result from "../pages/dashboard/Client-Training/Result";
import ResourcesFolder from "../pages/Resources/ResourcesFolder";
import NewAddResources from "../pages/Resources/NewAddResources";
import Privacy from "../pages/others/Privacy";
import Terms from "../pages/others/Terms";
import Support from "../pages/support/Support";
import AddTicket from "../pages/support/AddTicket";
import EvalutionForm from "../pages/dashboard/Client-Training/EvalutionForm";
import CPD from "../pages/dashboard/Client-Training/CPD";
import ArchiveCertificate from "../pages/dashboard/Certificate/ArchiveCertificate";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  let user = Storage.getLoggedInUser();

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {user.accountType === UserAccountType.staff ? (
          <>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={ClientDashboard}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/resources`}
              component={Resources}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/cpd-list`}
              component={CPD}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/resource-folders`}
              component={ResourcesFolder}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/learner-face-to-face`}
              component={LearnerFaceToFace}
            ></Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/learner-policies`}
              component={LearnerPolicies}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/policy-view`}
              component={PolicyView}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/view-all-course`}
              component={ViewAllCourse}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/start-course`}
              component={StartCourse}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/check-password`}
              component={VerifyPassword}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/change-password`}
              component={CreatePassword}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/start-course-details`}
              component={StartCourseDetails}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/course-assesment-test`}
              component={AssesmentTest}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/training-certificate`}
              component={TrainingCertificate}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/user-certificate`}
              component={GeneratedCertificate}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/user-archive-certificate`}
              component={ArchiveCertificate}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/training-assesment-result`}
              component={Result}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/face-to-face-detail`}
              component={FaceToFaceDetail}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/evalution-form`}
              component={EvalutionForm}
            ></Route>
          </>
        ) : (
          <>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Dashboard}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/policy-list`}
              component={PolicyList}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/staff`}
              component={Stafflist}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/staff-training`}
              component={StaffTraining}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/manage-face-to-face`}
              component={Facetoface}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/face-to-face-detail`}
              component={FaceToFaceDetail}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/policies`}
              component={Policies}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/add-policy`}
              component={AddPolicy}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/resources`}
              component={Resources}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/resource-folders`}
              component={ResourcesFolder}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/roles-list`}
              component={Roleslist}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/role`}
              component={RoleWizard}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/add-staff`}
              component={AddStaff}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/matrix`}
              component={CourseMatrix}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/training-list`}
              component={TrainingList}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/course-wizard`}
              component={CourseWizard}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/training-wizard`}
              component={TrainingWizard}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/certificate-list`}
              component={CertificateList}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/location`}
              component={LocationList}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/client-dashboard`}
              component={ClientDashboard}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/check-password`}
              component={VerifyPassword}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/training-certificate`}
              component={TrainingCertificate}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/policy-view`}
              component={PolicyView}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/user-certificate`}
              component={GeneratedCertificate}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/user-archive-certificate`}
              component={ArchiveCertificate}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/change-password`}
              component={CreatePassword}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/training-assesment-result`}
              component={Result}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/add-resources`}
              component={NewAddResources}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/support`}
              component={Support}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/add-ticket`}
              component={AddTicket}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/evalution-form`}
              component={EvalutionForm}
            ></Route>
          </>
        )}
        {/*Dashboards*/}

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/help`}
          component={Faq}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/privacy`}
          component={Privacy}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/terms-condition`}
          component={Terms}
        ></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
