import React from "react";
import PrivateRoute from "./route/PrivateRoute";
import Layout from "./layout/Index";

import Error404Classic from "./pages/error/404-classic";
import Error404Modern from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";

import Faq from "./pages/others/Faq";
import Terms from "./pages/others/Terms";
import Privacy from "./pages/others/Privacy";

import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import CreatePassword from "./pages/auth/CreatePassword";
import Success from "./pages/auth/Success";

import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import {
  AdminPaths,
  AuthPaths,
  LearnerPaths,
  ManagerPaths,
  RedirectAs404,
  UserAccountType,
} from "./utils/Utils";
import ConfirmationPage from "./pages/auth/Confirmation";
import ResetPassword from "./pages/auth/ResetPassword";
import { Storage } from "./services/storage/storage";
const App = (props) => {
  let auth = Storage.getUserToken();
  let user = Storage.getLoggedInUser();

  return (
    <Switch>
      {auth &&
        user &&
        AuthPaths.some((item) => item === props.location.pathname) && (
          <Redirect to={`${process.env.PUBLIC_URL}`} />
        )}
      {auth &&
        user.accountType === UserAccountType.admin &&
        !AdminPaths.some((item) => item === props.location.pathname) && (
          <Redirect to={`${process.env.PUBLIC_URL}`} />
        )}
      {auth &&
        user.accountType === UserAccountType.manager &&
        !ManagerPaths.some((item) => item === props.location.pathname) && (
          <Redirect to={`${process.env.PUBLIC_URL}`} />
        )}
      {auth &&
        user.accountType === UserAccountType.staff &&
        !LearnerPaths.some((item) => item === props.location.pathname) && (
          <Redirect to={`${process.env.PUBLIC_URL}`} />
        )}
      {/* Auth Pages */}
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auth-success`}
        component={Success}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auth-reset`}
        component={ForgotPassword}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auth-login`}
        component={Login}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/auth-create-password`}
        component={CreatePassword}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/confirmation`}
        component={ConfirmationPage}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/reset-password`}
        component={ResetPassword}
      ></Route>

      {/* Helper pages */}
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/terms-condition`}
        component={Terms}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/privacy`}
        component={Privacy}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/help`}
        component={Faq}
      ></Route>

      {/*Error Pages*/}
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/errors/404-classic`}
        component={Error404Classic}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/errors/504-modern`}
        component={Error504Modern}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/errors/404-modern`}
        component={Error404Modern}
      ></Route>
      <Route
        exact
        path={`${process.env.PUBLIC_URL}/errors/504-classic`}
        component={Error504Classic}
      ></Route>

      {/*Main Routes*/}
      <PrivateRoute exact path="" component={Layout}></PrivateRoute>
      <Route component={RedirectAs404}></Route>
    </Switch>
  );
};
export default withRouter(App);
