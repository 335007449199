import React from "react";
import { Step, Steps } from "react-step-builder";
import { Card } from "reactstrap";
import { Block } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import AddRole from "./AddRole";
import RoleTimeRange from "./RoleTimeRange";

const Header = (props) => {
  return (
    <div className="steps clearfix mx-4 primary">
      <ul>
        <li
          className={
            (props.current >= 1 ? "first done" : "first") + " wizard-margin"
          }
        >
          <a href="#wizard-01-h-0 " onClick={(ev) => ev.preventDefault()}>
            <span className="number">Role</span>
          </a>
        </li>
        <li className={(props.current >= 2 ? "done" : "") + " wizard-margin"}>
          <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
            <p className="number">Frequency</p>
          </a>
        </li>
        <li className={props.current >= 3 ? "done" : ""}></li>
      </ul>
    </div>
  );
};

const config = {
  before: Header,
};

const RoleWizard = () => {
  return (
    <React.Fragment>
      <Head title="Role"></Head>
      <Content className="">
        <Block className="wide-md mx-auto">
          <Card className="card-bordered ">
            <div className="nk-wizard nk-wizard-simple wizard clearfix mt-3">
              <Steps config={config}>
                <Step component={AddRole} />
                <Step component={RoleTimeRange} />
              </Steps>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default RoleWizard;
