import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Block,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../components/Component";
import { cpdRemoveAPI, fetchCPDList } from "./StaffData";
import {
  dateToShowFormate,
  dueDateColor,
  openLinkInNewTab,
  showErrorToast,
} from "../../utils";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";

const CPDList = forwardRef(function CPDList({ staffId, isLearnerSide }, ref) {
  const [cpdListData, setCpdListData] = useState([]);

  useImperativeHandle(
    ref,
    () => {
      return {
        reloadCPDList() {
          loadCPDList();
        },
      };
    },
    [staffId]
  );

  useEffect(() => {
    loadCPDList();
  }, [staffId]);

  function loadCPDList() {
    let params = {
      userId: staffId,
    };
    fetchCPDList(params, (data, error) => {
      if (error === null) {
        setCpdListData(data?.list);
      }
    });
  }

  function onRemove(id) {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to remove CPD training?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Remove",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          id: id,
        };
        cpdRemoveAPI(params, (data, error) => {
          if (error === null) {
            loadCPDList();
          } else {
            showErrorToast(error.message);
          }
        });
      }
    });    
  }

  return (
    <React.Fragment>
      <Block>
        <DataTable className={"course-list-view"}>
          {cpdListData?.length > 0 ? (
            <DataTableBody>
              <DataTableHead>
                <DataTableRow className="pt-3">
                  <span className="sub-text">
                    <strong>Training name</strong>
                  </span>
                </DataTableRow>
                <DataTableRow className="pt-3">
                  <span className="sub-text">
                    <strong>Completed Date</strong>
                  </span>
                </DataTableRow>
                <DataTableRow className="pt-3">
                  <span className="sub-text">
                    <strong>Facilitator</strong>
                  </span>
                </DataTableRow>
                <DataTableRow className="text-end pt-3">
                  <span className="sub-text">
                    <strong>Actions</strong>
                  </span>
                </DataTableRow>
              </DataTableHead>
              {cpdListData?.length > 0
                ? cpdListData?.map((item, index) => {
                    return (
                      <DataTableItem key={index}>
                        <DataTableRow>
                          <div className="user-info">
                            <span className="tb-lead">{item?.name ?? ""} </span>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-info">
                            <span className="tb-lead">
                              {item?.completedDate
                                ? dateToShowFormate(item?.completedDate)
                                : "N/A"}
                            </span>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <div className="user-info">
                            <span className="tb-lead">
                              {item?.facilitator ? item?.facilitator : "N/A"}
                            </span>
                          </div>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <ul className="nk-tb-actions gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            openLinkInNewTab(
                                              item?.certificateUrl,
                                              e
                                            );
                                          }}
                                        >
                                          Download Certificate
                                        </span>
                                      </DropdownItem>
                                    </li>
                                    {!item.isManually && isLearnerSide ? (
                                      <></>
                                    ) : (
                                      <li>
                                      <DropdownItem
                                        tag="a"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            onRemove(item?.id);
                                          }}
                                        >
                                          Remove
                                        </span>
                                      </DropdownItem>
                                    </li>
                                    )}

                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
          ) : (
            <div className="text-center m-3">
              <span className="text-silent">No data found</span>
            </div>
          )}
        </DataTable>
      </Block>
    </React.Fragment>
  );
});

export default CPDList;
