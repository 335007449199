import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Icon } from "../../../components/Component";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { ImageContainer } from "./TrainingCard";
import {
  getCourseDetail,
  sendUnlockCourseRequest,
} from "../Training/TrainingData";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import { isCourseCompleted } from "./DueTrainingData";
import { showErrorToast, showSuccessToast } from "../../../utils";
const StartCourse = () => {
  const location = useLocation();
  const [courseData, setCourseData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingUnlockRequest, setIsSendingUnlockRequest] = useState(false);

  const [IsCourseProgress, setIsCourseProgress] = useState(
    location?.state?.IsCourseProgress
  );
  const [isCompleted, setIsCompleted] = useState(false);
  const course = location.state?.item;
  const history = useHistory();

  window.onpopstate = (e) => {
    IsCourseProgress && history.push(`${process.env.PUBLIC_URL}/`);
  };

  useEffect(() => {
    if (course?.id) {
      loadCourse(course.id);
    } else {
      history.push(`${process.env.PUBLIC_URL}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (courseData !== (undefined || null)) {
      if (IsCourseProgress) {
        goToContinueCourse();
      } else if (
        location?.state?.IsEvaluationFormMove &&
        courseData !== undefined &&
        courseData?.completedData?.status === "CHAPTER_COMPLETED"
      ) {
        history.push({
          pathname: `${process.env.PUBLIC_URL}/evalution-form`,
          state: {
            questionData: courseData?.questions,
            courseDetails: courseData?.data,
            courseCompletedId: courseData?.completedData?.id,
            isEvaluationFormMoved: true,
          },
        });
      } else if (
        course &&
        courseData !== undefined &&
        courseData?.completedData?.status === "CHAPTER_COMPLETED" &&
        location?.state?.IsTakeTestMove
      ) {
        history.push({
          pathname: `${process.env.PUBLIC_URL}/course-assesment-test`,
          state: {
            questionData: courseData?.questions,
            courseDetails: courseData?.data,
            userDetails: "",
            isTakeTestMoved: true,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseData]);

  function loadCourse(id) {
    setIsLoading(true);
    getCourseDetail(id, (data, error) => {
      if (error === null) {
        var object = { ...data };
        if (
          isCourseCompleted(object.completedData?.status, course?.dueInStartAt)
        ) {
          setIsCompleted(true);
          object.completedData = undefined;
        } else {
          setIsCompleted(false);
        }
        setCourseData(object);
        setIsLoading(false);
      }
    });
  }

  function sendUnlockRequest() {
    if (courseData?.data?.id) {
      setIsSendingUnlockRequest(true);
      sendUnlockCourseRequest(courseData.data.id, (data, error) => {
        if (error === null) {
          setCourseData((prev) => ({
            ...prev,
            isUnlockRequest: 1,
          }));
          showSuccessToast("Your request has been sent to your manager");
        } else {
          showErrorToast(error.message);
        }
        setIsSendingUnlockRequest(false);
      });
    }
  }

  const goToContinueCourse = () => {
    if (courseData?.data?.chapters?.length > 0) {
      history.push({
        pathname: `${process.env.PUBLIC_URL}/start-course-details`,
        state: {
          data: courseData,
          isCompleted: isCompleted,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <div className="course-details-page">
        <Head title="Product Detail"></Head>
        <LoadingComponent isLoading={isLoading} />
        <Content>
          <div className="p-4 py-5 p-lg-5 bg-primary-dim min-h-292">
            <div className="row ">
              <div className=" col-lg-6 align-center">
                <div className="wide-lg ">
                  <h3
                    className="fw-normal fs-56 text-dark"
                    style={{ marginTop: "revert" }}
                  >
                    {courseData?.data?.name ?? ""}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${isLoading ? "d-none" : "d-block"} wide-lg mx-auto `}
          >
            <div className="row flex-lg-row-reverse px-4 w-100 mx-auto">
              {/* card-- */}
              <div className=" col-lg-5">
                <div className="course-card mx-auto">
                  <div className="video ">
                    <img className="video-poster w-100 " alt="" />
                    <ImageContainer
                      className="rounded-3"
                      img={courseData?.data?.image?.imageUrl}
                    />
                  </div>
                  <div className="p-3 shadow">
                    <p className="fs-4 text-secondary">
                      {courseData?.data?.name ?? ""}
                    </p>
                    <div className="text-secondary d-flex align-items-center mb-2">
                      <em className="ni ni-tag fs-3 me-2"></em>
                      <p className="fs-6">Suitable for all professions</p>
                    </div>
                    <div className="text-secondary d-flex align-items-center mb-2">
                      <em className="ni ni-clock fs-3 me-2"></em>
                      <p className="fs-6">Suitable for all professions</p>
                    </div>
                    <div className="text-secondary d-flex align-items-center mb-2">
                      <em className="ni ni-target fs-3 me-2"></em>
                      <p className="fs-6">Free</p>
                    </div>
                    <div className="text-secondary d-flex align-items-center mb-3">
                      <em className="ni ni-award fs-3 me-2"></em>
                      <p className="fs-6">Certificate Free</p>
                    </div>
                    {courseData?.completedData?.isNextAssessmentAllowed ===
                      true &&
                    courseData?.completedData?.lastChapterIndex ===
                      courseData?.completedData?.totalCompleted - 1 ? (
                      <div
                        className={`${
                          courseData?.data?.chapters?.length > 0
                            ? "text-bg-primary"
                            : "text-bg-gray"
                        } text-center py-2 rounded card-button`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (courseData?.questions && courseData?.data) {
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/course-assesment-test`,
                              state: {
                                questionData: courseData?.questions,
                                courseDetails: courseData?.data,
                                courseFullDetails: courseData,
                              },
                            });
                          }
                        }}
                      >
                        <Icon name="play-circle-fill"></Icon>
                        <span>Retake Assesment</span>
                      </div>
                    ) : (
                      <div
                        className={`${
                          courseData?.completedData?.isRetakeBlock === true &&
                          courseData?.completedData?.isUnlockRequest === 1
                            ? "text-bg-gray"
                            : courseData?.data?.chapters?.length > 0
                            ? "text-bg-primary"
                            : "text-bg-gray"
                        } ${
                          courseData?.totalChaptersCount === 0 ? "d-none" : ""
                        }  text-center py-2 rounded card-button`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (
                            courseData?.completedData?.isRetakeBlock === true &&
                            courseData?.completedData?.isUnlockRequest === 1
                          ) {
                            // ------------------------ blank
                          } else if (
                            courseData?.completedData?.isRetakeBlock === true &&
                            courseData?.completedData?.status ===
                              "CHAPTER_COMPLETED"
                          ) {
                            sendUnlockRequest();
                          } else if (
                            courseData?.completedData?.status ===
                              "CHAPTER_COMPLETED" &&
                            courseData?.data?.isEvaluationRequired === true &&
                            courseData?.completedData
                              ?.isEvaluationFormFilled === false
                          ) {
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/evalution-form`,
                              state: {
                                courseCompletedId:
                                  courseData?.completedData?.id,
                                questionData: courseData?.questions,
                                courseDetails: courseData?.data,
                              },
                            });
                          } else if (
                            course &&
                            courseData?.completedData?.status ===
                              "CHAPTER_COMPLETED"
                          ) {
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/course-assesment-test`,
                              state: {
                                questionData: courseData?.questions,
                                courseDetails: courseData?.data,
                                userDetails: "",
                              },
                            });
                          } else {
                            goToContinueCourse();
                          }
                        }}
                      >
                        {courseData?.completedData?.isRetakeBlock === true &&
                        courseData?.completedData?.status ===
                          "CHAPTER_COMPLETED" ? (
                          ""
                        ) : (
                          <Icon name="play-circle-fill"></Icon>
                        )}
                        <span>
                          {courseData?.completedData?.isRetakeBlock === true &&
                          courseData?.completedData?.isUnlockRequest === 1 ? (
                            "Wait, unlock request sent"
                          ) : courseData?.completedData?.isRetakeBlock ===
                              true &&
                            courseData?.completedData?.status ===
                              "CHAPTER_COMPLETED" ? (
                            isSendingUnlockRequest ? (
                              <Spinner size="sm" color="light" />
                            ) : (
                              "Tap to send unlock request"
                            )
                          ) : courseData?.completedData?.status ===
                            "CHAPTER_COMPLETED" ? (
                            courseData?.data?.isEvaluationRequired === true &&
                            courseData?.completedData
                              ?.isEvaluationFormFilled === false ? (
                              "Evaluation Form"
                            ) : (
                              "Take test"
                            )
                          ) : isCompleted ? (
                            "View"
                          ) : (
                            "Join Now"
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* details-- */}
              <div className="col-lg-7">
                <div className="py-5">
                  <h5 className="mb-4 fs-3 text-dark fw-normal">Outcomes</h5>
                  <ul className="list list-md list-checked">
                    <div className="px-4 row">
                      {courseData?.data?.outcome?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="mb-2 col-lg-6">
                              <li className="fs-6">{item}</li>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </ul>
                </div>
                <div className="bg-primary-dim py-4 px-3 rounded">
                  <h5 className="mb-4 fs-3 text-dark fw-normal">
                    About this course
                  </h5>
                  <p className="fs-6">{courseData?.data?.aboutTraining}</p>
                </div>
                <div className="py-5">
                  <h5 className="mb-4 fs-3 text-dark fw-normal">Chapters</h5>

                  <div className="px-4 row">
                    {courseData?.data?.chapters?.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="mb-4 fs-6">
                            {`Chapter ${index + 1}: ${
                              item?.title ?? "Not specified"
                            }`}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </div>
    </React.Fragment>
  );
};

export default StartCourse;
