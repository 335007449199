import React from "react";
import { useEffect } from "react";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Col } from "../../components/Component";
import { locationsDropDown } from "../dashboard/Location/LocationData";
import { useState } from "react";

const SetLocationFilter = ({
  location,
  visible,
  selectedLocations,
  onChanged,
  onClosed,
}) => {
  const [locationData, setLocationData] = useState([]);
  const [isInitScreen, setIsInitScreen] = useState(true);

  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    if (isInitScreen) {
      return;
    }
    let list = locationData.filter((e) => e.checked === true);
    onChanged(list);
  }, [locationData]);

  function loadLocations() {
    locationsDropDown(false, (locationData, error) => {
      if (error === null) {
        setLocationData(locationData);
      }
      setIsInitScreen(false);
    });
  }
  useEffect(() => {
    if (visible) {
      if (locationData) {
        setLocationData((prevData) =>
          prevData.map((item) =>
            selectedLocations?.some((e) => e.id === item.id)
              ? { ...item, checked: true }
              : { ...item, checked: false }
          )
        );
      }
    }
  }, [visible]);

  const onSelectChange = (e, id) => {
    const newState = e.currentTarget.checked;
    setLocationData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, checked: newState } : item
      )
    );
  };

  return (
    <>
      <Modal
        isOpen={visible}
        toggle={() => onClosed()}
        className="modal-dialog-centered location-filter modal-dialog-scrollable"
        size="md"
      >
        <ModalHeader className="bg-purple text-white" toggle={onClosed}>
          {" Locations"}
        </ModalHeader>
        <ModalBody>
          <Form className="row gy-3">
            {locationData.map((item, index) => (
              <Col lg="4" xxl="3" md="6" sm="12" key={index}>
                <div className="form-group">
                  <span className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`pid-${item.id}`}
                        checked={item?.checked ?? false}
                        onChange={(e) => onSelectChange(e, item.id)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`pid-${item.id}`}
                      >
                        {item.label}
                      </label>
                    </div>
                  </span>
                </div>
              </Col>
            ))}
          </Form>
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" type="button" onClick={() => onClosed()}>
            Save
          </Button>
        </ModalFooter> */}
      </Modal>
    </>
  );
};

export default SetLocationFilter;
