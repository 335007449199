import moment from "moment";
import { apiService } from "../../services/api";
import { APIServicePath } from "../../services/api/api-service-impl";
import { dateToShowFormate, toCapitalize } from "../../utils";

export const addStaff = (data, callback) => {
  apiService
    .postCall(APIServicePath.addStaff, data)
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const editStaff = (data, callback) => {
  apiService
    .postCall(APIServicePath.editStaff, data)
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const removeStaff = (data, callback) => {
  apiService
    .postCall(APIServicePath.removeStaff, data)
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const fetchStaffDetail = (id, callback) => {
  apiService
    .postCall(APIServicePath.detailStaff, { id: parseInt(id) })
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const fetchCPDSave = (params, callback) => {
  apiService
    .postCall(APIServicePath.cpdFormSave, params)
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const cpdRemoveAPI = (params, callback) => {
  apiService
    .postCall(APIServicePath.removeCPD, params)
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const fetchCPDList = (params, callback) => {
  apiService
    .postCall(APIServicePath.cpdList, params)
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const uploadCertificateFile = (file, type, callback) => {
  apiService
    .fileUploader(file, type, APIServicePath.fileUpload)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const archiveCertificateAPI = (params, callback) => {
  apiService
    .postCall(APIServicePath.archiveCertificate, params)
    .then((result) => {
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export function fetchStaff(
  page,
  itemPerPage,
  search,
  status,
  locationIds,
  rolesIds,
  isOverdue,
  token,
  callback
) {
  let params = {
    pageInfo: {
      pageIndex: page,
      itemsPerPage: itemPerPage,
    },
    search: search,
    locationIds: locationIds,
    roleIds: rolesIds,
  };

  if (isOverdue) {
    params["isOverdue"] = isOverdue;
  }

  if (status.value !== "ALL") {
    params["status"] = status.value;
  }

  apiService
    .postCallWithCancelSignal(APIServicePath.staffList, params, token)
    .then((data) => {
      let result = toStaffList(data);
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

function toStaffList(data) {
  let list = [];
  for (let index = 0; index < data?.list?.length ?? 0; index++) {
    const element = data?.list[index];
    list.push(toStaff(element));
  }
  return { list: list, pageInfo: data.pageInfo };
}

function toStaff(data) {
  let item = {
    id: data.id,
    name:
      toCapitalize(data.firstName.trim()) +
      " " +
      toCapitalize(data.lastName.trim()),
    role: data?.role?.name ?? "",
    status: data?.status === "ACTIVE" ? "Active" : "Paused",
    login: data.lastLogin ? dateToShowFormate(data.lastLogin) : null,
    compliance: data?.compliancePercentage ?? 0,
    location: data?.homeLocation?.name ?? "",
    accountType: data?.userType ?? null,
    email: data?.email ?? "",
  };
  return item;
}

export const getAssignedCourseList = (params, callback) => {
  apiService
    .postCall(APIServicePath.assignedCourseList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const getFTFTrainingList = (params, callback) => {
  apiService
    .postCall(APIServicePath.ftfTrainingList, params)
    .then((data) => {
      callback(data?.list ?? [], null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const staffcourseupdate = (params, callback) => {
  apiService
    .postCall(APIServicePath.staffCourseUpdate, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const markAsComplete = (params, callback) => {
  apiService
    .postCall(APIServicePath.courseMarkCompleteByAdmin, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const markNotMandatory = (params, callback) => {
  apiService
    .postCall(APIServicePath.courseMarkNotMandatoryByAdmin, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const resendInvitation = (params, callback) => {
  apiService
    .postCall(APIServicePath.staffResendInvite, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
export const assignCourseToStaff = (params, callback) => {
  apiService
    .postCall(APIServicePath.assignCourseToStaff, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const resetStaffTraining = (params, callback) => {
  apiService
    .postCall(APIServicePath.resetTraining, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
};
