import React from "react";
import Head from "../../layout/head/Head";
import FaceToFaceCalendar from "./FaceToFaceCalendar";

const Facetoface = ({ ...props }) => {
  return (
    <>
      <Head title="face-to-face" />
      <FaceToFaceCalendar />
    </>
  );
};

export default Facetoface;
