import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { getRoleList, removeUserRole } from "./RolesData";

import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import { useHistory } from "react-router-dom";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import { UserAccountType, showErrorToast } from "../../../utils";
import { Storage } from "../../../services/storage/storage";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";

const Roles = () => {
  const history = useHistory();
  const [data, setData] = useState([]);

  const [currentPage] = useState(1);
  // const [itemPerPage] = useState(10);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const user = Storage.getLoggedInUser();

  useEffect(() => {
    setTimeout(() => {
      if (currentPage !== 0) {
        loadRoles();
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get current list, pagination
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //******************API************************/
  function loadRoles() {
    getRoleList(false, (data, error) => {
      setIsInitScreen(false);
      if (error === null) {
        setData(data);
      }
    });
  }

  //*******************Functions**************************/
  function onEditClick(item) {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/role`,
      state: {
        roleId: item.id,
        name: item.role,
        courses: item.courses,
      },
    });
  }

  const onRemoveClick = (item, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${item.role} role?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        removeUserRole(item.id, (_, error) => {
          if (error === null) {
            setData(data.filter((data) => item.id !== data.id));
          } else {
            showErrorToast(error.message);
          }
        });
      }
    });
  };

  const onAddClick = () => {
    // history.push(`${process.env.PUBLIC_URL}/add-role`);
    history.push(`${process.env.PUBLIC_URL}/role`);
  };
  //*********************************************/

  return (
    <React.Fragment>
      <Head title="roles-list" />
      {!isInitScreen && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Roles list
                </BlockTitle>
              </BlockHeadContent>
              {user.accountType === UserAccountType.admin && (
                <BlockHeadContent>
                  <Button
                    color="primary"
                    className="btn-text custom-solid-btn-sm"
                    onClick={onAddClick}
                  >
                    Add Role
                  </Button>
                </BlockHeadContent>
              )}
            </BlockBetween>
          </BlockHead>

          <Block>
            <DataTable className="card-stretch">
              <DataTableBody>
                <DataTableHead>
                  {/* <DataTableRow className="nk-tb-col-check"></DataTableRow> */}
                  <DataTableRow>
                    <span className="sub-text">Role</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text text-center">Courses</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text text-center"></span>
                  </DataTableRow>
                  {user.accountType === UserAccountType.admin && (
                  <DataTableRow>
                    <span className="sub-text text-end"></span>
                  </DataTableRow>
                  )}
                </DataTableHead>
                {/*Head*/}
                {data.length > 0
                  ? data.map((item, index) => {
                      return (
                        <DataTableItem key={index}>
                          {/* <DataTableRow className="nk-tb-col-check"></DataTableRow> */}
                          <DataTableRow>
                            <div className="user-info">
                              <span className="tb-lead">{item.role} </span>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-info text-center">
                              <span className="text-success ">
                                {item.courses.length}{" "}
                              </span>
                            </div>
                          </DataTableRow>
                          <DataTableRow className="col-5">
                            <div className="user-info ">
                              <span className=" text-dark">
                                <a
                                  onClick={(event) => {
                                    event.preventDefault();
                                    item.isOpen = !item.isOpen;
                                    let list = [...data];
                                    list[index] = item;
                                    setData(list);
                                  }}
                                  href="#name"
                                  className="mb-5 clcikable"
                                >
                                  Course list
                                  {item.isOpen ? (
                                    <Icon name="chevron-down" />
                                  ) : (
                                    <Icon name="chevron-right" />
                                  )}
                                </a>
                                {item.isOpen && (
                                  <ul
                                    className="list-group"
                                    id={item.id + "pid-all"}
                                  >
                                    <label htmlFor={item.id + "pid-all"}>
                                      {item.courses.map((item) => (
                                        <li key={item.id}>{item.title}</li>
                                      ))}
                                    </label>
                                  </ul>
                                )}
                              </span>
                            </div>
                          </DataTableRow>
                          {user.accountType === UserAccountType.admin && (
                          <DataTableRow className="nk-tb-col-tools text-end">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                href="#folder"
                                className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                                onClick={(ev) => ev.preventDefault()}
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                  <>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#item"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          onEditClick(item);
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#item"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          onRemoveClick(item, index);
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                        <span>Remove</span>
                                      </DropdownItem>
                                    </li>
                                  </>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </DataTableRow>
                          )}
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
              {/* <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data?.length ?? 0}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No role found</span>
                </div>
              )}
            </div> */}
            </DataTable>
          </Block>
        </Content>
      )}

      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default Roles;
