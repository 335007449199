import React from "react";
import { useEffect } from "react";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Col, Row } from "../../components/Component";
import { locationsDropDown } from "../dashboard/Location/LocationData";
import { useState } from "react";
import { CourseStatus, courseMatrixStatus } from "../../utils/Utils";
import { getCourseList } from "../dashboard/Roles/RolesData";

const CourseFilter = ({
  visible,
  selectedLocations,
  selectedStatus,
  selectedCourse,
  onChanged,
  onClosed,
}) => {
  const [locationData, setLocationData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [isInitScreen, setIsInitScreen] = useState(true);

  useEffect(() => {
    loadLocations();
    loadStatus();
    loadCourses();
  }, []);

  const loadStatus = () => {
    let statuslist = [];
    const status = Object.values(CourseStatus);
    status.map((item, index) => {
      let status = {
        id: index + 1,
        value: item?.value,
        label: item?.label,
        checked: false,
      };
      return statuslist.push(status);
    });

    setStatusData(statuslist);
    updateStatus(statuslist);
  };

  function loadLocations() {
    locationsDropDown(false, (locationData, error) => {
      if (error === null) {
        setLocationData(locationData);
        updateLocations(locationData);
      }
      setIsInitScreen(false);
    });
  }

  function loadCourses() {
    getCourseList(true, (data, error) => {
      if (error === null) {
        setCourseData(data);
        updateCourses(data);
      }
    });
  }

  function updateLocations(locationData) {
    if (locationData) {
      setLocationData((prevData) =>
        prevData.map((item) =>
          selectedLocations?.some((e) => e.id === item.id)
            ? { ...item, checked: true }
            : { ...item, checked: false }
        )
      );
    }
  }

  function updateCourses(courseData) {
    if (courseData) {
      setCourseData((prevData) =>
        prevData.map((item) =>
          selectedCourse?.some((e) => e.id === item.id)
            ? { ...item, checked: true }
            : { ...item, checked: false }
        )
      );
    }
  }

  function updateStatus(statusData) {
    if (statusData) {
      setStatusData((prevData) =>
        prevData.map((item) =>
          selectedStatus?.some((e) => e.id === item.id)
            ? { ...item, checked: true }
            : { ...item, checked: false }
        )
      );
    }
  }

  useEffect(() => {
    if (visible) {
      updateLocations(locationData);
      updateCourses(courseData);
      updateStatus(statusData);
    }
  }, [visible]);

  const onSelectChange = (e, object, type, index) => {
    const newState = e.currentTarget.checked;
    if (type === "location") {
      setLocationData((prevData) =>
        prevData.map((item) =>
          item.id === object.id ? { ...item, checked: newState } : item
        )
      );
    } else if (type === "course") {
      setCourseData((prevData) =>
        prevData.map((item) =>
          item.id === object.id ? { ...item, checked: newState } : item
        )
      );
    } else if (type === "status") {
      object.checked = newState;
      let list = [...statusData];
      list[index] = object;
      setStatusData(list);
    } else {
    }
  };
  const resetFilter = () => {
    setLocationData((prevData) =>
      prevData.map((item) => ({ ...item, checked: false }))
    );
    setCourseData((prevData) =>
      prevData.map((item) => ({ ...item, checked: false }))
    );
    setStatusData((prevData) =>
      prevData.map((item) => ({ ...item, checked: false }))
    );
    // onChanged({ loaction: null, status: null, course: null });
  };

  const onSubmit = () => {
    let loaction = locationData.filter((e) => e.checked === true);
    let status = statusData.filter((e) => e.checked === true);
    let course = courseData.filter((e) => e.checked === true);
    onChanged({ loaction: loaction, status: status, course: course });
  };

  return (
    <>
      <Modal
        isOpen={visible}
        toggle={() => onClosed()}
        className="modal-dialog-centered modal-dialog-scrollable course-matrix-filter"
        size="lg"
      >
        <ModalHeader className="bg-purple text-white" toggle={onClosed}>
          {"Course filter"}
        </ModalHeader>
        <ModalBody>
          <Form className="row gy-3">
            <div>
              <p>status</p>
              <Row>
                {statusData?.map((status, index) => (
                  <Col lg="4" md="6" sm="12" key={index}>
                    <div className="form-group">
                      <span className="nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`sid-${status.id}`}
                            checked={status?.checked ?? false}
                            onChange={(e) =>
                              onSelectChange(e, status, "status", index)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`sid-${status.id}`}
                          >
                            {status.value}
                          </label>
                        </div>
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div>
              <p>Locations</p>
              <Row>
                {locationData.map((item, index) => (
                  <Col lg="4" md="6" sm="12" key={index}>
                    <div className="form-group">
                      <span className="nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`pid-${item.id}`}
                            checked={item?.checked ?? false}
                            onChange={(e) =>
                              onSelectChange(e, item, "location", index)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`pid-${item.id}`}
                          >
                            {item.label}
                          </label>
                        </div>
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div>
              <p>Courses</p>
              <Row>
                {courseData.map((item, index) => (
                  <Col lg="4" md="6" sm="12" key={index}>
                    <div className="form-group">
                      <span className="nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`cid-${item.id}`}
                            checked={item?.checked ?? false}
                            onChange={(e) =>
                              onSelectChange(e, item, "course", index)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`cid-${item.id}`}
                          >
                            {item.title}
                          </label>
                        </div>
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="button"
            className="custom-solid-btn-sm"
            onClick={() => {
              resetFilter();
              // onClosed();
            }}
          >
            Reset
          </Button>
          <Button
            color="primary"
            type="button"
            className="custom-solid-btn-sm"
            onClick={() => {
              onSubmit();
              onClosed();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CourseFilter;
