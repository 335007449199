import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";

export const CertificateData = [
    {
      id: 1,
      avatarBg: "purple",
      name: "Abu Bin Ishtiyak",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 85,
  
      nurshing: "nurshingcare",
    },
    {
      id: 2,
      avatarBg: "purple",
      name: "Ashley Lawson",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 3,
      avatarBg: "info",
      name: "Joe Larson",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 70,
  
      nurshing: "nurshingcare",
    },
    {
      id: 4,
      avatarBg: "danger",
      name: "Jane Montgomery",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 5,
      avatarBg: "purple",
      name: "Frances Burns",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 55,
  
      nurshing: "nurshingcare",
    },
    {
      id: 6,
      avatarBg: "primary",
      name: "Alan Butler",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 7,
      avatarBg: "warning",
      name: "Victoria Lynch",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 25,
  
      nurshing: "nurshingcare",
    },
    {
      id: 8,
      avatarBg: "success",
      name: "Patrick Newman",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 9,
      avatarBg: "purple",
      name: "Jane Harris",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 80,
  
      nurshing: "nurshingcare",
    },
    {
      id: 10,
      avatarBg: "purple",
      name: "Emma Walker",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 22,
  
      nurshing: "nurshingcare",
    },
    {
      id: 11,
      avatarBg: "pink",
      name: "Lilja Peltola",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 12,
      avatarBg: "secondary",
      name: "Annette Hunter",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 13,
      avatarBg: "pink",
      name: "Sara Koivisto",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 14,
      avatarBg: "blue",
      name: "Kianna Pham",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 15,
      avatarBg: "pink",
      name: "Raymond Atkins",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 16,
      avatarBg: "blue",
      name: "Amira Talley",
      role: "Nurse",
      status: "Active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 17,
      avatarBg: "secondary",
      name: "Lana Steiner",
      role: "Nurse",
      status: "active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 18,
      avatarBg: "warning",
      name: "Joshua Mcnair",
      role: "Nurse",
      status: "active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 19,
      avatarBg: "secondary",
      name: "Asiya Wolff",
      role: "Nurse",
      status: "active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
    {
      id: 20,
      avatarBg: "warning",
      name: "Fox Mccloud",
      role: "Nurse",
      status: "active",
      issuedDate: "2023-04-16T12:00:00.000Z",
      compliance: 100,
  
      nurshing: "nurshingcare",
    },
  ];
  
  export const bulkActionOptions = [
    { value: "Active", label: "Active" },
    { value: "Paused", label: "Paused" },
    { value: "All", label: "All" },
  ];
  export const RoleOptions = [
    { value: "Active", label: "Active" },
    { value: "Paused", label: "Paused" },
    { value: "All", label: "All" },
  ];
  export const LocationOption = [
    { value: "Ahme", label: "ahme" },
    { value: "Paused", label: "Paused" },
    { value: "All", label: "All" },
  ];

  export const getMyCertificateList = (callback) => {
    apiService
      .postCall(APIServicePath.getMyCertificate)
      .then((data) => {
        callback(data.list, null);
      })
      .catch((error) => {
        callback(null, error);
      });
  }


  export const staffGetCertificateList = (params, callback) => {
    apiService
      .postCall(APIServicePath.staffGetCertificate, params)
      .then((data) => {
        callback(data, null);
      })
      .catch((error) => {
        callback(null, error);
      });
  }

  export const downloadCertificate = (params, callback) => {
    apiService
      .postCall(APIServicePath.downloadAllCertificate, params)
      .then((data) => {
        callback(data, null);
      })
      .catch((error) => {
        callback(null, error);
      });
  }