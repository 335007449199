import React, { useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
} from "../../../components/Component";
import ProgressColor from "../../../components/progress/ProgressColor";
import Content from "../../../layout/content/Content";
import { useHistory, useLocation } from "react-router";

import Head from "../../../layout/head/Head";
import { LocationDropDown } from "../Location/LocationDropDown";
import { fetchCourseList } from "./TrainingData";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import { UserAccountType } from "../../../utils";
import { Storage } from "../../../services/storage/storage";

const TrainingList = () => {
  const locationState = useLocation();
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [location, setLocation] = useState(
    locationState.state?.location ?? null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(40);
  const [totalItems, setTotalItems] = useState(0);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const user = Storage.getLoggedInUser();

  useEffect(() => {
    if (currentPage !== 0) {
      fetchTraining();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Changing state value when searching name
  useEffect(() => {
    if (isInitScreen) {
      return;
    }
    if (currentPage === 1) {
      fetchTraining();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, location]);

  const onAddClick = () => {
    history.push(`${process.env.PUBLIC_URL}/course-wizard`);
  };

  const onEditClick = (course, index) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/course-wizard`,
      state: { id: course.id },
    });
  };

  // Get current list, pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function fetchTraining() {
    fetchCourseList(searchText, location?.id, (data, error) => {
      setIsInitScreen(false);
      if (error === null) {
        setCourses(data);
        if (currentPage === 1) {
          setTotalItems(data.length ?? 0);
        }
      }
    });
  }

  return (
    <React.Fragment>
      <Head title="Training" />
      {!isInitScreen && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Training
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                {user.accountType === UserAccountType.admin && (
                  <Button
                    color="primary"
                    className="btn-text custom-solid-btn-sm"
                    onClick={onAddClick}
                  >
                    Add Course
                  </Button>
                )}
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-2 d-flex flex-column flex-md-row ">
                      <div className="form-wrap">
                        <input
                          className="form-control w-250px mb-2 mb-md-0"
                          type="text"
                          name="search"
                          value={searchText}
                          placeholder="Search"
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-wrap">
                        <LocationDropDown
                          value={location}
                          className="w-250px"
                          onSelect={(e) => setLocation(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">
                      <strong>Training name</strong>
                    </span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text ">
                      <strong>Total compliance %</strong>
                    </span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text ">
                      <strong>Compliant staff</strong>
                    </span>
                  </DataTableRow>
                  {user.accountType === UserAccountType.admin && (
                    <DataTableRow className="nk-tb-col-tools text-end">
                      <span className="sub-text"></span>
                    </DataTableRow>
                  )}
                </DataTableHead>
                {/*Head*/}
                {courses.length > 0
                  ? courses.map((item, index) => {
                      return (
                        <DataTableItem key={index}>
                          <DataTableRow>
                            <div className="user-info">
                              <span className="tb-lead">{item.name} </span>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <ProgressColor value={item.average} />
                          </DataTableRow>
                          <DataTableRow>
                            <div className="project-progress ">
                              <div className="project-progress-percent">
                                {item.complaintStaff} / {item.totalStaff}
                              </div>
                            </div>
                          </DataTableRow>
                          {user.accountType === UserAccountType.admin && (
                            <DataTableRow className="nk-tb-col-tools text-end">
                              <div
                                className="project-progress text-end"
                                onClick={() => onEditClick(item, index)}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="project-progress-percent">
                                  <Icon name="edit" className={"iconSize18"} />
                                </div>
                              </div>
                            </DataTableRow>
                          )}
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
              {totalItems > courses.length && (
                <div className="card-inner">
                  {totalItems > 0 && totalItems > courses.length ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={totalItems}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : totalItems === 0 ? (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  ) : null}
                </div>
              )}
            </DataTable>
          </Block>
        </Content>
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default TrainingList;
