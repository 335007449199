import React, { useEffect, useState } from "react";
import { RSelect } from "../../components/Component";
import { courseDropDown } from "./FaceToFaceData";

export const CourseDropDown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  placeholder,
  isFetchAll = false,
  isClearable = false,
  ...props
}) => {
  const [list, setList] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = () => {
    setIsLoading(true);
    courseDropDown(isFetchAll, (data, error) => {
      if (error === null) {
        let others = {
          id: undefined,
          label: "Others",
          value: "Others",
        };
        setList([...data, others]);
      }
      setIsLoading(false);
    });
  };

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      isClearable={isClearable}
      placeholder={placeholder}
      isLoading={isLoading}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
