import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  addLocation,
  deleteLocation,
  editLocation,
  locationWithStatistics,
} from "./LocationData";
import {
  Modal,
  ModalBody,
  Form,
  Alert,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PreviewAltCard,
  PaginationComponent,
} from "../../../components/Component";
import ProgressColor from "../../../components/progress/ProgressColor";
import { Storage } from "../../../services/storage/storage";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { UserAccountType, showErrorToast } from "../../../utils";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";

var token = {};
const LocationList = () => {
  const [searchText, setSearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [user] = useState(Storage.getLoggedInUser());
  const [list, setList] = useState([]);
  const [errorVal, setError] = useState();
  const [editItem, setEditItem] = useState();
  const [isInitScreen, setIsInitScreen] = useState(true);

  // Change Page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (currentPage !== 0) {
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Changing state value when searching name
  useEffect(() => {
    if (isInitScreen) {
      return;
    }
    token?.cancel && token?.cancel();
    if (currentPage === 1) {
      fetchLocations();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPerPage, searchText]);

  function fetchLocations() {
    locationWithStatistics(
      currentPage,
      itemPerPage,
      searchText,
      token,
      (data, error) => {
        if (error === null) {
          setIsInitScreen(false);
          setList(data.list);
          if (currentPage === 1) {
            setTotalItems(data.pageInfo?.totalItems ?? 0);
          }
        } else {
          setIsInitScreen(false);
        }
      }
    );
  }

  const onEditClick = (item, index) => {
    setEditItem(item);
    setModal(true);
  };

  const onRemoveClick = (item, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${item.name} location?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
      confirmButtonColor: "primary",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteLocation(item.id, (data, error) => {
          if (error === null) {
            setList(list.filter((data) => item.id !== data.id));
          } else {
            showErrorToast(error.message);
          }
        });
      }
    });
  };

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedDate = `${yesterday.getDate().toString().padStart(2, "0")}-${(
    yesterday.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${yesterday.getFullYear()}`;

  return (
    <React.Fragment>
      <Head title="Location" />
      {!isInitScreen && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Locations
                </BlockTitle>
              </BlockHeadContent>
              {user.accountType === UserAccountType.admin && (
                <BlockHeadContent>
                  <Button
                    className={`  btn-text  toggle-expand me-n1 btn-primary custom-solid-btn-sm`}
                    onClick={() => setModal(true)}
                  >
                    Add Location
                  </Button>
                </BlockHeadContent>
              )}
            </BlockBetween>
          </BlockHead>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-2">
                      <div className="form-wrap">
                        <input
                          className="form-control"
                          type="text"
                          name="search"
                          value={searchText}
                          autoComplete="off"
                          placeholder="Search"
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">Location name</span>
                  </DataTableRow>
                  <DataTableRow size={"sm"}>
                    <span className="sub-text ">Compliant staff</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text ">Total compliance %</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text ">
                      {`Average compliance % (${formattedDate})`}
                    </span>
                  </DataTableRow>
                  {user.accountType === UserAccountType.admin && (
                    <DataTableRow></DataTableRow>
                  )}
                </DataTableHead>
                {/*Head*/}
                {list.length > 0
                  ? list.map((item, index) => {
                      return (
                        <DataTableItem key={index}>
                          <DataTableRow className="w-max-150px">
                            <div className="user-info">
                              <span className="tb-lead">{item.name} </span>
                            </div>
                          </DataTableRow>
                          <DataTableRow size={"sm"}>
                            <div className="project-progress ">
                              <div className="project-progress-percent">
                                {item.totalComplianceStaff} / {item.totalStaff}
                              </div>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <ProgressColor value={item.totalCompliance ?? 0} />
                          </DataTableRow>
                          <DataTableRow>
                            <ProgressColor value={item.avgCompliance ?? 0} />
                          </DataTableRow>

                          {user.accountType === UserAccountType.admin && (
                            <DataTableRow className="nk-tb-col-tools text-end">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  href="#folder"
                                  className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                                  onClick={(ev) => ev.preventDefault()}
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <>
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#item"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            onEditClick(item, index);
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#item"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            onRemoveClick(item, index);
                                          }}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Remove</span>
                                        </DropdownItem>
                                      </li>
                                    </>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </DataTableRow>
                          )}
                        </DataTableItem>
                      );
                    })
                  : null}
              </DataTableBody>
            </DataTable>
            <PreviewAltCard className={"border-top"}>
              {totalItems > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={totalItems}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">
                    You have not added any location yet.
                  </span>
                </div>
              )}
            </PreviewAltCard>
          </Block>
          <AddLocationView
            visible={modal}
            item={editItem}
            onClosed={(status) => {
              if (status) {
                fetchLocations();
              }
              setModal(false);
              setEditItem();
            }}
          />
        </Content>
      )}

      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

const AddLocationView = ({ visible, item, onClosed }) => {
  const [errorVal, setError] = useState();
  const [name, setName] = useState();
  const { errors, register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      setError();
      setName();
    }
    setName(item?.name);
  }, [visible]);

  const onFormSubmit = (formData) => {
    setLoading(true);
    if (item) {
      editLocation(item.id, formData.name, (data, error) => {
        setLoading(false);
        if (error === null) {
          onClosed(true);
        } else {
          setError(error.message);
        }
      });
    } else {
      addLocation(formData.name, (data, error) => {
        setLoading(false);
        if (error === null) {
          onClosed(true);
        } else {
          setError(error.message);
        }
      });
    }
  };

  return (
    <Modal
      isOpen={visible}
      toggle={() => onClosed(false)}
      className="modal-dialog-centered"
      size="sm"
    >
      <ModalBody>
        <a
          href="#close"
          className="close"
          onClick={(ev) => {
            ev.preventDefault();
            onClosed(false);
          }}
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{item ? "Edit Location" : "Add Location"}</h5>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
          <div className="mt-4">
            <Form
              className="row gy-4 form-validation"
              onSubmit={handleSubmit(onFormSubmit)}
            >
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Location name</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={name ?? ""}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter location"
                      ref={register({ required: "This field is required" })}
                    />
                    {errors.name && (
                      <span className="invalid">{errors.name.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button
                      className="custom-solid-btn-sm"
                      color="primary"
                      size="md"
                      type="submit"
                    >
                      {loading ? <Spinner size="sm" color="light" /> : "Save"}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LocationList;
