
export const userData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "Abu Bin Ishtiyak",
    role: "Nurse",
    status: "Active",
    login: "09 February, 2023",
    compliance: 85,
    nurshing: "nurshingcare",
    training: "Adult Safeguarding Northern Ireland",
    duedate: "2023-05-20",
    completedate: "2021-11-25",


  },
  {
    id: 2,
    avatarBg: "purple",
    name: "Ashley Lawson",
    role: "Nurse",
    status: "Active",
    login: "09 February,2023",
    compliance: 100,
    nurshing: "nurshingcare",
    training: "Adult Safeguarding Northern Ireland",
    duedate: "19 November, 2023",
    completedate: "09 May, 2023",


  },
  {
    id: 3,
    avatarBg: "info",
    name: "Joe Larson",
    role: "Nurse",
    status: "Active",
    login: "09 February, 2023",
    compliance: 70,
    nurshing: "nurshingcare",
    training: "Adult Safeguarding Northern Ireland",
    duedate: "19 April, 2023",
    completedate: "19 May, 2023",


  },
];

export const PermissionOption = [
  { value: "LEARNER", label: "Learner" },
  { value: "MANAGER", label: "Manager" },
];

export const StatusOption = [
  { value: "ACTIVE", label: "Active" },
  { value: "DISABLED", label: "Paused" },
];
