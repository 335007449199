import React from "react";
import { Modal, ModalBody } from "reactstrap";

const VideoPlay = ({ visible, item, onClosed }) => {
  return (
    <Modal
      isOpen={visible}
      toggle={() => onClosed(false)}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody className="p-0">
        <div>
          <iframe
            width="100%"
            className="d-block"
            height="550"
            src={`//www.youtube.com/embed/${item}?autoplay=1&mute=0`}
            title="YouTube video player"
            frameborder="0"
            allow="autoplay"
            allowfullscreen
          ></iframe>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VideoPlay;
