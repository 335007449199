import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Col, Icon } from "../../../../components/Component";

const AddOutcome = ({ visible, item, onSaved, onClosed }) => {
  const [formData, setFormData] = useState({
    description: "",
  });

  useEffect(() => {
    if (visible) {
    } else {
      setFormData({ ...formData, description: "" });
    }
  }, [visible]);

  useEffect(() => {
    if (item) {
      setFormData({ ...formData, description: item });
    }
  }, [item]);

  const onFormSubmit = () => {
    const { description } = formData;
    onSaved(description);
  };
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { errors, register, handleSubmit } = useForm();

  return (
    <>
      <Modal
        isOpen={visible}
        toggle={() => onClosed()}
        className="modal-dialog-centered"
        size="md"
      >
        <ModalHeader toggle={onClosed}>
          {item ? "Edit outcome" : "Add outcome"}
        </ModalHeader>
        <ModalBody>
          <Form className="row gy-4">
            <Col size="12">
              <div className="form-group">
                <textarea
                  name="description"
                  value={formData.description}
                  placeholder="Your outcome"
                  onChange={(e) => onInputChange(e)}
                  className="form-control-xl form-control no-resize"
                  ref={register({
                    required: "This field is required",
                  })}
                />
                {errors.description && (
                  <span className="invalid">{errors.description.message}</span>
                )}
              </div>
            </Col>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="custom-solid-btn-sm"
            onClick={() => onFormSubmit()}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddOutcome;
