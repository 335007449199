import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../components/Component";

const Terms = ({ ...props }) => {
  return (
    <React.Fragment>
      <Head title="Terms &amp; Conditions" />
      <Content>
        <div className="termsCondition">
          <div class="container">
            <h2>TERMS AND CONDITIONS</h2>

            <p className="fw-bold">Last Updated: 20 January 2024</p>
            <p>
              These terms and conditions are intended to apply to access to and
              use of our product available on our website; Saunders Training
              Academy
            </p>
            <p>
              Saunders Training Academy is a learning portal and career
              development platform for health and social care. Saunders Training
              Academy provides both online and offline solutions designed to
              offer health and social care workers access to the best continuing
              professional development and educational materials, with access to
              a range of courses and documentation.
            </p>
            <p>
              These terms and conditions (including any Schedules or Appendices)
              apply to the services provided by us, (trading as "Saunders
              Training Academy"), in which users access and make use of the
              services referred to herein. Saunders Training Academy is referred
              to as the “Supplier” where relevant for the purposes of these
              terms and conditions, further details of which can be found in the
              “Definitions and Interpretation” section below.
            </p>
            <ul>
              <li className="fw-bold">Definitions and interpretation</li>
              <li>In these Conditions the following definitions apply:</li>
            </ul>
            <div class="tbl">
              <table className="w85">
                <tr>
                  <th>Active User</th>
                  <td>
                    means any User who is not a Paused User or a Deactivated
                    User;
                  </td>
                </tr>
                <tr>
                  <th>Affiliate</th>
                  <td>
                    means any entity that directly or indirectly Controls, is
                    Controlled by or is under common Control with, another
                    entity;
                  </td>
                </tr>
                <tr>
                  <th>Applicable Law</th>
                  <td>
                    means all applicable laws, legislation, statutory
                    instruments, regulations and governmental guidance having
                    binding force whether local or national or international in
                    any relevant jurisdiction;
                  </td>
                </tr>
                <tr>
                  <th>Bribery Laws</th>
                  <td>
                    means the Bribery Act 2010 and all Applicable Laws in
                    connection with bribery or anti- corruption;
                  </td>
                </tr>
                <tr>
                  <th>Business Day</th>
                  <td>
                    means a day other than a Saturday, Sunday or bank or public
                    holiday when banks generally are open for non-automated
                    business in Northern Ireland;
                  </td>
                </tr>
                <tr>
                  <th>Conditions</th>
                  <td>
                    means the Supplier’s terms and conditions of supply set out
                    in this document;
                  </td>
                </tr>
                <tr>
                  <th>Confidential Information</th>
                  <td>
                    means any commercial, financial or technical information,
                    information relating to the Services, plans, course content,
                    course structure, training techniques, know-how or trade
                    secrets which is obviously confidential in nature or has
                    been identified as confidential, or which is developed by a
                    party in performing its obligations under, or otherwise
                    pursuant to the Contract;
                  </td>
                </tr>
                <tr>
                  <th>Contract</th>
                  <td>
                    means the agreement between the Supplier and the Customer
                    for the supply and purchase of Services incorporating these
                    Conditions and including any other terms incorporated by
                    reference of agreed between the Supplier and the Customer in
                    writing;
                  </td>
                </tr>
                <tr>
                  <th>Control</th>
                  <td>
                    means the beneficial ownership of more than 50% of the
                    issued share capital of a company or the legal power to
                    direct or cause the direction of the management of the
                    company and <b>Controls, Controlled</b> Controls and{" "}
                    <b>under common Control</b> shall be construed accordingly;
                  </td>
                </tr>
                <tr>
                  <th>Controller</th>
                  <td>
                    shall have the meaning given to it in applicable Data
                    Protection Laws from time to time;
                  </td>
                </tr>
                <tr>
                  <th>Customer</th>
                  <td>
                    means the healthcare provider who has agreed to purchase the
                    Services from the Supplier in accordance with the terms and
                    conditions set forth herein;
                  </td>
                </tr>
                <tr>
                  <th>Data Protection Laws</th>
                  <td>
                    means, as binding on either party or the Services:
                    <ul>
                      <li>the GDPR;</li>
                      <li>the UK GDPR;</li>
                      <li>the Data Protection Act 2018;</li>
                      <li>
                        t h e P r i v a c y a n d E l e c t r o n i c
                        Communications ( EC Directive) Regulations 2003, SI
                        2003/2426 and any laws or regulations implementing such
                        directive;{" "}
                      </li>
                      <li>
                        the Data Protection, Privacy and E l e c t r o n i c C o
                        m m u n i c a t i o n s ( A m e n d m e n t s e t c ) (
                        E U E x i t ) Regulations 2019; and
                      </li>
                      <li>
                        the Data Protection, Privacy and E l e c t r o n i c C o
                        m m u n i c a t i o n s ( A m e n d m e n t s e t c ) (
                        E U E x i t ) Regulations 2019; and
                      </li>
                      <li>
                        any laws that replace, extend, re- enact, consolidate or
                        amend any of the foregoing;
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Data Protection Supervisory Authority</th>
                  <td>
                    means any regulator, authority or body responsible for
                    administering Data Protection Laws;
                  </td>
                </tr>
                <tr>
                  <th>Data Subject</th>
                  <td>
                    shall have the meaning given to it in applicable Data
                    Protection Laws from time to time;
                  </td>
                </tr>
                <tr>
                  <th>Deactivated User</th>
                  <td>
                    means any User that is deactivated from the Platform which
                    means they are permanently removed from the Platform
                  </td>
                </tr>
                <tr>
                  <th>Documentation</th>
                  <td>
                    means any course materials, downloads, descriptions,
                    instructions, manuals, literature, technical details or
                    other related materials supplied in connection with the
                    Services;
                  </td>
                </tr>
                <tr>
                  <th>Force Majeure</th>
                  <td>
                    means an event or sequence of events beyond a party’s
                    reasonable control (after exercise of reasonable care to put
                    in place robust back-up and disaster recovery arrangements)
                    preventing or delaying it from performing its obligations
                    under the Contract including an act of God, fire, flood,
                    lightning, earthquake or other natural disaster, war, riot
                    or civil unrest, interruption or failure of supplies of
                    power, fuel, w a t e r , t r a n s p o r t , e q u i p m e n
                    t o r telecommunications service, or material required for
                    performance of the Contract, strike, lockout or boycott or
                    other industrial action including those involving the
                    Supplier’s or its suppliers’ workforce, but excluding the
                    Customer’s inability to pay or circumstances resulting in
                    the Customer’s inability to pay;
                  </td>
                </tr>
                <tr>
                  <th>GDPR </th>
                  <td>means the General Data Protection Regulation,</td>
                </tr>
                <tr>
                  <th>Intellectual Property Rights</th>
                  <td>
                    means copyright, patents, know-how, trade secrets, trade
                    marks, trade names, design rights, rights in get-up, rights
                    in goodwill, rights in software, rights in Confidential
                    Information, rights to invention, rights to sue for passing
                    off, domain names and all other intellectual property rights
                    and similar rights and, in each case:
                    <ul>
                      <li>whether registered or not;</li>
                      <li>
                        including any applications to protect or register such
                        rights;{" "}
                      </li>
                      <li>
                        including all renewals and extensions of such rights or
                        applications;
                      </li>
                      <li>whether vested, contingent or future;</li>
                      <li>
                        to which the relevant party is or may be entitled, and
                      </li>
                      <li>in whichever part of the world existing;</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>International Organisation</th>
                  <td>
                    shall have the meaning given to it in applicableData
                    Protection Laws from time to time;
                  </td>
                </tr>
                <tr>
                  <th>IPR Claim</th>
                  <td>has the meaning given in clause 14.1;</td>
                </tr>
                <tr>
                  <th>Location</th>
                  <td>
                    means the physical address or addresses for performance of
                    the Services, if relevant, as agreed between the Supplier
                    and the Customer in writing;
                  </td>
                </tr>
                <tr>
                  <th>Modern Slavery Policy</th>
                  <td>
                    means, if applicable, the Supplier’s anti-slavery and human
                    trafficking policy in force and notified to the Customer
                    from time to time;
                  </td>
                </tr>
                <tr>
                  <th>MSA Offence</th>
                  <td>has the meaning given in clause 10.2.1;</td>
                </tr>
                <tr>
                  <th>Paused User</th>
                  <td>
                    means a User whose access to receive Services and/or
                    Documentation through the Platform has been suspended for
                    any reason by the Customer, usually on a temporary basis;
                  </td>
                </tr>
                <tr>
                  <th>Personal Data</th>
                  <td>
                    shall have the meaning given to it in applicable Data
                    Protection Laws from time to time;
                  </td>
                </tr>
                <tr>
                  <th>Personal Data Breach</th>
                  <td>
                    shall have the meaning given to it in applicable Data
                    Protection Laws from time to time;
                  </td>
                </tr>
                <tr>
                  <th>Platform</th>
                  <td>
                    means the web based platform available at{" "}
                    <a href="https://saunderstrainingacademy.co.uk">
                      https://saunderstrainingacademy.co.uk
                    </a>
                    , through which the Services will be provided by the
                    Supplier;
                  </td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>has the meaning set out in clause 4.1;</td>
                </tr>
                <tr>
                  <th>processing</th>
                  <td>
                    has the meaning given to it in applicable Data Protection
                    Laws from time to time (and related expressions, including{" "}
                    <b>process, processing, processed,</b> and <b>processes </b>
                    shall be construed accordingly);
                  </td>
                </tr>
                <tr>
                  <th>Processor</th>
                  <td>
                    shall have the meaning given to it in applicable Data
                    Protection Laws from time to time;
                  </td>
                </tr>
                <tr>
                  <th>Protected Data</th>
                  <td>
                    means Personal Data received from or on behalf of the
                    Customer in connection with the performance of the
                    Supplier’s obligations under the Contract;
                  </td>
                </tr>
                <tr>
                  <th>Services</th>
                  <td>
                    means in the case of Saunders Training Academy, the
                    e-learning and in-person training courses provided by or on
                    behalf of the Supplier;
                  </td>
                </tr>
                <tr>
                  <th>Sub-Processor</th>
                  <td>
                    means any agent, sub-contractor or other third party
                    (excluding its employees) engaged by the Supplier for
                    carrying out any processing activities on behalf of the
                    Customer in respect of the Protected Data;
                  </td>
                </tr>
                <tr>
                  <th>Supplier</th>
                  <td>means Saunders Training Academy;</td>
                </tr>
                <tr>
                  <th>Supplier Personnel</th>
                  <td>
                    means all employees, officers, staff, other workers, agents
                    and consultants of the Supplier, its Affiliates and any of
                    their sub- contractors who are engaged in the performance of
                    the Services from time to time; and
                  </td>
                </tr>
                <tr>
                  <th>User</th>
                  <td>
                    means any staff member, worker, employee or officer of the
                    Customer, without limitation, whomay be granted access to
                    the Platform by the Customer
                  </td>
                </tr>
                <tr>
                  <th>UK GDPR</th>
                  <td>
                    means the General Data Protection Regulation, Regulation
                    (EU) 2016/679 as it forms part of domestic law in the United
                    Kingdom by virtue of section 3 of the European Union
                    (Withdrawal) Act 2018 (including as further amended or
                    modified by the laws of the United Kingdom or a part of the
                    United Kingdom from time to time);
                  </td>
                </tr>
                <tr>
                  <th>VAT</th>
                  <td>
                    means value added tax under the Value Added Taxes Act 1994
                    or any other similar sale or fiscal tax applying to the sale
                    of the Services.
                  </td>
                </tr>
              </table>
            </div>
            <ul>
              <li>
                In these Conditions, unless the context requires otherwise:
              </li>
              <br />
              <ul class="a">
                <li>
                  any clause, schedule or other headings in these Conditions is
                  included for convenience only and shall have no effect on the
                  interpretation of the Conditions;
                </li>
                <br />
                <li>
                  a reference to a ‘party’ includes that party’s personal
                  representatives, successors and permitted assigns;
                </li>
                <br />
                <li>
                  a reference to a ‘person’ includes a natural person, corporate
                  or unincorporated body (in each case whether or not having
                  separate legal personality) and that person’s personal
                  representatives, successors and permitted assigns;
                </li>
                <br />
                <li>
                  {" "}
                  a reference to a ‘company’ includes any company, corporation
                  or other body corporate, wherever and however incorporated or
                  established;
                </li>
                <br />
                <li> a reference to a gender includes each other gender;</li>
                <br />
                <li>
                  {" "}
                  words in the singular include the plural and vice versa;
                </li>
                <br />
                <li>
                  {" "}
                  any words that follow ‘include’, ‘includes’, ‘including’, ‘in
                  particular’ or any similar words and expressions shall be
                  construed as illustrative only and shall not limit the sense
                  of any word, phrase, term, definition or description preceding
                  those words;
                </li>
                <br />
                <li>
                  a reference to ‘writing’ or ‘written’ includes any method of
                  reproducing words in a legible and non-transitory form
                  (including email);
                </li>
                <br />
                <li>
                  {" "}
                  a reference to any English action, remedy, method of judicial
                  proceeding, court, official, legal document, legal status,
                  legal doctrine, legal concept or thing shall, in respect of
                  any jurisdiction other than Northern Ireland, be deemed to
                  include a reference to that which most nearly approximates to
                  the English equivalent in that jurisdiction.
                </li>
                <br />
              </ul>
            </ul>
            <ul>
              <li>
                <b>Application of these conditions</b>
              </li>{" "}
              <br />
              <li>
                {" "}
                By accessing or using the Platform in any manner the Customer is
                deemed to have accepted these Conditions in full without
                amendment. These Conditions apply to and form part of the
                Contract between the Supplier and the Customer. They supersede
                any previously issued terms and conditions of purchase or
                supply.{" "}
              </li>
              <br />
              <li>
                {" "}
                No terms or conditions endorsed on, delivered with, or contained
                in the Customer’s purchase conditions, order, confirmation of
                order, specification or other document shall form part of the
                Contract except to the extent that the Supplier otherwise agrees
                in writing.
              </li>
              <br />
              <li>
                {" "}
                We may update and change these Conditions from time to time and
                we recommend the Customer to check regularly for updates. We
                will try to give the Customer reasonable notice of any major
                changes and by continuing to use the Services the Customer will
                have been deemed to accept any changes. Updates will be binding
                regardless of whether notice has been given or received.
              </li>
              <br />
              <li>
                {" "}
                In the event that the Customer has agreed to any fixed contract
                period for receipt of the Services, at the end of any such
                contract period these Conditions shall renew automatically for
                the same period and on the same terms unless the Customer gives
                written notice of the Customer’s intention to terminate these
                Conditions. Such written notice must be delivered to the
                Supplier no less than 60 days in advance of the then current
                contract period. In all other cases (and always subject to any
                other termination provisions set forth herein) the Customer
                shall be required to give 6 months advance written notice in
                order to terminate these Conditions.
              </li>
              <br />
              <li>
                {" "}
                Marketing and other promotional material relating to the
                Services are illustrative only and do not form part of these
                Conditions.
              </li>
              <br />
              <li>
                {" "}
                These Conditions shall incorporate the Supplier’s Acceptable Use
                Policy.
              </li>{" "}
              <br />
              <br />
              <li>
                <b> Signing up to and using the Platform</b>
              </li>{" "}
              <br />
              <br />
              <li>
                {" "}
                The Customer must activate their account on the Platform before
                any Services can be accessed by the Customer or any User. The
                Customer may activate an account at{" "}
                <a href="https://saunderstrainingacademy.co.uk.">
                  {" "}
                  https://saunderstrainingacademy.co.uk.
                </a>
              </li>
              <br />
              <li>
                {" "}
                The Customer shall be solely responsible for adding Users,
                although the Supplier may help with bulk uploads, to the
                Platform and the Customer represents and warrants that the
                Customer has obtained each Users consent in compliance with all
                Data Protection Laws.
              </li>
              <br />
              <li>
                {" "}
                Once activated, the Customer and Users can use the Platform to
                search for, assign and carry out training and tasks relevant to
                their own professional development.
              </li>
              <br />
              <li>
                {" "}
                Subject to continued compliance with the Contract, the Customer
                and Users shall have access to a messaging area on the Platform.
                The Supplier does not routinely monitor any messages sent and
                received on the Platform and will only use the messaging area to
                send reminders or help guidance relevant to each User’s use of
                the Platform. The Supplier shall at no time use the messaging
                service on the Platform for marketing.
              </li>
              <br />
              <li>
                {" "}
                All Users will be Active Users unless the Customer notifies the
                Supplier that any user is a Paused User or a Deactivated User.
                The Customer can do this within the products.
              </li>
              <br />
              <li>
                {" "}
                The Platform is compatible with the majority of modern devices
                and modern operating systems and, subject to the following, the
                Customer shall provide any hardware or software necessary for
                the Platform to function.
              </li>
              <br />
              <br />
              <li>
                <b> Price </b>
              </li>
              <br />
              <br />
              <li>
                {" "}
                The price for the Services shall be calculated in accordance
                with the Supplier’s scale of charges in force from time to time
                (the <b>Price</b>). The Supplier reserves the right to determine
                which elements of the Services are included within the Price as
                standard, and which elements of the Services constitute premium
                offerings for which additional fees may be levied. In the event
                that the Customer is affected by any exercise of the Supplier’s
                discretion in this regard, any changes to the Price shall take
                effect one month following the date that the Supplier notified
                the Customer of the changes.
              </li>
              <br />
              <li>
                ⦁ The Price for the Saunders Training Academy Platform is
                currently calculated by reference to the highest number of
                Active Users registered on the Platform by the Customer in any
                billing period, subject always to any additional course content
                services that the Customer may require. The current charges are
                available separately from the Supplier.
              </li>
              <br />
              <li>
                {" "}
                The Price for the Florence Rota Platform is subject to varying
                tiers depending on the service purchased. Special pricing terms
                may be available on request.
              </li>
              <br />
              <li>
                {" "}
                The Customer shall be solely liable for notifying the Supplier
                through the Platform in the event that any Users become Paused
                Users or Deactivated Users. Any failure by the Customer to
                provide such notification to the Supplier shall result in the
                Price being charged in accordance with these Conditions.
              </li>
              <br />
              <li>
                {" "}
                The Price and access to the Platform shall be subject to fair
                usage restrictions in order to protect the reasonable commercial
                interests of the Supplier. Fair usage shall be determined in
                good faith by the Supplier and notified to the Customer from
                time to time. In determining fair usage, the Supplier shall have
                regard for the number of Active Users, the number of days or
                months that any User has been registered as a Paused User or a
                Deactivated User, and the volume of Services and Documentation
                received by Active Users and the activity levels over any
                relevant time frame. Any determination of fair use may result in
                an increase in the Price or reasonable restrictions placed on
                any one or more Users at the discretion of the Supplier in the
                event that the Customer is determined to be using the Platform
                in a manner that may cause commercial losses to the Supplier.
              </li>
              <br />
              <li> The Price is exclusive of VAT (or equivalent sales tax).</li>
              <br />
              <li>
                {" "}
                The Customer shall pay any applicable VAT to the Supplier on
                receipt of a valid VAT invoice.
              </li>
              <br />
              <li>
                {" "}
                The Supplier may increase the Price at any time by giving the
                Customer not less than 14 days’ notice in writing.
              </li>
              <br />
              <li>
                {" "}
                Notwithstanding clause 4.8, the Supplier may increase the Price
                with immediate effect by written notice to the Customer where
                there is an increase in the direct cost to the Supplier of
                supplying the relevant Services.
              </li>
              <br /> <br />
              <li>
                <b> Payment </b>
              </li>
              <br />
              <br />
              <li>
                {" "}
                The Supplier shall invoice the Customer for the Services on a
                monthly or annual basis at the option of the Customer.
              </li>
              <br />
              <li>
                {" "}
                The Customer shall pay all invoices:
                <ul class="a">
                  <li>
                    {" "}
                    in full without deduction or set-off, in cleared funds
                    within 14 days of the date of each invoice; and
                  </li>
                  <br />
                  <li> to the bank account nominated by the Supplier.</li>
                  <br />
                </ul>
              </li>
              <li>
                Time of payment is of the essence. Where sums due under these
                Conditions are not paid in full by the due date:
                <ul class="a">
                  <li>
                    {" "}
                    the Supplier may, without limiting its other rights, charge
                    interest on such sums at 4% a year above the base rate of
                    Barclays Bank plc from time to time in force;
                  </li>
                  <br />
                  <li>
                    {" "}
                    suspend Users or block the Customer from using the Service;
                    and
                  </li>
                  <br />
                  <li>
                    {" "}
                    levy interest on a daily basis, and apply such interest from
                    the due date for payment until actual payment in full,
                    whether before or after judgment.
                  </li>
                  <br />
                </ul>
              </li>
              <br />
              <li>
                {" "}
                <b>Credit limit</b>
                <br />
                <ul class="a">
                  <li>
                    The Supplier may set and vary credit limits from time to
                    time and withhold all further supplies if the Customer
                    exceeds such credit limit.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <b> Performance </b>
                <br />
                <li>
                  {" "}
                  The Services shall be performed by the Supplier through the
                  Platform and at any agreed Location on the dates agreed
                  between the Supplier and the Customer.
                </li>
                <br />
                <li>
                  The Supplier shall use its reasonable endeavours to meet
                  estimated dates for performance, but any such dates are
                  indicative only unless otherwise explicitly agreed in writing.
                </li>
                <br />
                <li>
                  {" "}
                  Where a Location has been agreed for the provision of any
                  Services, the Supplier shall not be liable for any delay in or
                  failure of performance caused by:
                </li>
                <br />
                <ul class="a">
                  <li>
                    {" "}
                    the Customer’s failure to make the Location available;
                  </li>
                  <br />
                  <li> the Customer’s failure to prepare the Location;</li>
                  <br />
                  <li>
                    {" "}
                    the Customer’s failure to provide the Supplier with adequate
                    instructions for performance;
                  </li>
                  <br />
                  <li> Force Majeure</li>
                  <br />
                </ul>
              </li>
              <li>
                <b> Warranty </b>
              </li>
              <br />
              <li>
                {" "}
                The Supplier warrants that, at the time of performance, the
                Services shall: <br />
                <ul class="a">
                  <li>
                    {" "}
                    conform in all material respects to their description;{" "}
                  </li>
                  <br />
                  <li> be free from material defects;</li>
                  <br />
                  <li>
                    {" "}
                    be supplied with reasonable care and skill within the
                    meaning of the Supply of Goods and Services Act 1982, Part
                    II s 13; and
                  </li>
                  <br />
                  <li>
                    {" "}
                    in the case of media on which the results of the Services
                    are supplied, be of satisfactory quality within the meaning
                    of the Sale of Goods Act 1979.
                  </li>
                  <br />
                </ul>
              </li>
              <li>
                {" "}
                As the Customer’s sole and exclusive remedy, the Supplier shall,
                at its option, remedy, re- perform or refund the Services that
                do not comply with clause 8.1, provided that: <br />
                <ul class="a">
                  <li>
                    {" "}
                    the Customer serves a written notice on the Supplier not
                    later than five Business Days from performance in the case
                    of defects discoverable by a physical inspection, or within
                    a reasonable period of time from performance in the case of
                    latent defects; and
                  </li>
                  <br />
                  <li>
                    {" "}
                    such notice specifies that some or all of the Services do
                    not comply with clause 8.1 and identifies in sufficient
                    detail the nature and extent of the defects; and
                  </li>
                  <br />
                  <li>
                    {" "}
                    the Customer gives the Supplier a reasonable opportunity to
                    examine the claim of the defective Services.
                  </li>
                  <br />
                </ul>
                <li>
                  {" "}
                  The provisions of these Conditions shall apply to any Services
                  that are remedied or re- performed with effect from
                  performance of the remedied or re-performed Services.
                </li>
                <br />
                <li>
                  {" "}
                  Except as set out in this clause 8:
                  <ul class="a">
                    <li>
                      {" "}
                      the Supplier gives no warranties and makes no
                      representations in relation to the Services; and
                    </li>
                    <br />
                    <li>
                      {" "}
                      shall have no liability for their failure to comply with
                      the warranty in clause 8.1 in excess of the liability set
                      forth in clause 8.2,
                    </li>
                    <br />
                    <li>
                      and all warranties and conditions (including the
                      conditions implied by ss 12–16 of the Supply of Goods and
                      Services Act 1982), whether express or implied by statute,
                      common law or otherwise are excluded to the extent
                      permitted by law.
                    </li>
                    <br />
                  </ul>
                </li>
                <br />
                <li>
                  <b> Anti-bribery </b>
                </li>
                <br />
                <li>
                  For the purposes of this clause 9 the expressions{" "}
                  <b>‘adequate procedures’</b> and <b>‘associated with’</b>shall
                  be construed in accordance with the Bribery Act 2010 and
                  legislation or guidance published under it.
                </li>
                <br />
                <li>
                  {" "}
                  Each party shall comply with applicable Bribery Laws including
                  ensuring that it has in place adequate procedures to prevent
                  bribery and use all reasonable endeavours to ensure that:
                </li>
                <br />
                <ul class="a">
                  <li> all of that party’s personnel;</li>
                  <br />
                  <li> all others associated with that party; and</li>
                  <br />
                  <li>all of that party’s sub-contractors;</li>
                  <br />
                  <li>involved in performing the Contract so comply.</li>
                  <br />
                </ul>
                <br />
                <li>
                  Without limitation to clause 9.2, neither party shall make or
                  receive any bribe (as defined in the Bribery Act 2010) or
                  other improper payment, or allow any such to be made or
                  received on its behalf, either in the United Kingdom or
                  elsewhere, and shall implement and maintain adequate
                  procedures to ensure that such bribes or payments are not made
                  or received directly or indirectly on its behalf.
                </li>
                <br />
                <li>
                  The Customer shall immediately notify the Supplier as soon as
                  it becomes aware of a breach or possible breach by the
                  Customer of any of the requirements in this clause 9.
                </li>
                <br />
                <li>
                  {" "}
                  Any breach of this clause 9 by the Customer shall be deemed a
                  material breach of the Contract that is not remediable and
                  shall entitle the Supplier to immediately terminate the
                  Contract by notice under clause 20.1.1.
                </li>
                <br />
                <li>
                  <b>Anti-slavery</b>
                </li>
                <br />
                <li>
                  {" "}
                  The Supplier shall comply with the Modern Slavery Act 2015
                  and, where applicable, any Modern Slavery Policy.
                </li>
                <br />
                <li>
                  {" "}
                  The Customer undertakes, warrants and represents that: <br />
                  <ul class="a">
                    <li>
                      {" "}
                      neither the Customer nor any of its officers, employees,
                      agents or subcontractors has:
                    </li>
                    <br />
                    <ul class="a">
                      <li>
                        committed an offence under the Modern Slavery Act 2015
                        (an <b>MSA Offence</b>); or
                      </li>
                      <br />
                      <li>
                        {" "}
                        been notified that it is subject to an investigation
                        relating to an alleged MSA Offence or prosecution under
                        the Modern Slavery Act 2015; or
                      </li>
                      <br />
                      <li>
                        is aware of any circumstances within its supply chain
                        that could give rise to an investigation relating to an
                        alleged MSA Offence or prosecution under the Modern
                        Slavery Act 2015;
                      </li>
                    </ul>
                    <li>
                      {" "}
                      it shall comply with the Modern Slavery Act 2015 and any
                      Modern Slavery Policy; and
                    </li>
                    <br />
                    <li>
                      {" "}
                      it has implemented due diligence procedures to ensure
                      compliance with the Modern Slavery Act 2015 and any Modern
                      Slavery Policy in its business and supply chain, and those
                      of its officers, employees, agents or subcontractors,
                      which will be made available to the Supplier on request at
                      any time throughout the Contract.
                    </li>
                    <br />
                  </ul>
                  <li>
                    The Customer shall notify the Supplier immediately in
                    writing if it becomes aware or has reason to believe that
                    it, or any of its officers, employees, agents or
                    subcontractors have breached or potentially breached any of
                    the Customer’s obligations under clause 10.2. Such notice to
                    set out full details of the circumstances concerning the
                    breach or potential breach of the Customer’s obligations.
                  </li>
                  <br />
                  <li>
                    Any breach of clause 10.2 by the Customer shall be deemed a
                    material breach of the Contract and shall entitle the
                    Supplier to terminate the Contract with immediate effect.
                  </li>
                  <br />
                </li>
                <br />
                <li>
                  <b>Indemnity and insurance</b>
                </li>
                <li>
                  The Customer shall indemnify, and keep indemnified, the
                  Supplier from and against any losses, damages, liability,
                  costs (including legal fees) and expenses incurred by the
                  Supplier as a result of or in connection with the Customer’s
                  breach of any of the Customer’s obligations under the
                  Contract.
                </li>
                <br />
                <li>
                  The Customer shall have in place contracts of insurance with
                  reputable insurers incorporated in the United Kingdom or
                  another country commonly held by prudent operators in the
                  business of the Customer, and sufficient to cover its
                  obligations under these Conditions, including but not limited
                  to cyber risks and breaches of the GDPR. On request, the
                  Customer shall supply, so far as is reasonable, evidence of
                  the maintenance of the insurance and all of its terms from
                  time to time applicable. The Customer shall on request assign
                  to the Supplier the benefit of such insurance.
                </li>
                <br />
                <li>
                  <b>Limitation of liability</b>
                </li>
                <br />
                <li>
                  The extent of the parties’ liability under or in connection
                  with the Contract (regardless of whether such liability arises
                  in tort, contract or in any other way and whether or not
                  caused by negligence or misrepresentation) shall be as set out
                  in this clause 12.
                </li>
                <br />
                <li>
                  Subject to clauses 12.5 and 12.6, the Supplier’s total
                  liability shall not exceed the amounts paid to the Supplier by
                  the Customer in the 12 months preceding any claim.
                </li>
                <br />
                <li>
                  Subject to clauses 12.5 and 12.6, the Supplier shall not be
                  liable for consequential, indirect or special losses.
                </li>
                <br />
                <li>
                  Subject to clauses 12.5 and 12.6, the Supplier shall not be
                  liable for any of the following (whether direct or indirect):
                </li>
                <br />
                <li>
                  loss of profit;
                  <ul class="a">
                    <li>loss or corruption of data;</li> <br />
                    <li> loss of use;</li>
                    <br />
                    <li> loss of production;</li>
                    <br />
                    <li> loss of contract</li>
                    <br />
                    <li> loss of opportunity</li>
                    <br />
                    <li>
                      {" "}
                      loss of savings, discount or rebate (whether actual or
                      anticipated);
                    </li>
                    <br />
                    <li> harm to reputation or loss of goodwill</li>
                    <br />
                  </ul>
                  <li>
                    The limitations of liability set out in clauses 12.2 to 12.4
                    shall not apply in respect of any indemnities given by the
                    Customer under the Contract.
                  </li>
                  <br />
                  <li>
                    Notwithstanding any other provision of the Contract, the
                    liability of the parties shall not be limited in any way in
                    respect of the following:
                  </li>
                  <br />
                  <ul class="a">
                    <li>death or personal injury caused by negligence; </li>
                    <br />
                    <li> fraud or fraudulent misrepresentation;</li>
                    <br />
                    <li>
                      any other losses which cannot be excluded or limited by
                      Applicable Law;
                    </li>
                    <br />
                    <li>any losses caused by wilful misconduct</li>
                    <br />
                  </ul>
                </li>
                <br />
                <li>
                  <b>Platform content </b>
                </li>
                <li>
                  As between the parties hereto, the Supplier is the owner or
                  the licensee of all intellectual property rights in and to the
                  Platform and the Documentation, unless any Documentation was
                  created solely by or on behalf of the Customer in accordance
                  with the provisions of clauses 13.11 to 13.13. Any works
                  created, licenced, controlled or used by the Supplier are
                  protected by copyright laws and treaties around the world. All
                  such rights are reserved.
                </li>
                <br />
                <li>
                  {" "}
                  In the event that the Customer creates or commissions the
                  creation of Documentation, any data generated from the use of
                  such Documentation on the Platform shall be the sole property
                  of the Supplier, who shall own all related Intellectual
                  Property Rights in that regard.
                </li>
                <br />
                <li>
                  The Customer and any Active User may print off any number of
                  copies, and may download extracts, of the Documentation for
                  personal use.
                </li>
                <br />
                <li>
                  The Customer and any User must not modify the paper or digital
                  copies of any Documentation, and neither the Customer nor any
                  User has permission to use any illustrations, photographs,
                  video or audio sequences or any graphics comprising the
                  Platform or the Documentation, unless such Documentation has
                  been created in accordance with the provisions of clauses
                  13.11 to 13.13.
                </li>
                <br />
                <li>
                  The Customer and any User agree to acknowledge the Supplier’s
                  status (and that of any identified contributors) as the
                  authors, owner or licensee of the Platform or the
                  Documentation.
                </li>
                <br />
                <li>
                  The Customer and any User must not use any part of the
                  Platform or the Documentation for commercial purposes save for
                  the intended use of the Platform as a learning resource for
                  active Users. No Customer or User shall charge any third party
                  a fee for use of the Platform.
                </li>
                <br />
                <li>
                  If the Customer and any User prints-off, copies or downloads
                  any part of the Platform or the Documentation in breach of
                  these Conditions, any right to use the Platform will cease
                  immediately and the Customer must, or must procure, the return
                  or destruction of any copies of the Platform or the
                  Documentation.
                </li>
                <br />
                <li>
                  Whilst the Supplier will use reasonable endeavours to ensure
                  that the Documentation and the Platform is accurate and
                  up-to-date, the Supplier does not warrant the accuracy or
                  completeness of the Documentation or the Platform which is
                  provided "as available" and "as is" with no express or implied
                  warranty for availability, accessibility, accuracy or
                  completeness and without any other representations,
                  warranties, conditions or other terms of any kind.
                </li>
                <br />
                <li>
                  The Supplier may update and change the Platform from time to
                  time. The Supplier will try to give the Customer reasonable
                  notice of any major changes and by continuing to use the
                  Platform the Customer will have been deemed to accept any
                  changes.
                </li>
                <br />
                <li>
                  {" "}
                  The Supplier does not guarantee that the Platform, or any
                  content on it, including the Documentation, will always be
                  available or be uninterrupted. The Supplier may suspend or
                  withdraw or restrict the availability of all or any part of
                  the Platform for business, technical or operational reasons.
                  The Supplier will try to give the Customer reasonable notice
                  of any planned suspension or withdrawal of the Platform or any
                  part of it. The Customer agrees that the Supplier will not be
                  liable to the Customer or any User or to any third party for
                  any unavailability, modification, suspension or withdrawal of
                  the Platform, or any features, parts or content of the
                  Platform, including the Documentation.
                </li>
                <br />
                <li>
                  {" "}
                  The Customer may be permitted to create their own content to
                  make available to all Active Users using the functionality of
                  the Platform by sending any such content to the Supplier in
                  such formats as the Supplier may notify to the Customer.
                </li>
                <br />
                <li>
                  The Customer may be permitted to create private content to be
                  made available to a select and closed group of Active Users.
                  Before any such permission may be granted by the Supplier, the
                  Supplier and the Customer shall agree formal written terms
                  including as to any additional fees payable.
                </li>
                <br />
                <li>
                  The Supplier can prepare and develop bespoke course materials
                  in accordance with the Customer’s explicit written
                  instructions. Such additional services shall be the subject of
                  a separate agreement and the payment of additional fees. The
                  Supplier’s current fee for the preparation of bespoke course
                  materials is available from the Supplier on request.
                </li>
                <br />
                <li>
                  <b>Intellectual property claims</b>
                </li>
                <br />
                <li>
                  Subject to the provisions of clause 14.5 the Supplier shall
                  indemnify the Customer from and against any losses, damages,
                  liability, costs and expenses (including reasonable
                  professional fees) incurred by it as a result of any action,
                  demand or claim that performance of benefit of the Services
                  infringes the Intellectual Property Rights of any third party
                  (<b>IPR Claim</b>), provided that the Supplier shall have no
                  such liability if the Customer:
                </li>
                <br />
                <ul class="a">
                  <li>
                    does not notify the Supplier in writing setting out full
                    details of any IPR Claim of which it has notice as soon as
                    is reasonably possible;
                  </li>
                  <br />
                  <li>
                    {" "}
                    makes any admission of liability or agrees any settlement or
                    compromise of the relevant IPR Claim without the prior
                    written consent of the Supplier;
                  </li>
                  <br />
                  <li>
                    {" "}
                    does not let the Supplier at its request and own expense
                    have the conduct of or settle all negotiations and
                    litigation arising from the IPR Claim at its sole
                    discretion;
                  </li>
                  <br />
                  <li>
                    {" "}
                    does not take all reasonable steps to minimise the losses
                    that may be incurred by it or by any third party as a result
                    of the IPR Claim;
                  </li>
                  <br />
                  <li>
                    {" "}
                    does not, at the Supplier’s request, provide the Supplier
                    with all reasonable assistance in relation to the IPR Claim
                    (at the Customer’s expense) including the provision of
                    prompt access to any relevant premises, officers, employees,
                    contractors or agents of the Customer;
                  </li>
                  <br />
                  <li>
                    {" "}
                    uses the Services in combination with any other goods or
                    services, which without such combination, no IPR Claim could
                    or would have been made.
                  </li>
                  <br />
                </ul>
                <li>
                  {" "}
                  If any IPR Claim is made or is reasonably likely to be made,
                  the Supplier may at its option:
                </li>
                <br />
                <ul class="a">
                  <li>
                    procure for the Customer the right to continue receiving the
                    benefit of the relevant Services; or
                  </li>
                  <br />
                  <li>
                    modify or replace the infringing part of the Services so as
                    to avoid the infringement or alleged infringement, provided
                    the Services remain in material conformance to their
                    description.
                  </li>
                  <br />
                </ul>
                <li>
                  The Supplier’s obligations under clause 14.1 shall not apply
                  to Services modified or used by the Customer other than in
                  accordance with the Contract or the Supplier’s instructions.
                  The Customer shall indemnify the Supplier against all losses,
                  damages, liability, costs and expenses
                  <p>
                    (including reasonable legal fees) incurred by the Supplier
                    in connection with any claim arising from such modification
                    or use.
                  </p>
                </li>
                <br />
                <li>
                  The Supplier’s obligations under clause 14.1 shall not apply
                  to any part of the Documentation created by or on behalf of
                  the Customer
                </li>
                <br />
                <li>
                  To the extent that the Customer created or commissioned the
                  creation of any part of the Documentation, the Customer
                  represents and warrants that it owns, controls or has licenced
                  all Intellectual Property Rights necessary for the use of such
                  Documentation on the Platform. The Customer shall indemnify
                  the Supplier from and against any losses, damages, liability,
                  costs and expenses (including reasonable professional fees)
                  incurred by the Supplier as a result of any action, demand or
                  claim that any such Documentation infringes the Intellectual
                  Property Rights of any third party.
                </li>
                <br />
                <li>
                  <b> Viruses </b>
                </li>
                <br />
                <li>
                  {" "}
                  The Supplier does not guarantee that the Platform will be
                  secure or free from bugs or viruses. The Customer agrees that
                  we are not liable for any bugs or viruses on the Platform
                </li>
                <br />
                <li>
                  The Customer is responsible for configuring its information
                  technology, computer programmes and platform to access the
                  Platform. The Customer agrees to use its own virus protection
                  software.
                </li>
                <br />
                <li>
                  <b>Website links on the Platform</b>
                </li>
                <p>
                  Where the Platform contains links to other sites and resources
                  provided by third parties, these links are provided for
                  information only. Such links should not be interpreted as
                  approval by the Supplier of those linked websites or
                  information may be obtained from them. The Supplier has no
                  control over the contents of those sites or resources. The
                  Supplier suggests that the Customer reads the terms of use and
                  privacy notices of such sites at the time of visiting such
                  sites.
                </p>
                <li>
                  <b>Confidentiality and announcements</b>
                </li>
                <br />
                <li>
                  The Customer shall keep confidential all Confidential
                  Information of the Supplier and of its Affiliates and shall
                  only use the same as required to perform the Contract. The
                  provisions of this clause shall not apply to:
                </li>
                <br />
                <ul class="a">
                  <li>
                    any information which was in the public domain at the date
                    of the Contract;
                  </li>
                  <br />
                  <li>
                    any information which comes into the public domain
                    subsequently other than as a consequence of any breach of
                    the Contract or any related agreement;
                  </li>
                  <br />
                  <li>
                    any information which is independently developed by the
                    Customer without using information supplied by the Supplier
                    or by any Affiliate of the Supplier; or
                  </li>
                  <br />
                  <li>
                    any disclosure required by law or a regulatory authority or
                    otherwise by the provisions of the Contract
                  </li>
                  <br />
                  <p>
                    except that the provisions of clauses 17.1.1 to 17.1.3 shall
                    not apply to information to which clause 17.4 relates.
                  </p>
                </ul>
                <li>
                  This clause shall remain in force for a period of five years
                  from the date of the Contract and, if longer, three years
                  after termination of the Contract.
                </li>
                <br />
                <li>
                  The Customer shall not make any public announcement or
                  disclose any information regarding the Contract, except to the
                  extent required by law or regulatory authority.
                </li>
                <br />
                <li>
                  To the extent any Confidential Information is Protected Data
                  (as defined in clause 18) such Confidential Information may be
                  disclosed or used only to the extent such disclosure or use is
                  in compliance with and does not conflict with any of the
                  provisions of clause 18.1.
                </li>
                <br />
                <li>
                  <b>Processing of personal data</b>
                </li>
                <br />
                <li>
                  The parties agree that the Customer is a Controller and that
                  the Supplier is a Processor for the purposes of processing
                  Protected Data pursuant to the Contract. The Customer shall at
                  all times comply with all Data Protection Laws in connection
                  with the processing of Protected Data. The Customer shall
                  ensure all instructions given by it to the Supplier in respect
                  of Protected Data (including the terms of the Contract) shall
                  at all times be in accordance with Data Protection Laws.
                  Nothing in the Contract relieves the Customer of any
                  responsibilities or liabilities under any Data Protection
                  Laws.
                </li>
                <br />
                <li>
                  The Supplier shall process Protected Data in compliance with
                  the obligations placed on it under Data Protection Laws and
                  the terms of the Contract
                </li>
                <br />
                <li>
                  The Customer shall indemnify and keep indemnified the Supplier
                  against all losses, claims, damages, liabilities, fines,
                  sanctions, interest, penalties, costs, charges, expenses,
                  compensation paid to Data Subjects, demands and legal and
                  other professional costs (calculated on a full indemnity basis
                  and in each case whether or not arising from any investigation
                  by, or imposed by, a Data Protection Supervisory Authority)
                  arising out of or in connection with any breach by the
                  Customer of its obligations under this clause 18.
                </li>
                <br />
                <li>The Supplier shall</li>
                <br />
                <ul class="a">
                  <li>
                    only process (and shall ensure Supplier Personnel only
                    process) the Protected Data in accordance with the schedule
                    and the Contract (including when making any transfer to
                    which clause 18.9 relates), except to the extent:
                    <ul class="a">
                      <li>
                        that alternative processing instructions are agreed
                        between the parties in writing; or
                      </li>
                      <br />
                      <li>
                        otherwise required by applicable law (and shall inform
                        the Customer of that legal requirement before
                        processing, unless applicable law prevents it doing so
                        on important grounds of public interest); and
                      </li>
                      <br />
                    </ul>
                  </li>
                  <br />
                  <li>
                    without prejudice to clause 18.1, if the Supplier believes
                    that any instruction received by it from the Customer is
                    likely to infringe the Data Protection Laws it shall
                    promptly inform the Customer and be entitled to cease to
                    provide the relevant Services until the parties have agreed
                    appropriate amended instructions which are not infringing.
                  </li>
                  <br />
                </ul>
                <li>
                  Taking into account the state of technical development and the
                  nature of processing, the Supplier shall implement and
                  maintain the technical and organisational measures set out in
                  Part B of the schedule to protect the Protected Data against
                  accidental, unauthorised or unlawful destruction, loss,
                  alteration, disclosure or access.
                </li>
                <br />
                <li>The Supplier shall:</li>
                <br />
                <ul class="a">
                  <li>
                    not permit any processing of Protected Data by any agent,
                    subcontractor or other third party (except its or its
                    Sub-Processors’ own employees in the course of their
                    employment that are subject to an enforceable obligation of
                    confidence with regards to the Protected Data) without the
                    written authorisation of the Customer;
                  </li>
                  <br />
                  <li>
                    prior to the relevant Sub-Processor carrying out any
                    processing activities in respect of the Protected Data,
                    appoint each Sub-Processor under a written contract
                    containing materially the same obligations as under this
                    clause 18 (including those relating to sufficient guarantees
                    to implement appropriate technical and organisational
                    measures) that is enforceable by the Supplier and ensure
                    each such Sub-Processor complies with all such obligations;
                  </li>
                  <br />
                  <li>
                    remain fully liable to the Customer under the Contract for
                    all the acts and omissions of each Sub-Processor as if they
                    were its own; and
                  </li>
                  <br />
                  <li>
                    ensure that all natural persons authorised by the Supplier
                    or any Sub-Processor to process Protected Data are subject
                    to a binding written contractual obligation to keep the
                    Protected Data confidential.
                  </li>
                  <br />
                </ul>
                <li>
                  The Customer authorises the appointment of the Sub-Processors
                  listed in the schedule.
                </li>
                <br />
                <li>The Supplier shall (at the Customer’s cost):</li>
                <br />
                <ul class="a">
                  <li>
                    assist the Customer in ensuring compliance with the
                    Customer’s obligations pursuant to Articles 32 to 36 of the
                    GDPR (and any similar obligations under applicable Data
                    Protection Laws) taking into account the nature of the
                    processing and the information available to the Supplier;
                    and
                  </li>
                  <br />
                  <li>
                    taking into account the nature of the processing, assist the
                    Customer (by appropriate technical and organisational
                    measures), insofar as this is possible, for the fulfilment
                    of the Customer’s obligations to respond to requests for
                    exercising the Data Subjects’ rights under Chapter III of
                    the GDPR (and any similar obligations under applicable Data
                    Protection Laws) in respect of any Protected Data.
                  </li>
                  <br />
                </ul>
                <li>
                  The Supplier shall not process and/or transfer, or otherwise
                  directly or indirectly disclose, any Protected Data in or to
                  countries outside the United Kingdom or to any International
                  Organisation without the prior written authorisation of the
                  Customer.
                </li>
                <br />
                <li>
                  The Supplier shall, in accordance with Data Protection Laws,
                  make available to the Customer such information that is in its
                  possession or control as is necessary to demonstrate the
                  Supplier’s compliance with the obligations placed on it under
                  this clause 18 and to demonstrate compliance with the
                  obligations on each party imposed by Article 28 of the GDPR
                  (and under any equivalent Data Protection Laws equivalent to
                  that Article 28), and allow for and contribute to audits,
                  including inspections, by the Customer (or another auditor
                  mandated by the Customer) for this purpose (subject to a
                  maximum of [one] audit request in any 12 month period under
                  this clause 18.10).
                </li>
                <br />
                <li>
                  On the end of the provision of the Services relating to the
                  processing of Protected Data, at the Customer’s cost and the
                  Customer’s option, the Supplier shall either return all of the
                  Protected Data to the Customer or securely dispose of the
                  Protected Data (and thereafter promptly delete all existing
                  copies of it) except to the extent that any applicable law
                  requires the Supplier to store such Protected Data. This
                  clause 18 shall survive termination or expiry of the Contract.
                </li>
                <br />
                <li>
                  <b>Force majeure</b>
                </li>
                <br />
                <p>
                  Neither party shall have any liability under or be deemed to
                  be in breach of the Contract for any delays or failures in
                  performance of the Contract which result from Force Majeure.
                  The party subject to the Force Majeure event shall promptly
                  notify the other party in writing when such the event causes a
                  delay or failure in performance and when it ceases to do so.
                  If the Force Majeure event continues for a continuous period
                  of more than 20 days, the party not affected may terminate the
                  Contract by written notice to the other party.
                </p>
                <li>Termination</li>
                <br />
                <li>
                  The Supplier may terminate the Contract or any other contract
                  which it has with the Customer at any time by giving notice in
                  writing to the Customer if:
                </li>
                <br />
                <ul class="a">
                  <li>
                    the Customer commits a material breach of Contract and such
                    breach is not remediable;
                  </li>
                  <br />
                  <li>
                    the Customer commits a material breach of the Contract which
                    is capable of being remedied and such breach is not remedied
                    within 14 days of receiving written notice of such breach;
                  </li>
                  <br />
                  <li>
                    the Customer has failed to pay any amount due under the
                    Contract on the due date and such amount remains unpaid
                    within 30 days after the Supplier has given notification
                    that the payment is overdue; or
                  </li>
                  <br />
                  <li>
                    any consent, licence or authorisation held by the Customer
                    is revoked or modified such that the Customer is no longer
                    able to comply with its obligations under the Contract or
                    receive any benefit to which it is entitled.
                  </li>
                  <br />
                </ul>
                <li>
                  The Supplier may terminate the Contract at any time by giving
                  notice in writing to the Customer if the Customer:
                </li>
                <br />
                <ul class="a">
                  <li>
                    stops carrying on all or a significant part of its business,
                    or indicates in any way that it intends to do so;
                  </li>
                  <br />
                  <li>
                    is unable to pay its debts either within the meaning of
                    section 123 of the Insolvency Act 1986 or if the Supplier
                    reasonably believes that to be the case;
                  </li>
                  <br />
                  <li>
                    becomes the subject of a company voluntary arrangement under
                    the Insolvency Act 1986;
                  </li>
                  <br />
                  <li>
                    becomes subject to a moratorium under Part A1 of the
                    Insolvency Act 1986;
                  </li>
                  <br />
                  <li>
                    becomes subject to a restructuring plan under Part 26A of
                    the Companies Act 2006;
                  </li>
                  <br />
                  <li>
                    becomes subject to a scheme of arrangement under Part 26 of
                    the Companies Act 2006;
                  </li>
                  <br />
                  <li>
                    has a receiver, manager, administrator or administrative
                    receiver appointed over all or any part of its undertaking,
                    assets or income;
                  </li>
                  <br />
                  <li>has a resolution passed for its winding up;</li>
                  <br />
                  <li>
                    has a petition presented to any court for its winding up or
                    an application is made for an administration order, or any
                    winding-up or administration order is made against it;
                  </li>
                  <br />
                  <li>
                    suspends or ceases, or threatens to suspend or cease, to
                    carry on all or a substantial part of its business
                  </li>
                  <br />
                  <li>
                    is subject to any procedure for the taking control of its
                    goods that is not withdrawn or discharged within seven days
                    of that procedure being commenced;
                  </li>
                  <br />
                  <li>has a freezing order made against it;</li>
                  <br />
                  <li>
                    takes any steps in anticipation of, or has no realistic
                    prospect of avoiding, any of the events or procedures
                    described in clauses 20.2.1 to 20.2.12 including giving
                    notice for the convening of any meeting of creditors,
                    issuing an application at court or filing any notice at
                    court, receiving any demand for repayment of lending
                    facilities, or passing any board resolution authorising any
                    steps to be taken to enter into an insolvency process.
                  </li>
                  <br />
                </ul>
                <li>
                  The Supplier may terminate the Contract any time by giving not
                  less than four weeks’ notice in writing to the Customer if the
                  Customer undergoes a change of Control or if it is
                  realistically anticipated that it shall undergo a change of
                  Control within two months.
                </li>
                <br />
                <li>
                  The right of the Supplier to terminate the Contract pursuant
                  to clause 20.2 shall not apply to the extent that the relevant
                  procedure is entered into for the purpose of amalgamation,
                  reconstruction or merger (where applicable) where the
                  amalgamated, reconstructed or merged party agrees to adhere to
                  the Contract.
                </li>
                <br />
                <li>
                  If the Customer becomes aware that any event has occurred, or
                  circumstances exist, which may entitle the Supplier to
                  terminate the Contract under this clause 20, it shall
                  immediately notify the Supplier in writing.
                </li>
                <br />
                <li>
                  The Customer shall be entitled to terminate the Contract at
                  any time on written notice to the Supplier, provided that the
                  Customer shall not be entitled to any refunds or rebates of
                  any kind or any amount from the Supplier in relation to any
                  prepayments or advance payments made by the Customer.
                </li>
                <br />
                <li>
                  Termination or expiry of the Contract shall not affect any
                  accrued rights and liabilities of the Supplier at any time up
                  to the date of termination, including, for the avoidance of
                  doubt, the obligation on the Customer to settle any unpaid
                  sums due to the Supplier hereunder.
                </li>
                <br />
                <li>
                  <b>Notices</b>
                </li>
                <br />
                <li>
                  Any notice or other communication given by a party under these
                  Conditions shall:
                </li>
                <br />
                <ul class="a">
                  <li>be in writing and in English;</li>
                  <br />
                  <li>
                    be signed by, or on behalf of, the party giving it (except
                    for notices sent by email); and
                  </li>
                  <br />
                  <li>
                    be sent to the relevant party at the address set out in the
                    Contract
                  </li>
                  <br />
                </ul>
                <li>Notices may be given, and are deemed received:</li>
                <br />
                <ul class="a">
                  <li>
                    by hand: on receipt of a signature at the time of delivery;
                  </li>
                  <br />
                  <li>
                    by Royal Mail Recorded Signed For post: at 9.00 am on the
                    second Business Day after posting;
                  </li>
                  <br />
                  <li>
                    by Royal Mail International Tracked & Signed post: at 9.00
                    am on the fourth Business Day after posting; and
                  </li>
                  <br />
                  <li>
                    by fax: on receipt of a transmission report from the correct
                    number confirming uninterrupted and error-free transmission;
                    and
                  </li>
                  <br />
                  <li>
                    by email: on receipt of a delivery receipt email from the
                    correct address.
                  </li>
                  <br />
                </ul>
                <li>
                  Any change to the contact details of a party as set out in the
                  Contract shall be notified to the other party in accordance
                  with clause 21.1 and shall be effective:
                </li>
                <br />
                <ul class="a">
                  <li>
                    on the date specified in the notice as being the date of
                    such change; or
                  </li>
                  <br />
                  <li>
                    if no date is so specified, five Business Days after the
                    notice is deemed to be received.
                  </li>
                  <br />
                </ul>
                <li>
                  All references to time are to the local time at the place of
                  deemed receipt.
                </li>
                <br />
                <li>
                  This clause does not apply to notices given in legal
                  proceedings or arbitration.
                </li>
                <br />
                <li>
                  <b>Cumulative remedies</b>
                </li>
                <br />
                <p>
                  The rights and remedies provided in the Contract for the
                  Supplier only are cumulative and not exclusive of any rights
                  and remedies provided by law.
                </p>
                <li>
                  <b>Time</b>
                </li>
                <br />
                <p>
                  Unless stated otherwise, time is of the essence of any date or
                  period specified in the Contract in relation to the Customer’s
                  obligations only.
                </p>
                <br />
                <li>
                  <b>Further assurance</b>
                </li>
                <br />
                <p>
                  The Customer shall at the request of the Supplier, and at the
                  Customer’s own cost, do all acts and execute all documents
                  which are necessary to give full effect to the Contract.
                </p>
                <br />
                <li>
                  <b>Entire agreement</b>
                </li>
                <br />
                <li>
                  The parties agree that the Contract and any documents entered
                  into pursuant to it constitutes the entire agreement between
                  them and supersedes all previous agreements, understandings
                  and arrangements between them, whether in writing or oral in
                  respect of its subject matter.
                </li>
                <br />
                <li>
                  Each party acknowledges that it has not entered into the
                  Contract or any documents entered into pursuant to it in
                  reliance on, and shall have no remedies in respect of, any
                  representation or warranty that is not expressly set out in
                  the Contract or any documents entered into pursuant to it. No
                  party shall have any claim for innocent or negligent
                  misrepresentation on the basis of any statement in the
                  Contract
                </li>
                <br />
                <li>
                  Nothing in these Conditions purports to limit or exclude any
                  liability for fraud.
                </li>
                <br />
                <li>
                  <b>Variation</b>
                </li>
                <br />
                <p>
                  No variation of the Contract shall be valid or effective
                  unless it is in writing, refers to the Contract and these
                  Conditions and is issued by the Supplier duly signed or
                  executed by, or on behalf of, each party.
                </p>
                <br />
                <li>
                  <b>Assignment</b>
                </li>
                <br />
                <li>
                  The Customer may not assign, subcontract or encumber any right
                  or obligation under the Contract, in whole or in part, without
                  the Supplier’s prior written consent, which it may withhold or
                  delay at its absolute discretion.
                </li>
                <br />
                <li>
                  Notwithstanding clause 27.1, the Customer may perform any of
                  its obligations and exercise any of its rights granted under
                  the Contract through any Affiliate provided that it gives the
                  Supplier prior written notice of such subcontracting or
                  assignment including the identity of the relevant Affiliate.
                  The Customer acknowledges and agrees that any act or omission
                  of its Affiliate in relation to the Customer’s rights or
                  obligations under the Contract shall be deemed to be an act or
                  omission of the Customer itself.
                </li>
                <br />
                <li>
                  <b>Set off</b>
                </li>
                <br />
                <li>
                  The Supplier shall be entitled to set-off under the Contract
                  any liability which it has or any sums which it owes to the
                  Customer under the Contract or under any other contract which
                  the Supplier has with the Customer.
                </li>
                <br />
                <li>
                  The Customer shall pay all sums that it owes to the Supplier
                  under the Contract without any set-off, counterclaim,
                  deduction or withholding of any kind, save as may be required
                  by law.
                </li>
                <br />
                <li>
                  <b>No partnership or agency</b>
                </li>
                <br />
                <p>
                  The parties are independent persons and are not partners,
                  principal and agent or employer and employee and the Contract
                  does not establish any joint venture, trust, fiduciary or
                  other relationship between them, other than the contractual
                  relationship expressly provided for in it. None of the parties
                  shall have, nor shall represent that they have, any authority
                  to make any commitments on the other party’s behalf.
                </p>
                <br />
                <li>
                  <b>Equitable relief</b>
                </li>
                <br />
                <p>
                  The Customer recognises that any breach or threatened breach
                  of the Contract may cause the Supplier irreparable harm for
                  which damages may not be an adequate remedy. Accordingly, in
                  addition to any other remedies and damages available to the
                  Supplier, the Customer acknowledges and agrees that the
                  Supplier is entitled to the remedies of specific performance,
                  injunction and other equitable relief without proof of special
                  damages.
                </p>
                <br />
                <li>
                  <b>Severance</b>
                </li>
                <br />
                <li>
                  If any provision of the Contract (or part of any provision) is
                  or becomes illegal, invalid or unenforceable, the legality,
                  validity and enforceability of any other provision of the
                  Contract shall not be affected.
                </li>
                <br />
                <li>
                  If any provision of the Contract (or part of any provision) is
                  or becomes illegal, invalid or unenforceable but would be
                  legal, valid and enforceable if some part of it was deleted or
                  modified, the provision or part-provision in question shall
                  apply with the minimum such deletions or modifications as may
                  be necessary to make the provision legal, valid and
                  enforceable. In the
                </li>
                <br />
                <p>
                  event of such deletion or modification, the parties shall
                  negotiate in good faith in order to agree the terms of a
                  mutually acceptable alternative provision.
                </p>
                <br />
                <li>
                  <b>Waiver</b>
                </li>
                <br />
                <li>
                  No failure, delay or omission by the Supplier in exercising
                  any right, power or remedy provided by law or under the
                  Contract shall operate as a waiver of that right, power or
                  remedy, nor shall it preclude or restrict any future exercise
                  of that or any other right, power or remedy.
                </li>
                <br />
                <li>
                  No single or partial exercise of any right, power or remedy
                  provided by law or under the Contract by the Supplier shall
                  prevent any future exercise of it or the exercise of any other
                  right, power or remedy by the Supplier.
                </li>
                <br />
                <li>
                  A waiver of any term, provision, condition or breach of the
                  Contract by the Supplier shall only be effective if given in
                  writing and signed by the Supplier, and then only in the
                  instance and for the purpose for which it is given.
                </li>
                <br />
                <li>
                  <b>Compliance with law</b>
                </li>
                <br />
                <p>
                  The Customer shall comply with Applicable Law and shall
                  maintain such licences, authorisations and all other
                  approvals, permits and authorities as are required from time
                  to time to perform its obligations under or in connection with
                  the Contract.
                </p>
                <br />
                <li>
                  <b>Costs and expenses</b>
                </li>
                <br />
                <p>
                  The Customer shall pay its own costs and expenses incurred in
                  connection with the negotiation, preparation, signature and
                  performance of the Contract (and any documents referred to in
                  it).
                </p>
                <br />
                <li>
                  <b>Third party rights</b>
                </li>
                <br />
                <li>
                  Except as expressly provided for in clause 35.2, a person who
                  is not a party to the Contract shall not have any rights under
                  the Contracts (Rights of Third Parties) Act 1999 to enforce
                  any of the provisions of the Contract.
                </li>
                <br />
                <li>
                  Any Affiliate of the Supplier shall be entitled under the
                  Contracts (Rights of Third Parties) Act 1999 to enforce any of
                  the provisions of the Contract. The consent of any such
                  Affiliate is not required in order to rescind or vary the
                  Contract or any provision of it.
                </li>
                <br />
                <li>
                  <b>Governing law</b>
                </li>
                <br />
                <p>
                  The Contract and any dispute or claim arising out of, or in
                  connection with, it, its subject matter or formation
                  (including non-contractual disputes or claims) shall be
                  governed by, and construed in accordance with, the laws of
                  Northern Ireland.
                </p>
                <br />
                <li>
                  <b>Jurisdiction</b>
                </li>
                <br />
                <p>
                  The parties irrevocably agree that the courts of Northern
                  Ireland shall have exclusive jurisdiction to settle any
                  dispute or claim arising out of, or in connection with, the
                  Contract, its subject matter or formation (including
                  non-contractual disputes or claims).
                </p>
                <br />
                <p>
                  <center>THE SCHEDULE</center>
                </p>
                <p>
                  <center>
                    <b>Part A</b>
                  </center>
                </p>
                <p>
                  <center>
                    <b>Data processing details</b>
                  </center>
                </p>
                <p>
                  Processing of the Protected Data by the Supplier under the
                  Contract shall be for the subject-matter, duration, nature and
                  purposes and involve the types of Personal Data and categories
                  of Data Subjects set out in this Part A of the schedule.
                </p>
                <p>
                  <b>Subject-matter of processing:</b>
                </p>
                <ul>
                  <p>
                    To provide a service to the Customer, the Data Controller,
                    for the benefit of the Customer and its employees, workers
                    and consultants, and that involves the processing of
                    personal data.
                  </p>
                </ul>
                <p>
                  <b>Duration of the processing:</b>
                </p>
                <ul>
                  <p>
                    The duration of the contract and any period thereafter where
                    the Supplier is obliged to retain records for legal and
                    regulatory purposes
                  </p>
                </ul>
                <p>
                  <b>Nature and purpose of the processing:</b>
                </p>
                <ul>
                  <p>For the proper performance of the Contract</p>
                </ul>
                <p>
                  <b>Type of Personal Data:</b>
                </p>
                <ul>
                  (i) First name; (ii) last name; (iii) email; (iv) role; and
                  (v) work location
                </ul>
                <p>
                  <b>Categories of Data Subjects:</b>
                </p>
                <ul>Employees, workers and consultants of the Customer</ul>
                <p>
                  <b>Specific processing instructions:</b>
                </p>
                <ul>n/a</ul>
                <p>
                  <center>
                    <b>Part B</b>
                  </center>
                </p>
                <p>
                  <center>
                    <b>Technical and organisational security measures</b>
                  </center>
                </p>
                <p>
                  The Supplier shall implement and maintain the following
                  technical and organisational security measures to protect the
                  Protected Data:
                </p>
              </li>
            </ul>
            <ol>
              <li>
                In accordance with the Data Protection Laws, taking into account
                the state of the art, the costs of implementation and the
                nature, scope, context and purposes of the processing of the
                Protected Data to be carried out under or in connection with the
                Contract, as well as the risks of varying likelihood and
                severity for the rights and freedoms of natural persons and the
                risks that are presented by the processing, especially from
                accidental or unlawful destruction, loss, alteration,
                unauthorised disclosure of, or access to the Protected Data
                transmitted, stored or otherwise processed, the Supplier shall
                implement appropriate technical and organisational security
                measures appropriate to the risk, including as appropriate those
                matters mentioned in Articles 32(1)(a) to 32(1)(d) (inclusive)
                of the GDPR (and any similar obligations under applicable Data
                Protection Laws).
              </li>
            </ol>
          </div>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Terms;
