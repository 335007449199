import { Storage } from "../storage/storage";
import fetch from "./api-timeout";

let apiKey = process.env.REACT_APP_API_KEY;
let baseURL = process.env.REACT_APP_API_PATH;

export const APIErrorCode = {
  UnkonwnError: 0, // client side code
  NotReady: -1, // client side code
  UnexpectedAuthResponse: -3, // client side code
  Aborted: -4, // When api call is canceled
  InternalServerError: 1,
  WrongAppKey: 2,
  AccessDenied: 3,
  WrongInput: 4,
  NotImplemented: 5,
  EmailAlreadyRegistered: 6,
  UserCredentialNotFound: 7,
  IncorrectEmailOrPassword: 8,
  UserNotFoundByEmail: 9,
  EmailAlreadyVerified: 10,
  PasswordNotMatch: 11,
  OTPCodeExpired: 12,
  WrongPasswordRecoveryToken: 13,
  PasswordRecoveryTokenExpired: 14,
  UserEmailIsNotVerified: 15,
  ImageFileRequired: 16,
  WrongImageFormat: 17,
  FileTooLarge: 18,
  AccountInactive: 19,
  EmailIncorrect: 20,
};

export class APIError extends Error {
  constructor(errorCode, message, title, isForceLogout) {
    super(isForceLogout ? "Please try to login again." : message);
    this.title = title;
    this.errorCode = errorCode;
    this.isForceLogout = isForceLogout;
  }
}
export class APIServiceBase {
  constructor() {
    this.basePath = baseURL;
    this.apiKey = apiKey;
    this.accessToken = undefined;
  }

  // defalut langauge is used for non session APIs
  setDefaultLanguage(language) {
    if (this.supportedLanguages.includes(language)) {
      this.defaultLanguage = language;
    }
  }

  async publicAPICall(apiPath, method, req) {
    return this.apiCall(apiPath, method, req, false);
  }

  async sessionAPICall(apiPath, method, req, signal, timeout) {
    return this.apiCall(apiPath, method, req, true, signal, timeout);
  }

  async apiCall(apiPath, method, req, isSessionAPI, signal, timeout) {
    // console.log(`API request ${apiPath}`);
    if (req) {
      // console.log(req);
    }

    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": this.apiKey,
    };

    if (isSessionAPI) {
      // console.log("X-Session-Key---", Storage.getUserToken());
      headers["X-Session-Key"] = Storage.getUserToken();
    }

    try {
      const response = await fetch(
        `${this.basePath}${apiPath}`,
        {
          method,
          headers,
          body: method === "POST" ? JSON.stringify(req) : undefined,
          signal,
        },
        timeout
      );

      const result = await this.handleAPIResopnse(response);
      // console.log(`API response ${apiPath}`, result);
      return result;
    } catch (e) {
      // console.log(`API exception ${apiPath}`);
      // console.log(e);
      throw this.handleAPIException(e);
    }
  }

  async uploadImageApiCall(apiPath, method, body, timeout) {
    // console.log(`API request ${apiPath}`);
    const headers = {
      accept: "application/json",
      // "Content-Type": "multipart/form-data",
      "X-API-Key": this.apiKey,
      "X-Session-Key": Storage.getUserToken(),
    };
    try {
      const response = await fetch(
        `${this.basePath}${apiPath}`,
        {
          method,
          headers,
          body: body,
        },
        timeout
      );
      const result = await this.handleAPIResopnse(response);
      // console.log(`API response ${apiPath}`);
      // console.log(result);
      return result;
    } catch (e) {
      // console.log(`API exception ${apiPath}`);
      // console.log(e);
      throw this.handleAPIException(e);
    }
  }

  async handleAPIResopnse(response) {
    return response;
  }

  handleAPIException(exception) {
    return exception;
  }

  get apiNotReadyError() {
    return new APIError(APIErrorCode.NotReady);
  }
}
