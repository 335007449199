import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { Step, Steps } from "react-step-builder";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewAltCard,
  PreviewCard,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import LoadingComponent from "../../../../layout/spinner/LoadingSpinner";
import { getCourseDetail } from "../TrainingData";
import CourseChapter from "./CourseChapter";
import CourseInfoBasic from "./CourseInfoBasic";
import CourseOutcome from "./CourseOutcome";
import CourseTest from "./CourseTest";
import {
  DueOptions,
  DurationOptions,
  FrequencyOptions,
  YesNoOptions,
} from "../../Roles/RolesData";
export const CourseWizardContext = React.createContext();

const Header = (props) => {
  return (
    <div className="steps clearfix ">
      <ul>
        <li
          className={
            (props.current >= 1 ? "first done" : "first") + " wizard-margin"
          }
        >
          <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
            <span className="number">Info</span>
          </a>
        </li>
        <li className={(props.current >= 2 ? "done" : "") + " wizard-margin"}>
          <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
            <span className="number">Outcome</span>
          </a>
        </li>
        <li className={(props.current >= 3 ? "done" : "") + " wizard-margin"}>
          <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
            <span className="current-info audible">current step: </span>
            <span className="number">Chapters</span>
          </a>
        </li>
        <li
          className={
            (props.current === 4 ? "last done" : "last") + " wizard-margin"
          }
        >
          <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
            <span className="current-info audible">current step: </span>
            <span className="number">Test</span>
          </a>
        </li>
        <li></li>
      </ul>
    </div>
  );
};

const config = {
  before: Header,
};

const CourseWizard = () => {
  const location = useLocation();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    if (location.state?.id) {
      loadCourse(location.state.id);
    } else {
      setData({});
      setIsInitScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const courseWizardContext = useMemo(
    () => ({
      data: data,
      updateData: (key, value) => {
        setData((prev) => ({
          ...prev,
          [key]: value,
        }));
      },
    }),
    [data]
  );

  function toLocalQuestions(questions) {
    let list = [];
    for (let index = 0; index < questions?.length ?? 0; index++) {
      const question = questions[index];
      list.push({
        id: question.id,
        question: question.description,
        questionType: question.type,
        options: question.options,
        answerIndexes: question.correctAnswer,
      });
    }
    return list;
  }

  function loadCourse(id) {
    getCourseDetail(id, (result, error) => {
      if (error === null) {
        let chapters = [
          {
            id: 0,
            html: result?.data?.intro,
          },
        ];
        const list = [...(result?.data?.chapters ?? [])].sort((a, b) =>
          a.orderIndex > b.orderIndex ? 1 : -1
        );

        for (let index = 0; index < (list?.length ?? 0); index++) {
          const chapter = list[index];
          let questions = toLocalQuestions(chapter?.questions);
          chapters.push({
            id: chapter.id,
            html: chapter.description,
            index: chapter.orderIndex,
            title: chapter.title,
            questions: questions,
          });
        }

        let questions = toLocalQuestions(result?.questions);

        let frequency = undefined;
        if (result.data?.frequency) {
          let list = FrequencyOptions.filter(
            (item) => item.value === result.data?.frequency
          );
          if (list.length === 1) {
            frequency = list[0];
          }
        }

        let dueIn = undefined;
        if (result.data?.dueIn) {
          let list = DueOptions.filter(
            (item) => item.value === result.data?.dueIn
          );
          if (list.length === 1) {
            dueIn = list[0];
          }
        }

        let duration = undefined;
        if (result.data?.duration) {
          let list = DurationOptions.filter(
            (item) => item.value === result.data?.duration
          );
          if (list.length === 1) {
            duration = list[0];
          }
        }

        let isCpd = undefined;
        if (result.data?.isCpd !== undefined) {
          let text = result.data?.isCpd === true ? "Yes" : "No";
          let list = YesNoOptions.filter((item) => item.value === text);
          if (list.length === 1) {
            isCpd = list[0];
          }
        }

        let isEvaluationRequired = undefined;
        if (result.data?.isEvaluationRequired !== undefined) {
          let text = result.data?.isEvaluationRequired === true ? "Yes" : "No";
          let list = YesNoOptions.filter((item) => item.value === text);
          if (list.length === 1) {
            isEvaluationRequired = list[0];
          }
        }

        let isManualCertificate = undefined;
        if (result.data?.isManualCertificate !== undefined) {
          let text = result.data?.isManualCertificate === true ? "Yes" : "No";
          let list = YesNoOptions.filter((item) => item.value === text);
          if (list.length === 1) {
            isManualCertificate = list[0];
          }
        }

        setData((prev) => ({
          ...prev,
          id: result.data?.id,
          courseName: result.data?.name,
          frequency: frequency,
          dueIn: dueIn,
          about: result.data?.aboutTraining,
          cover: result.data?.image?.imageUrl,
          outcome: result.data?.outcome,
          chapters: chapters,
          questions: questions,
          duration: duration ?? DurationOptions[1],
          evalutionForm: isEvaluationRequired,
          isCpd: isCpd,
          isManualCertificate: isManualCertificate,
        }));
      }
      setIsInitScreen(false);
    });
  }
  return (
    <React.Fragment>
      <Head title="Training" />
      {!isInitScreen && (
        <Content>
          <Block className="wide-md mx-auto">
            <BlockHead size="sm">
              <BlockBetween>
                <BlockTitle tag="h3" page>
                  Course
                </BlockTitle>
              </BlockBetween>
            </BlockHead>
            <PreviewAltCard>
              <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                <CourseWizardContext.Provider value={courseWizardContext}>
                  <Steps config={config}>
                    <Step component={CourseInfoBasic} />
                    <Step component={CourseOutcome} />
                    <Step component={CourseChapter} />
                    <Step component={CourseTest} />
                  </Steps>
                </CourseWizardContext.Provider>
              </div>
            </PreviewAltCard>
          </Block>
        </Content>
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default CourseWizard;
