
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";

import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  PaginationComponent,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Storage } from "../../services/storage/storage";
import { UserAccountType } from "../../utils";
import { getPolicyAssignList } from "./PolicyData";
import moment from "moment/moment";

const LearnerPolicies = ({ ...props }) => {
  const history = useHistory();
  const [policyList, setPolicyList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [location, setLocation] = useState([]);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const user = Storage.getLoggedInUser();

  useEffect(() => {
    loadPolicyAssignList();
  }, [searchText]);

  useEffect(() => {
    if (currentPage !== 0) {
      // fetchTraining();
      loadPolicyAssignList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // Changing state value when searching name
  useEffect(() => {
    if (isInitScreen) {
      return;
    }
    if (currentPage === 1) {
      // fetchTraining();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, location]);

  const viewOnClick = (item) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/policy-view`,
      state: {
        data: item,
      },
    });
  };

  // Get current list, pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function loadPolicyAssignList() {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: itemPerPage,
      },
      search: searchText,
    };
    getPolicyAssignList(params, (data, error) => {
      // setIsInitScreen(false);
      if (error === null) {
        setPolicyList(data.list);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo.totalItems);
        }
      }
    });
  }
  return (
    <>
      <React.Fragment>
        <Head title="Policies" />
        <Content>
        <div className="wide-lg mx-auto">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Policies
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <Block>
            <DataTable className="card-stretch">
              <div className="card-inner position-relative card-tools-toggle">
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="form-inline flex-nowrap gx-2">
                        <input
                          className="form-control w-300px"
                          type="text"
                          name="search"
                          value={searchText}
                          placeholder="Search"
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                        />
                    </div>
                  </div>
                </div>
              </div>
              <DataTableBody>
                {policyList.length > 0 ? (
                  <>
                    <DataTableHead>
                      <DataTableRow>
                        <span className="sub-text">
                          <strong>Policy name</strong>
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text text-end">
                          <strong>Date</strong>
                        </span>
                      </DataTableRow>
                      {user.accountType === UserAccountType.admin && (
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <span className="sub-text"></span>
                        </DataTableRow>
                      )}
                    </DataTableHead>
                    {policyList.length > 0
                      ? policyList.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <DataTableItem
                                className="cursor-pointer"
                                key={index}
                                onClick={() => viewOnClick(item)}
                              >
                                <DataTableRow>
                                  <div className="user-info">
                                    <span className="tb-lead">
                                      {item.name}{" "}
                                    </span>
                                  </div>
                                </DataTableRow>
                                <DataTableRow className="col-4 col-sm-2 text-end">
                                  <span className="">
                                    {item?.policyRead?.completedAt ? (
                                      <>
                                        {moment(
                                          item?.policyRead?.completedAt
                                        ).format("DD-MM-YYYY hh:mm A")}
                                      </>
                                    ) : (
                                      "N/A"
                                    )}
                                  </span>
                                </DataTableRow>
                              </DataTableItem>
                            </React.Fragment>
                          );
                        })
                      : null}
                  </>
                ) : (
                  <p className="p-2 text-center">Data not found</p>
                )}
              </DataTableBody>
              {totalItems > policyList.length && (
                <div className="card-inner">
                  {totalItems > 0 && totalItems > policyList.length ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={totalItems}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : totalItems === 0 ? (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  ) : null}
                </div>
              )}
            </DataTable>
          </Block>
          </div>
        </Content>
      </React.Fragment>
    </>
  );
};

export default LearnerPolicies;
