import React, { useState } from "react";
import { Card, Progress, Spinner } from "reactstrap";
import Content from "../../../layout/content/Content";
import { useHistory, useLocation } from "react-router";
import {
  fetchChapterMarkAsRead,
  fetchChapterMarkAsUnread,
} from "./DueTrainingData";
import TextToSpeech from "./TextToSpeech";
import { useEffect } from "react";
import { Storage } from "../../../services/storage/storage";
import { AssesmentQuestion } from "./AssesmentTest";
import { QuestionType } from "../../../utils";
import { ResultAnswer } from "./ResultedQuestion";
import Head from "../../../layout/head/Head";

const StartCourseDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const [viewIndex, setViewIndex] = useState(
    location?.state?.data?.completedData?.totalCompletedChapters ?? 0
  );

  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [isLoadingPrev, setIsLoadingPrev] = useState(false);
  const [scale, setScale] = useState(1);
  const [courseData] = useState(location?.state?.data?.data);
  const [courseTestQuestion] = useState(location?.state?.data?.questions);
  const [courseCompletionData, setCourseCompletionData] = useState();
  const [userData, setUserData] = useState(Storage.getLoggedInUser());
  const [text, setText] = useState();
  const [isNeedToShowChapter, setIsNeedToShowChapter] = useState(false);
  const [answers, setAnswers] = useState({});
  const [allAnswers, setAllAnswers] = useState({});
  const [results, setResults] = useState({});
  const [attempt, setAttempt] = useState(0);

  useEffect(() => {
    let showChapter =
      location?.state?.data?.completedData ||
      courseCompletionData?.data?.currentChapterNumber ||
      courseData.intro.trim().length === 0;
    setIsNeedToShowChapter(showChapter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [viewIndex]);

  useEffect(() => {
    if (!isNeedToShowChapter) {
      const speechText = courseData?.intro
        ?.replace(/(<([^>]+)>)/gi, "")
        ?.replaceAll("&nbsp;", " ");
      setText(speechText);
    } else {
      const speechText = courseData?.chapters[viewIndex]?.description
        ?.replace(/(<([^>]+)>)/gi, "")
        ?.replaceAll("&nbsp;", " ");
      setText(speechText);
    }
  }, [viewIndex, isNeedToShowChapter]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const prevPage = () => {
    if (viewIndex === 0) {
      setIsNeedToShowChapter(false);
    } else if (location.state?.isCompleted) {
      setViewIndex(viewIndex - 1);
      setAnswers([]);
    } else {
      if (viewIndex === 1) {
        chapterMarkUnread(viewIndex - 1, () => {
          setViewIndex(viewIndex - 1);
          setAnswers([]);
        });
      } else {
        if (viewIndex > 0) {
          setIsLoadingPrev(true);
          loadChapter(viewIndex - 2, () => {
            setViewIndex(viewIndex - 1);
            setAnswers([]);
          });
        }
      }
    }
  };

  const nextPage = () => {
    if (isNeedToShowChapter) {
      if (location.state?.isCompleted) {
        if (viewIndex === courseData?.chapters.length - 1) {
          // View Results
          if (courseTestQuestion.length === 0) {
            history.push(`${process.env.PUBLIC_URL}/`);
          } else {
            showAssesmentResult();
          }
        }
        setViewIndex(viewIndex + 1);
        setAnswers({});
        setAttempt(0); // Resetting attempt count here
      } else {
        setIsLoadingNext(true);
        loadChapter(viewIndex, (data) => {
          // Check if evaluation form is required and it's the last chapter
          if (
            location?.state?.data?.data?.isEvaluationRequired === true &&
            viewIndex === courseData?.chapters.length - 1 &&
            !location.state?.isCompleted
          ) {
            history.push({
              pathname: `${process.env.PUBLIC_URL}/evalution-form`,
              state: {
                questionData: courseTestQuestion,
                courseDetails: courseData,
                courseCompletedId:
                  courseCompletionData?.data?.courseCompletedId,
              },
            });
          } else {
            // Continue with regular chapter loading
            if (viewIndex === courseData?.chapters.length - 1) {
              // Questions are exist and move to assessment screen
              if (courseTestQuestion.length !== 0) {
                takeAssesment();
              } else {
                // Questions are not exist so move to certificate screen.
                showCertificate(data?.data?.certificateUrl);
              }
            }
            setViewIndex(viewIndex + 1);
            setAnswers({});
            setAttempt(0); // Resetting attempt count here
          }
        });
      }
    } else {
      setIsNeedToShowChapter(true);
      // Scroll to the next page
      scrollToTop();
    }
  };

  const showAssesmentResult = () => {
    const userData = Storage.getLoggedInUser();
    history.push({
      pathname: `${process.env.PUBLIC_URL}/training-assesment-result`,
      state: {
        userData: userData,
        courseId: courseData?.id,
        onlyResult: true,
      },
    });
  };

  const takeAssesment = () => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/course-assesment-test`,
      state: { questionData: courseTestQuestion, courseDetails: courseData },
    });
  };

  const showCertificate = (url) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/training-certificate`,
      state: {
        userData: userData,
        courseId: courseData.id,
        courseDetail: courseData,
        certificateUrl: url,
        isAssesmentExist: false,
      },
    });
  };

  const chapterMarkUnread = (index, callback) => {
    let params = {
      courseId: courseData?.id,
    };
    setIsLoadingPrev(true);
    fetchChapterMarkAsUnread(params, (data, error) => {
      if (error === null) {
        setIsLoadingNext(false);
        setResults({});
        setAttempt(0);
        callback();
      }
      setIsLoadingPrev(false);
    });
  };

  const loadChapter = (index, callback) => {
    let params = {
      chapterId: courseData?.chapters[index]?.id,
    };
    fetchChapterMarkAsRead(params, (data, error) => {
      if (error === null) {
        setIsLoadingNext(false);
        setCourseCompletionData(data);
        setResults({});
        setAttempt(0);
        callback(data);
      }
      setIsLoadingPrev(false);
    });
  };

  const handleOptionChange = (id, question, option) => {
    setResults({});
    let item = answers[id];
    if (item && question.type === QuestionType.msq) {
      if (item.answers.some((item) => item === option)) {
        setAnswers((prev) => ({
          ...prev,
          [id]: {
            questionId: id,
            answers: item.answers.filter((item) => item !== option),
          },
        }));
      } else {
        setAnswers((prev) => ({
          ...prev,
          [id]: {
            questionId: id,
            answers: [...item.answers, option],
          },
        }));
      }
    } else {
      setAnswers((prev) => ({
        ...prev,
        [id]: {
          questionId: id,
          answers: [option],
        },
      }));
    }

    if (item && question.type === QuestionType.msq) {
      if (item.answers.some((item) => item === option)) {
        setAllAnswers((prev) => ({
          ...prev,
          [id]: {
            questionId: id,
            answer: item.answers.filter((item) => item !== option),
          },
        }));
      } else {
        setAllAnswers((prev) => ({
          ...prev,
          [id]: {
            questionId: id,
            answer: [...item.answers, option],
          },
        }));
      }
    } else {
      setAllAnswers((prev) => ({
        ...prev,
        [id]: {
          questionId: id,
          answer: [option],
        },
      }));
    }
  };

  const prepareResult = () => {
    setAttempt(attempt + 1);
    for (
      let index = 0;
      index < courseData?.chapters[viewIndex]?.questions?.length ?? 0;
      index++
    ) {
      const question = courseData?.chapters[viewIndex]?.questions[index];
      let yourAnswer = answers[question.id];
      let correctAnswers = question.options.filter((_, index) =>
        question.correctAnswer?.some((position) => position === index)
      );
      let status =
        question?.correctAnswer?.every((answer) =>
          yourAnswer?.answers?.includes(answer)
        ) && question?.correctAnswer?.length === yourAnswer?.answers?.length
          ? true
          : false;
      setResults((prev) => ({
        ...prev,
        [question.id]: {
          status: status,
          correctAnswers: correctAnswers,
        },
      }));
    }
  };

  return (
    <>
      <Head title={`${courseData.name}`}></Head>
      <Content>
        <div className="course-details" id="custom-table">
          <Card className="wide-md mx-auto bg-transparent border-0">
            <div>
              <p className="fs-2 text-dark mb-3">{courseData.name}</p>
              <Progress
                className="progress-lg  course-progess-bar"
                color="success"
                value={`${
                  viewIndex === 0
                    ? 5
                    : Math.floor(
                        (viewIndex / (courseData?.chapters?.length ?? 1)) * 100
                      )
                }`}
              >
                {`${Math.floor(
                  (viewIndex / (courseData?.chapters?.length ?? 1)) * 100
                )}%`}
              </Progress>
              <div>
                <div className="d-sm-flex  justify-content-between align-item-center my-2">
                  <TextToSpeech text={text} />
                  <span className="btn btn-dim btn-primary ">
                    Text Size
                    <span
                      className="ni ni-zoom-in fs-4 mx-1"
                      onClick={() => {
                        if (scale < 2) {
                          setScale(scale + 0.2);
                        }
                      }}
                    ></span>
                    <span
                      className="ni  ni-zoom-out fs-4 "
                      onClick={() => {
                        if (scale > 1) {
                          setScale(scale - 0.2);
                        }
                      }}
                    ></span>
                  </span>
                </div>
                <div className="p-1">
                  <div style={{ zoom: `${scale}` }}>
                    <p className="fs-1 text-dark">
                      {isNeedToShowChapter
                        ? courseData?.chapters[viewIndex]?.title
                        : "Introduction"}
                    </p>
                    {isLoadingNext || isLoadingPrev ? (
                      <div className="spinner">
                        <Spinner size="sm" color="dark" />
                      </div>
                    ) : (
                      <>
                        <div
                          className="py-2 course-chapter-details"
                          id="course-chapter-details"
                          dangerouslySetInnerHTML={{
                            __html: isNeedToShowChapter
                              ? courseData?.chapters[viewIndex]?.description
                              : courseData?.intro,
                          }}
                        ></div>
                        {isNeedToShowChapter &&
                          courseData?.chapters[viewIndex]?.questions?.length >
                            0 && (
                            <>
                              {courseData?.chapters[viewIndex]?.questions?.map(
                                (question, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <AssesmentQuestion
                                        question={question}
                                        index={index}
                                        answers={answers[question.id]}
                                        allAnswers={allAnswers[question.id]}
                                        onChanged={handleOptionChange}
                                      />
                                      {results && results[question.id] && (
                                        <div className="mb-3 mt-n3">
                                          <ResultAnswer
                                            isCorrect={
                                              results[question.id].status
                                            }
                                            answers={[
                                              results[question.id].status
                                                ? "Your answer is correct"
                                                : attempt >= 3
                                                ? `Try again, Correct Answer: ${results[
                                                    question.id
                                                  ].correctAnswers.join(", ")}`
                                                : "Try again",
                                            ]}
                                          />
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                              <button
                                className="btn btn-primary btn-text custom-solid-btn-sm mb-5"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  prepareResult();
                                }}
                              >
                                Check answer
                              </button>
                            </>
                          )}
                      </>
                    )}
                  </div>
                </div>
                {isLoadingNext || isLoadingPrev ? (
                  <></>
                ) : (
                  <div className="d-flex justify-content-end">
                    {isNeedToShowChapter ? (
                      <button
                        className="btn btn-primary btn-text me-1 custom-solid-btn-sm"
                        onClick={prevPage}
                        disabled={
                          isLoadingPrev ||
                          (viewIndex === 0 && courseData?.intro?.length === 0)
                        }
                      >
                        {isLoadingPrev ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          "Previous"
                        )}
                      </button>
                    ) : null}
                    <button
                      className="btn btn-primary btn-text ms-2 custom-solid-btn-sm"
                      disabled={
                        isLoadingNext ||
                        ((courseData?.chapters[viewIndex]?.questions?.length ??
                          0) > 0 &&
                          isNeedToShowChapter &&
                          (Object.values(results).length === 0 ||
                            Object.values(results).some(
                              (item) => item.status === false
                            )))
                      }
                      onClick={(ev) => {
                        ev.preventDefault();
                        nextPage();
                      }}
                    >
                      {isNeedToShowChapter ? (
                        isLoadingNext ? (
                          <Spinner size="sm" color="light" />
                        ) : viewIndex === courseData?.chapters.length - 1 ? (
                          courseTestQuestion.length === 0 ? (
                            "Complete"
                          ) : location.state?.isCompleted ? (
                            "View Result"
                          ) : location?.state?.data?.data
                              ?.isEvaluationRequired === true ? (
                            "Evaluation Form"
                          ) : (
                            "Take Assesment"
                          )
                        ) : (
                          "Next"
                        )
                      ) : (
                        "Start course"
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </Content>
    </>
  );
};

export default StartCourseDetails;
