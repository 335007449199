import React from "react";
import { Bar } from "react-chartjs-2";

export const BarChart = ({ data }) => {
  return (
    <Bar
      className="sales-bar-chart chartjs-render-monitor"
      width={180}
      data={data}
      options={{
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true, // Start the scale at 0
                min: 0, // Set a minimum value for the scale (adjust as needed)
              },
            },
          ],
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
            },
          ],
        },

        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            title: function (tooltipItem, data) {
              return false;
            },
            label: function (tooltipItem, data) {
              return (
                // data["labels"][tooltipItem["index"]] +
                // `\r\n` +
                data.datasets[tooltipItem.datasetIndex]["data"][
                  tooltipItem["index"]
                ] + data["dataUnit"]
              );
            },
          },
          backgroundColor: "#eff6ff",
          titleFontSize: 11,
          titleFontColor: "#6783b8",
          titleMarginBottom: 4,
          bodyFontColor: "#9eaecf",
          bodyFontSize: 10,
          bodySpacing: 3,
          yPadding: 8,
          xPadding: 8,
          footerMarginTop: 0,
          displayColors: false,
        },
      }}
    />
  );
};
