import React from "react";
import { Icon } from "../Component";

const RemoveableBadge = ({ label, onRemove }) => {
  return (
    <span className="badge badge-dim rounded-pill bg-primary me-2">
      {label}
      <li className="btn-toolbar-sep"></li>
      <Icon style={{ cursor: "pointer" }} name="cross-circle-fill" onClick={() => onRemove()} />
    </span>
  );
};

export default RemoveableBadge;