import React from "react";

// Reusable component for global radio buttons
const GlobalRadioButtons = ({ name, defaultValue, onChange }) => {
  return (
    <div className="ms-2">
      <div className="d-flex align-items-center">
        <input
          type="radio"
          id={`${name}Yes`}
          name={name}
          value="yes"
          defaultChecked={defaultValue === true} // Default to 'yes'
          onChange={onChange}
        />
        <label htmlFor={`${name}Yes`} className="ms-1">
          Yes
        </label>
        <input
          type="radio"
          id={`${name}No`}
          name={name}
          value="no"
          defaultChecked={defaultValue === false}
          onChange={onChange}
          className="ms-2"
        />
        <label htmlFor={`${name}No`} className="ms-1">
          No
        </label>
      </div>
    </div>
  );
};

export default GlobalRadioButtons;
